import {showNotification, updateNotification} from '@mantine/notifications';

import i18n from '../../i18n';

export const startNotification = (id: string) => {
  showNotification({
    id,
    loading: true,
    title: String(i18n.t('Common:notifications.loadingTitle')),
    message: String(i18n.t('Common:notifications.loadingMessage')),
    autoClose: false,
    withCloseButton: false,
  });
};

export const endNotification = (
  id: string,
  message: string,
  wasSuccessful: boolean
) => {
  updateNotification({
    id,
    color: wasSuccessful ? 'green' : 'red',
    title: wasSuccessful
      ? String(i18n.t('Common:notifications.success'))
      : String(i18n.t('Common:notifications.error')),
    message,
    autoClose: 2000,
  });
};
