import React from 'react';
import {useTranslation} from 'react-i18next';

// Icons
import {ArrowNarrowRight} from 'tabler-icons-react';

// Components
import {Button, Space} from '@mantine/core';
import {
  SlideContent,
  SlideContentHeader,
  SlideFooter,
} from 'components/slideText';
import {RichTextContent} from 'components/RichText';
import {Text} from 'components/common';

// Enums & Types
import {Color} from 'enums/common';
import {Exercise} from 'types/chapters/chapters';

// Styles
import {DarkButtonStyle} from 'styles/buttons';

interface Props {
  exercise: Exercise | null;
  setPage: (page: number) => void;
  disabledButton?: boolean;
}

const SlideText = ({exercise, setPage, disabledButton}: Props) => {
  const {t} = useTranslation('Practices');

  if (!exercise) return <></>;

  return (
    <>
      <SlideContent>
        <SlideContentHeader>
          <Text fontSize={18} fontWeight={700} color={Color.DARK}>
            {t('introduction')}
          </Text>
        </SlideContentHeader>
        <Text fontSize={16} fontWeight={500} color={Color.PURPLE_TXT} mt={20}>
          <RichTextContent htmlContent={exercise.introSlideText as string} />
        </Text>
      </SlideContent>
      <SlideFooter>
        <Space w={10} />
        <Button
          size="md"
          rightIcon={
            <ArrowNarrowRight size={26} strokeWidth={1.4} color={'white'} />
          }
          onClick={() => setPage(2)}
          style={DarkButtonStyle}
          disabled={disabledButton}
          loading={disabledButton}
        >
          {t('getStarted')}
        </Button>
      </SlideFooter>
    </>
  );
};

export default SlideText;
