import React, {FC} from 'react';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
  VictoryLine,
} from 'victory';

export interface TrainingTimeChartProps {
  day: string;
  userMinutes: number;
  recommendedMinutes: number;
}

export const TrainingTimeChart: FC<{
  timeDetails: TrainingTimeChartProps[];
  maxMinutes: number;
}> = ({timeDetails, maxMinutes}) => {
  // Prepare week data
  const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  // const minuteValues = Array.from({length: 5}, (_, i) => i * 5);

  const increment = maxMinutes <= 40 ? 5 : 10;

  // Generate minuteValues array
  const minuteValues = [];
  for (let i = 0; i <= maxMinutes; i += increment) {
    minuteValues.push(i);
  }
  return (
    <VictoryChart
      padding={40}
      style={{
        parent: {
          borderRadius: '4px',
          maxHeight: '550px',
          zIndex: 0,
        },
      }}
      containerComponent={<VictoryContainer />}
    >
      <VictoryAxis
        tickValues={weekdays}
        tickFormat={x => x}
        style={{
          axis: {stroke: 'gray'},
          ticks: {size: 5, stroke: 'transparent'},
          tickLabels: {
            fontSize: 10,
            fill: '#A29FC4',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
            padding: 0,
          },
        }}
      />

      <VictoryAxis
        dependentAxis
        tickValues={minuteValues}
        tickFormat={y => `${y} min`}
        style={{
          axis: {stroke: 'transparent'},
          ticks: {size: 5, stroke: 'transparent'},
          tickLabels: {
            fontSize: 10,
            fill: '#A29FC4',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
            padding: 0,
          },
        }}
      />

      {minuteValues.map(minute => (
        <VictoryLine
          key={minute}
          data={[
            {x: 'Mon', y: minute},
            {x: 'Sun', y: minute},
          ]}
          style={{
            data: {
              stroke: '#E5E5EF',
              strokeWidth: 1,
              strokeDasharray: '3,3',
            },
          }}
        />
      ))}

      <VictoryGroup offset={20}>
        <VictoryBar
          data={timeDetails?.map(({day, recommendedMinutes}) => ({
            x: day,
            y: recommendedMinutes,
          }))}
          style={{data: {fill: '#5C4D8A'}}}
          cornerRadius={{
            bottomLeft: 4,
            bottomRight: 4,
            topLeft: 4,
            topRight: 4,
          }}
        />
        <VictoryBar
          data={timeDetails?.map(({day, userMinutes}) => ({
            x: day,
            y: userMinutes,
          }))}
          style={{data: {fill: '#58C1DE'}}}
          cornerRadius={{
            bottomLeft: 4,
            bottomRight: 4,
            topLeft: 4,
            topRight: 4,
          }}
        />
      </VictoryGroup>
    </VictoryChart>
  );
};
