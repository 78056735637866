import {Checkbox, Loader, Pagination, Box} from '@mantine/core';
import {useAssignCourses} from 'api/tanstack-hooks/courses/useAssignCourses';
import {useGetAssignableCourses} from 'api/tanstack-hooks/courses/useGetAssignableCourses';
// import {Button} from 'components/button';
import {Color} from 'enums/common';
import React, {useState} from 'react';
import styled from 'styled-components';
import {showNotification} from '@mantine/notifications';
import {Check} from 'tabler-icons-react';
import {useTranslation} from 'react-i18next';
import {Button} from '@mantine/core';

// Styles
const Wrapper = styled.div`
  margin-top: -40px;
`;

const SubTitle = styled.div`
  color: ${Color.DARK};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: -10px;
  padding-bottom: 16px;
`;

const ModalTitle = styled.div`
  color: ${Color.DARK};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding-bottom: 8px;
`;

export const AssignCourses = ({
  userName,
  userId,
  onClose,
}: {
  userId: number;
  userName: string;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [selectedCourses, setSelectedCourses] = useState<string[]>([]);
  const [activePage, setActivePage] = useState(1);
  const pageSize = 10;

  const {t} = useTranslation('Dashboard');

  const {data: courses, isLoading} = useGetAssignableCourses(
    userId.toString(),
    activePage,
    pageSize
  );
  const assignCoursesMutation = useAssignCourses();

  const handleAssign = () => {
    assignCoursesMutation.mutate(
      {usersIds: [userId.toString()], courseIds: selectedCourses},
      {
        onSuccess: () => {
          showNotification({
            title: `${t('assignCourses.notificationTitle', {
              courses: selectedCourses.length,
              name: userName,
            })}`,
            message: `${t('assignCourses.notificationMessage')}`,
            icon: <Check size={16} />,
          });

          onClose(false);
        },
      }
    );
  };

  const handleSelectCourse = (courseId: string) => {
    setSelectedCourses(prev =>
      prev.includes(courseId)
        ? prev.filter(id => id !== courseId)
        : [...prev, courseId]
    );
  };

  if (isLoading)
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader />
      </div>
    );

  return (
    <Wrapper>
      <div>
        <ModalTitle>
          <ModalTitle>{` ${t('assignCourses.title')} ${
            userName ?? '-'
          }`}</ModalTitle>
        </ModalTitle>
        <SubTitle>{t('assignCourses.subTitle')}</SubTitle>
        {courses?.data?.elements?.map(({id, name}) => (
          <Checkbox
            key={id}
            label={name}
            checked={selectedCourses.includes(id)}
            onChange={() => handleSelectCourse(id)}
            styles={{
              root: {
                marginBottom: 16,
                height: 20,
              },
              input: {
                cursor: 'pointer',
                '&:checked': {
                  backgroundColor: Color.DARK,
                  borderColor: Color.DARK,
                },
              },
              label: {
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 400,
                color: Color.DARK,
              },
            }}
          />
        ))}
      </div>
      <Pagination
        value={activePage}
        onChange={setActivePage}
        total={Math.ceil((courses?.data.total || 0) / pageSize)}
        withControls={false}
        withEdges
        position="left"
        size="sm"
        styles={{
          control: {
            '&[data-active]': {
              backgroundColor: '#26145C',
              color: 'white',
            },
          },
        }}
      />
      <Box
        style={{
          paddingTop: 8,
          display: 'flex',
          flexDirection: 'row-reverse',
          gap: '10px',
        }}
      >
        <Button
          onClick={handleAssign}
          disabled={assignCoursesMutation.isLoading}
          style={{
            backgroundColor: '#26145C',
          }}
        >
          {t('assignCourses.assignCourses')}
        </Button>
        <Button
          onClick={() => onClose(false)}
          disabled={assignCoursesMutation.isLoading}
          variant="default"
          style={{color: '#3C2C6C'}}
        >
          {t('assignCourses.cancel')}
        </Button>
      </Box>
    </Wrapper>
  );
};
