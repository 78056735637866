import React, {useEffect, useState} from 'react';
import {Global, MantineProvider} from '@mantine/core';
import styled from 'styled-components';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {init as sentryInit} from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import ReactGA from 'react-ga4';
import {Notifications} from '@mantine/notifications';

// Configuration
import {configuration} from 'config';

// Context
import {defaultLoginResponse, UserContext} from './contexes';

// Components
import {Router} from './routes';
import {useLocation} from 'react-router-dom';

// Initialisation
if (configuration().sentryDsn) {
  sentryInit({
    dsn: configuration().sentryDsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const StyledDiv = styled.div`
  background-color: #f6f6f6;
  margin: 0;
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const App = () => {
  // State
  const [user, setUser] = useState(defaultLoginResponse);

  // Hooks
  const location = useLocation();

  // Google analytics initialize and variables
  const {gaKey, isProduction} = configuration();
  if (gaKey && isProduction) ReactGA.initialize(gaKey);

  // Effects
  useEffect(() => {
    if (isProduction) {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <StyledDiv>
      <UserContext.Provider value={[user, setUser]}>
        <MantineProvider withCSSVariables withGlobalStyles withNormalizeCSS>
          <Global
            styles={{
              ':root': {
                '--mantine-color-violet-8': '#3C2C6C',
              },
            }}
          />
          <Notifications position="top-right" />
          <QueryClientProvider client={queryClient}>
            <Router />
            {process.env.NODE_ENV === 'development' && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
          </QueryClientProvider>
        </MantineProvider>
      </UserContext.Provider>
    </StyledDiv>
  );
};

export default App;
