import {useQuery} from '@tanstack/react-query';

import {ConversationPayload} from '../../../types/conversations/conversations';

import {getConversation} from '../../conversations/conversations';

export const useGetConversation = (
  payload: ConversationPayload,
  enabled?: boolean
) => {
  return useQuery(['conversation'], () => getConversation(payload), {
    enabled,
  });
};
