import i18n from 'i18n';

export const getCtaTitle = (learningPercentage: number): string => {
  if (learningPercentage && learningPercentage > 0 && learningPercentage < 100)
    return i18n.t('Dashboard:courseCards.continue');
  if (learningPercentage && learningPercentage === 100)
    return i18n.t('Dashboard:courseCards.restart');

  return i18n.t('Dashboard:courseCards.start');
};
