import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';

import {BasePage} from 'components/common';
import {
  CoursePreviewFooter,
  CoursePreviewHeader,
} from 'components/courses/common/course-preview';
import {CourseSidebar} from 'components/courses/common/course-preview';

import {useGetCourseOverview} from 'api/tanstack-hooks/courses';
import {useGetCourseChapters} from 'api/tanstack-hooks/courses/useGetCourseChapters';

const CourseContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f6f6f8;
  min-height: calc(100vh - 80px);
`;

const MainContentContainer = styled.div`
  margin: 20px auto;
  width: 60%;
`;

export const SalesCourseDetails: React.FC = () => {
  const {id, userCourseId, isLeadership} = useParams();

  const {data} = useGetCourseOverview(Number(id));
  const {data: chaptersData} = useGetCourseChapters(Number(userCourseId));

  return (
    <BasePage>
      {data && chaptersData && (
        <CourseContainer>
          <MainContentContainer>
            <CoursePreviewHeader
              chapters={chaptersData.data}
              course={data.data}
              isLeadership={Boolean(isLeadership)}
              userCourseId={userCourseId || ''}
            />
            <CoursePreviewFooter course={data.data} />
          </MainContentContainer>

          <CourseSidebar
            overviewUrl=""
            isLeadershipCourse
            chapters={chaptersData.data}
            allowSelection={false}
          />
        </CourseContainer>
      )}
    </BasePage>
  );
};
