import React from 'react';
import styled from 'styled-components';

import {Group, Text} from '@mantine/core';

import {Color} from '../../../../../enums/common';
import {Chapter} from '../../../../../types/chapters/chapters';

const AccordionLabelWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledText = styled(Text)`
  font-size: 14px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
  flex: 1;
`;
const StyledMinutes = styled(Text)`
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
  padding-left: 10px;
`;

export interface ChapterCoursesProps {
  chapter: Chapter;
}

export const AccordionLabel: React.FC<ChapterCoursesProps> = ({chapter}) => {
  return (
    <Group>
      <AccordionLabelWrapper>
        <StyledText>{chapter.chapterTitle || chapter.name}</StyledText>
        <StyledMinutes>{chapter.duration} min</StyledMinutes>
      </AccordionLabelWrapper>
    </Group>
  );
};
