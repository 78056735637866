export enum AssignedCourses {
  COURSES = 'pc-course/assigned',
}

export enum ToAssignCourses {
  COURSES = 'pc-course/to-assign',
}

export enum CoursesProgress {
  PROGRESS = 'pc-department/progress',
}

export enum CoursePc {
  COURSE = 'pc-course',
}

export enum Department {
  NAME = '/pc-department/names',
}

export enum Course {
  CHAPTERS_OVERVIEW = 'chapters-overview',
  OVERVIEW = 'overview',
  CHAPTERS = 'chapters',
}
