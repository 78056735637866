import React, {useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import produce from 'immer';

import {Button, Group, Image, ThemeIcon} from '@mantine/core';

import {DarkButtonStyle} from 'styles/buttons';

import {Close, Microphone} from 'components/icons';
import {Popup, Text} from 'components/common';
import {BottomTextContainer} from 'components/practices/voicebots';
import {
  VoicebotV2MessageGroup,
  VoiceBotV2SearchParams,
} from 'components/practices/voicebots/v2';

import {Color} from 'enums/common';
import {conversationMessage} from 'api/conversations/conversations';

import {
  ConversationInitialize,
  InterpretedText,
} from 'types/conversations/conversations';
import {VB2Message} from 'types/conversations/VB2Message';

import {MsSpeechToText} from 'utils/common';
import {detectLanguageId} from 'utils/voicebots';
import {queryClient} from '../../../../../App';

const VoicebotV2ConversationContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #e6e6ea;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 550px;
  padding: 0 32px;
  overflow-y: scroll;
`;

const VoicebotHeader = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  padding: 32px 0 12px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #f1f0f0;
  background-color: ${Color.WHITE};
  z-index: 1;
`;

const VoicebotFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border: 1px solid #e6e6ea;
  background-color: ${Color.GRAY_500};
`;

const Protip = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Color.PURPLE_400};
  height: 32px;
  padding: 0 35px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  padding-right: 10px;
  justify-content: flex-end;
  gap: 20px;
`;

const ReadMoreButton = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  user-select: none;
  cursor: pointer;
  font-weight: 500;
  color: ${Color.DARK};

  :hover {
    color: ${Color.DARK_LIGHTER};
  }
`;

const StyledClose = styled(Close)`
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const PopupContentContainer = styled.div`
  padding: 0 20px;
`;

export interface VoicebotV2ConversationProps {
  conversationInitialize: ConversationInitialize;
  conversationId: string;
  voicebotName: string;
  rasaBotKey: string;
  callback: ({isQuiz}: {isQuiz?: boolean | undefined}) => void;
  nextIterationCallback?: () => void;
  currentPageIndex?: number;
  onSearchParamsChange?: (props: VoiceBotV2SearchParams) => void;
}

export const VoicebotV2Conversation: React.FC<VoicebotV2ConversationProps> = ({
  conversationInitialize,
  conversationId,
  voicebotName,
  rasaBotKey,
  callback,
  nextIterationCallback,
  onSearchParamsChange,
  currentPageIndex = 0,
}) => {
  const {t} = useTranslation('Practices');
  const bottomRef = useRef<HTMLDivElement>(null);

  const [iteration, setIteration] = useState(currentPageIndex);
  const [hasIterationChanged, setHasIterationChanged] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isProtipHidden, setIsProtipHidden] = useState(false);
  const [isTechniqueInfoPopupOpen, setIsTechniqueInfoPopupOpen] =
    useState(false);
  const [isRecording, setIsRecording] = useState(false);

  const [interpretedText, setInterpretedText] = useState<InterpretedText[]>([]);
  const [vb2Messages, setVb2Messages] = useState<{[key: string]: VB2Message[]}>(
    {[currentPageIndex]: []}
  );
  const [protipMessage, setProtipMessage] = useState<string>();

  const msSpeechToText = useMemo(() => {
    return new MsSpeechToText(detectLanguageId(rasaBotKey));
  }, [rasaBotKey]);

  const advanceCourse = async () => {
    onSearchParamsChange?.({
      pageIndex: currentPageIndex + 1,
      showVoicebot: true,
    });
    setVb2Messages(
      produce(draft => {
        draft[currentPageIndex] = [];
      })
    );
    await queryClient.invalidateQueries([
      'initializeConversation',
      conversationId,
    ]);
    setHasIterationChanged(false);
    await setInitialMessages();

    if (nextIterationCallback) {
      nextIterationCallback();
    }
  };

  const recordVoice = async () => {
    if (!isRecording) {
      setIsRecording(true);

      setTimeout(() => {
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
      }, 50);

      const spokenText = await msSpeechToText.startRecognition();
      setInterpretedText(oldArray => [...oldArray, {text: spokenText}]);

      setIsRecording(false);
    }
  };

  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  };

  const setInitialMessages = async () => {
    for (const item of conversationInitialize.custom.intro_text) {
      setVb2Messages(
        produce(draft => {
          if (!draft[currentPageIndex]) draft[currentPageIndex] = [];
          draft[currentPageIndex].push({
            type: item.from,
            text: item.text,
            date: new Date(),
          });
        })
      );

      await new Promise(resolve => setTimeout(resolve, 600));
      scrollToBottom();
    }

    setVb2Messages(
      produce(draft => {
        draft[currentPageIndex].push({
          type: 'bot',
          text: conversationInitialize.text,
          date: new Date(),
        });
      })
    );

    setProtipMessage(conversationInitialize.custom.followup_recommendation);

    await new Promise(resolve => setTimeout(resolve, 600));
    scrollToBottom();
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (!vb2Messages[currentPageIndex]?.length) {
        setInitialMessages();
      }
    }, 200);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vb2Messages[currentPageIndex]]);

  useEffect(() => {
    if (interpretedText.length > 0) {
      (async () => {
        const messageData = await conversationMessage(
          {
            voicebot_name: voicebotName,
            message: interpretedText[interpretedText.length - 1].text,
          },
          conversationId
        );

        // Your message
        setVb2Messages(
          produce(draft => {
            draft[currentPageIndex].push({
              type: 'user',
              text: interpretedText[interpretedText.length - 1].text,
              date: new Date(),
              feedback: {
                value: messageData.custom.feedback_value,
                title: messageData.custom.feedback.title,
                content: messageData.custom.feedback.content,
              },
            });
          })
        );

        await new Promise(resolve => setTimeout(resolve, 600));
        scrollToBottom();

        if (messageData.custom.followup_recommendation) {
          setProtipMessage(messageData.custom.followup_recommendation);
        }

        if (messageData.text) {
          setVb2Messages(
            produce(draft => {
              draft[currentPageIndex].push({
                type: 'bot',
                text: messageData.text,
                date: new Date(),
              });
            })
          );

          await new Promise(resolve => setTimeout(resolve, 800));
          scrollToBottom();
        }

        if (
          messageData.custom.iteration_metadata.iteration > iteration &&
          messageData.custom.iteration_metadata.iterations_left > 0
        ) {
          setIteration(messageData.custom.iteration_metadata.iteration);
          setHasIterationChanged(true);
        }

        if (messageData.custom.finished) {
          setIsCompleted(true);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interpretedText]);

  useEffect(() => {
    if (currentPageIndex !== iteration) {
      setIteration(currentPageIndex);
      setVb2Messages(
        produce(draft => {
          draft[currentPageIndex] = [];
        })
      );
      void setInitialMessages();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageIndex]);

  return (
    <>
      <VoicebotV2ConversationContainer>
        <VoicebotHeader>
          <Text fontSize={18} fontWeight={700} color={Color.DARK}>
            {conversationInitialize.section.title}
          </Text>
          <ReadMoreButton onClick={() => setIsTechniqueInfoPopupOpen(true)}>
            {t('voicebot2.readMore')}
          </ReadMoreButton>
        </VoicebotHeader>
        <VoicebotV2MessageGroup
          messages={vb2Messages[currentPageIndex] || []}
          isRecording={isRecording}
        />
        {protipMessage && (
          <BottomTextContainer
            text={protipMessage.replaceAll('</br>', ' \n')}
          />
        )}
        <div key="bottom-ref" ref={bottomRef} />
      </VoicebotV2ConversationContainer>
      {!isProtipHidden && (
        <Protip>
          <Group spacing={11}>
            <Microphone />
            <Text fontSize={10} fontWeight={600} color={Color.DARK}>
              {t('pressButton')}
            </Text>
          </Group>
          <StyledClose onClick={() => setIsProtipHidden(true)} />
        </Protip>
      )}
      <VoicebotFooter>
        {isCompleted && (
          <ButtonContainer>
            <Button
              style={DarkButtonStyle}
              onClick={() => callback({isQuiz: false})}
            >
              {t('complete')}
            </Button>
          </ButtonContainer>
        )}
        {!isCompleted && !hasIterationChanged && (
          <ThemeIcon
            style={{cursor: isRecording ? 'not-allowed' : 'pointer'}}
            onClick={recordVoice}
            size={56}
            radius="xl"
            color={isRecording ? Color.GRAY_250 : Color.GREEN_050}
          >
            <Image width={18} height={25} src="/images/voice.svg" />
          </ThemeIcon>
        )}
        {!isCompleted && hasIterationChanged && (
          <ButtonContainer>
            <Button style={DarkButtonStyle} onClick={advanceCourse}>
              {t('next')}
            </Button>
          </ButtonContainer>
        )}
      </VoicebotFooter>
      <Popup
        isVisible={isTechniqueInfoPopupOpen}
        setIsVisible={setIsTechniqueInfoPopupOpen}
      >
        <PopupContentContainer>
          {conversationInitialize.custom.read_about_techniques.map(
            (techniqueItem, id) => (
              <div key={id}>
                <Text fontSize={24} fontWeight={700} color={Color.DARK}>
                  {techniqueItem.title}
                </Text>
                <Text fontSize={16} color={Color.DARK} mt={10}>
                  <ReactMarkdown>
                    {techniqueItem.content.replaceAll('</br>', ' \n')}
                  </ReactMarkdown>
                </Text>
              </div>
            )
          )}
        </PopupContentContainer>
      </Popup>
    </>
  );
};
