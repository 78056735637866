import {useQuery} from '@tanstack/react-query';
import {getCourseChapters} from '../../courses/courses';

export const useGetCourseChapters = (userCourseId: number) => {
  return useQuery(
    ['chapters', userCourseId],
    () => getCourseChapters(userCourseId),
    {
      enabled: !!userCourseId,
    }
  );
};
