import React from 'react';

interface CloseProps {
  onClick: () => void;
  className?: string;
}

export const Close: React.FC<CloseProps> = ({onClick, className}) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      onClick={onClick}
      className={className}
    >
      <path
        d="M9.66683 1.27301L8.72683 0.333008L5.00016 4.05967L1.2735 0.333008L0.333496 1.27301L4.06016 4.99967L0.333496 8.72634L1.2735 9.66634L5.00016 5.93967L8.72683 9.66634L9.66683 8.72634L5.94016 4.99967L9.66683 1.27301Z"
        fill="#26145C"
      />
    </svg>
  );
};
