import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

// Services
import {
  chatBotV3Communication,
  completeExercise,
  generateChatFeedback,
  getChatAnalyses,
  getChatSummary,
  startExercise,
} from 'api/practices';

// Types
import {Exercise} from 'types/chapters/chapters';

// Components
import SlideText from 'components/practices/voicebots/v3/SlideText';
import ChatBotV3 from 'components/practices/voicebots/v3/ChatBotV3';
import {Anchor, Breadcrumbs} from '@mantine/core';
import {BreadcrumbsStyle} from 'styles';
import ChatBotV3Feedback, {
  Feedback,
} from 'components/practices/voicebots/v3/ChatBotV3Feedback';
import {StartExerciseInterface} from 'pages/practices/PracticeV3';
import ConversationFeedback from 'components/practices/voicebots/v3/ConversationFeedback';

// Styled Components
const Container = styled.div`
  margin: 20px auto;
`;

export interface ChatBotV3Conversation {
  role: 'assistant' | 'user' | 'system';
  content: string;
}

interface Props {
  userExerciseId: number;
  hideBreadCrumbs?: boolean;
  conversationId: number;
  exercise: Exercise;
  isCourse?: boolean;
  handleAdvance?: ({isQuiz}: {isQuiz: boolean}) => void;
}

const ChatBotV3Container = ({
  conversationId = 0,
  exercise,
  isCourse = false,
  handleAdvance = () => null,
  hideBreadCrumbs = true,
  userExerciseId,
}: Props) => {
  // default Values
  const initialState = {
    initialPage: 1,
    initialTextMessage: '',
    initialConversation: [],
    initialSendingMessage: false,
    initialEndConversation: false,
    initialFeedbackMessage: null,
    summary: null,
    initialTries: 1,
    initialConversationId: conversationId,
    initialConversationIds: [],
    initialFeedbacksHaveBeenGenerated: false,
    initialFeedbackLoading: false,
    initialConversationFeedback: null,
  };

  const navigate = useNavigate();
  const {t} = useTranslation('Practices');

  const [page, setPage] = useState(initialState.initialPage);
  const [textMessage, setTextMessage] = useState(
    initialState.initialTextMessage
  );
  const [conversation, setConversation] = useState<ChatBotV3Conversation[]>(
    initialState.initialConversation
  );
  const [sendingMessage, setSendingMessage] = useState(
    initialState.initialSendingMessage
  );
  const [endConversation, setEndConversation] = useState(
    initialState.initialEndConversation
  );
  const [feedbackMessage, setFeedbackMessage] = useState<Feedback | null>(
    initialState.initialFeedbackMessage
  );
  const [summary, setSummary] = useState<string | null>(initialState.summary);
  const [tries, setTries] = useState(initialState.initialTries);
  const [_conversationId, _setConversationId] = useState<number>(
    initialState.initialConversationId
  );
  const [conversationIds, setConversationIds] = useState<number[]>(
    initialState.initialConversationIds
  );
  const [feedbacksHaveBeenGenerated, setFeedbacksHaveBeenGenerated] = useState(
    initialState.initialFeedbacksHaveBeenGenerated
  );

  const [conversationFeedback, setConversationFeedback] = useState<
    string | null
  >(initialState.initialConversationFeedback);

  const [feedbackLoading, setFeedbackLoading] = useState(
    initialState.initialFeedbackLoading
  );
  const [retryLoading, setRetryLoading] = useState(false);
  const [userFeedback, setUserFeedback] = useState<boolean | null>(null);

  const maxTries = exercise.conversationRepetitions;
  const isConversationFeedback =
    exercise.feedbackType.name === 'FEEDBACK_PER_CONVERSATION';

  const handleStartExercise = async (exerciseTries: null | number = null) => {
    if (!userExerciseId) return;

    setSendingMessage(initialState.initialSendingMessage);
    const res = await startExercise(+userExerciseId);
    const data = res.data as unknown as StartExerciseInterface;

    _setConversationId(data.conversationId as unknown as number);
    const exTries = exerciseTries !== null ? exerciseTries : tries;

    setTries(exTries + 1);
    setConversation([]);
    setEndConversation(initialState.initialEndConversation);
    setTextMessage(initialState.initialTextMessage);
    setPage(2);
    setConversationFeedback(initialState.initialConversationFeedback);
    setUserFeedback(null);
  };

  const handleChatBotCommunication = async () => {
    if (!textMessage.length || page !== 2) return;
    setSendingMessage(true);
    try {
      setConversation([...conversation, {role: 'user', content: textMessage}]);
      const data = await chatBotV3Communication({
        message: textMessage,
        conversationId: _conversationId,
      });

      let messages = [...data.conversation];
      const conversationLength = conversation.length;
      if (conversationLength) {
        messages = [...conversation];

        for (let i = conversationLength; i < data.conversation.length; i++) {
          messages.push(data.conversation[i]);
        }
      }

      setConversation(messages);
      setTextMessage('');

      if (data.endConversation) {
        setEndConversation(true);
        return;
      }

      setSendingMessage(false);
    } catch (error) {
      setSendingMessage(false);
    }
  };

  const handleFeedback = async () => {
    if (!feedbacksHaveBeenGenerated) return;
    setSendingMessage(true);
    setFeedbackLoading(true);
    try {
      const feedbackResponse = await getChatAnalyses({conversationIds});

      setFeedbackMessage(feedbackResponse.data);
      setSendingMessage(false);
      setFeedbackLoading(false);

      if (!isCourse) {
        await completeExercise(+userExerciseId);
      }

      const summaryResponse = await getChatSummary({conversationIds});

      setSummary(summaryResponse.data.summary);
    } catch (e) {
      setSendingMessage(false);
      setFeedbackLoading(false);
    }
  };
  const handleShowFeedback = () => {
    setPage(4);
  };

  const onSingleConversationFeedback = async ({value}: {value: boolean}) => {
    setFeedbackLoading(true);
    setPage(3);
    try {
      const response = await generateChatFeedback({
        conversationId: _conversationId,
        isClientSatisfied: value,
      });

      setConversationFeedback(response.feedback);
    } catch (error) {
      setFeedbackLoading(false);
    }
    setFeedbackLoading(false);
  };

  const generateFeedback = async ({value}: {value: boolean}) => {
    if (!isConversationFeedback) {
      await generateChatFeedback({
        conversationId: _conversationId,
        isClientSatisfied: value,
      });
      if (tries !== maxTries && !isConversationFeedback) handleStartExercise();
    }

    if (isConversationFeedback) await onSingleConversationFeedback({value});

    if (tries === maxTries) {
      setFeedbacksHaveBeenGenerated(true);
    }
  };

  const handleRetry = async () => {
    setPage(initialState.initialPage);
    setRetryLoading(true);
    setFeedbacksHaveBeenGenerated(
      initialState.initialFeedbacksHaveBeenGenerated
    );
    setConversationIds(initialState.initialConversationIds);
    setFeedbackMessage(initialState.initialFeedbackMessage);
    setSummary(initialState.summary);
    setFeedbackLoading(initialState.initialFeedbackLoading);

    const exerciseTries = 0;
    await handleStartExercise(exerciseTries);
    setRetryLoading(false);
  };

  const handleEndExercise = () => {
    if (isCourse) {
      return handleAdvance({isQuiz: false});
    }

    return navigate('/practices');
  };

  useEffect(() => {
    handleChatBotCommunication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textMessage]);

  useEffect(() => {
    handleFeedback();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbacksHaveBeenGenerated]);

  useEffect(() => {
    if (_conversationId) {
      setConversationIds([...conversationIds, _conversationId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_conversationId]);

  const onUserConversationFeedback = ({value}: {value: boolean}) => {
    setUserFeedback(value);
    generateFeedback({value});
  };

  const breadcrumbItems = [
    {title: t('anchor.home'), href: '/'},
    {
      title: t('anchor.practices'),
      href: '/practices',
    },
    {title: `${exercise?.title}`},
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  return (
    <Container
      style={{
        width: isCourse ? '100%' : '60%',
      }}
    >
      {!hideBreadCrumbs && (
        <Breadcrumbs mb="sm" separator="→" styles={BreadcrumbsStyle}>
          {breadcrumbItems}
        </Breadcrumbs>
      )}
      {page === 1 && (
        <SlideText
          setPage={setPage}
          exercise={exercise}
          disabledButton={retryLoading}
        />
      )}

      {page === 2 && exercise && (
        <ChatBotV3
          tries={tries}
          maxTries={maxTries}
          exercise={exercise}
          conversation={conversation}
          textMessage={textMessage}
          sendingMessage={sendingMessage}
          setTextMessage={setTextMessage}
          endConversation={endConversation}
          setEndConversation={() => setEndConversation(true)}
          handleShowFeedback={handleShowFeedback}
          onUserConversationFeedback={onUserConversationFeedback}
          userFeedback={userFeedback}
        />
      )}

      {page === 3 && (
        <ConversationFeedback
          conversation={conversation}
          maxTries={maxTries}
          tries={tries}
          text={conversationFeedback}
          endConversation={endConversation}
          handleShowFeedback={handleShowFeedback}
          handleNextButton={() => {
            handleStartExercise();
          }}
        />
      )}

      {page === 4 && tries === maxTries && (
        <ChatBotV3Feedback
          feedbackLoading={feedbackLoading || !feedbacksHaveBeenGenerated}
          handleEndExercise={handleEndExercise}
          feedback={feedbackMessage}
          summary={summary}
          handleRetry={handleRetry}
        />
      )}
    </Container>
  );
};

export default ChatBotV3Container;
