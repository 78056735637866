import React from 'react';

export const Script: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M9.80833 2.16667L13.8333 6.19167V13.8333H2.16667V2.16667H9.80833ZM9.80833 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V6.19167C15.5 5.75 15.325 5.325 15.0083 5.01667L10.9833 0.991667C10.675 0.675 10.25 0.5 9.80833 0.5ZM3.83333 10.5H12.1667V12.1667H3.83333V10.5ZM3.83333 7.16667H12.1667V8.83333H3.83333V7.16667ZM3.83333 3.83333H9.66667V5.5H3.83333V3.83333Z"
        fill="#776AA1"
      />
    </svg>
  );
};
