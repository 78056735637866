import {useQuery} from '@tanstack/react-query';
import {getUserOverview} from 'api/user/user';
import {UserOverviewParams} from 'types/users/users';
import {filterFalsyValues} from 'utils/common';

export const useUserOverview = (params: UserOverviewParams) => {
  const {departmentId, userId, from, to} = filterFalsyValues(params);
  return useQuery(
    ['userOverview', {departmentId, userId, from, to}],
    () => getUserOverview({departmentId, userId, from, to}),
    {
      enabled: !!(departmentId && userId && from && to),
    }
  );
};
