import React from 'react';

// Api
import {useGetAssignedCourses} from '../../api/tanstack-hooks/courses';

// Components
import {MainContainer} from 'components/common/Containers';
import {BasePage} from 'components/common';
import {AllSalesCourses} from 'components/sales-courses';

// Styled Components
export const SalesCourses: React.FC = () => {
  const page = 1;
  const size = 100;
  const {data} = useGetAssignedCourses(page, size);

  return (
    <BasePage>
      <MainContainer>
        {data && <AllSalesCourses courses={data.data.elements} />}
      </MainContainer>
    </BasePage>
  );
};
