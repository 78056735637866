import {Color} from 'enums/common';

export const DisabledTopicButtonStyle = {
  fontSize: 10,
  fontFamily: 'Montserrat',
  fontWeight: 600,
  color: Color.DARK,
  border: '2px solid #26145C',
  borderRadius: 22,
  backgroundColor: 'transparent',
};
