import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

// Utils
import {getCtaTitle} from 'utils/card';

// Types
import {Color} from 'enums/common';
import {AssignedCourse} from 'types/courses/courses';

// Components
import {Space, Text} from '@mantine/core';
import {EntityTileGrid} from 'components/Grids';
import {EntityCard} from 'components/Entity';

const FlexContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  color: ${Color.DARK};
`;

const StyledCourseTitle = styled(Text)`
  font-size: 20px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export interface AllCoursesProps {
  courses: AssignedCourse[];
}

export const AllCourses: React.FC<AllCoursesProps> = ({courses}) => {
  const {t} = useTranslation('Dashboard');

  return (
    <>
      <StyledHeader>
        <StyledCourseTitle>{t('allCourses')}</StyledCourseTitle>
      </StyledHeader>

      {/* Display courses */}
      <FlexContainer style={{width: '100%'}}>
        <EntityTileGrid>
          {courses
            .sort((a, b) => a.learningPercent - b.learningPercent)
            .map(course => (
              <EntityCard
                to={`/courseDetails=${course.courseId}&userCourseId=${course.userCourseId}`}
                key={`${course.courseId}_${course.createDate}`}
                coverImage={course.coverImage}
                title={course.courseName}
                subtitle={course?.courseSubtitle}
                totalChaptersLabel={t('courseCards.chaptersLeft', {
                  chapters: course.totalChapterNum,
                })}
                courseLevelLabel={t(`courseCards.${course.level.name}`)}
                timesCompleted={
                  course.timesCompleted > 0
                    ? `${t('Courses:courseDetails.timesCompleted')}: ${
                        course.timesCompleted
                      }`
                    : ''
                }
                buttonLabel={getCtaTitle(course.learningPercent)}
                learningPercent={course.learningPercent}
              />
            ))}
        </EntityTileGrid>
      </FlexContainer>
      <Space h={40} />
    </>
  );
};
