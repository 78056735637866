import {Color} from 'enums/common';

export const LightButtonStyle = {
  fontFamily: 'Montserrat',
  fontSize: 14,
  fontWeight: 700,
  color: Color.DARK,
  backgroundColor: Color.PURPLE_400,
  borderRadius: 0,
};
