import React, {useContext} from 'react';
import styled from 'styled-components';

// Context
import {UserContext} from 'contexes';

// Types
import {Role} from 'enums/auth';

// Service
import {useGetAssignedCourses} from 'api/tanstack-hooks/courses';

// Components
import {Space} from '@mantine/core';
import {MainContainer} from 'components/common/Containers';
import {BasePage} from 'components/common';
import {AllCourses, ToCompleteCourses} from 'components/courses/user';
import {AssignWorkersToCourses} from 'components/courses/manager';

// Styled components
const StyledWelcomeItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Courses: React.FC = () => {
  const [userContext] = useContext(UserContext);
  const page = 1;
  const size = 1000;

  const {data: assignedCoursesData} = useGetAssignedCourses(page, size);

  return (
    <BasePage>
      <MainContainer>
        <StyledWelcomeItems>
          <Space h={16} />
          {assignedCoursesData &&
            userContext.authority.includes(Role.MEMBER) && (
              <ToCompleteCourses courses={assignedCoursesData.data.elements} />
            )}
        </StyledWelcomeItems>
        {assignedCoursesData && userContext.authority.includes(Role.MEMBER) && (
          <AllCourses courses={assignedCoursesData.data.elements} />
        )}
        {userContext.authority.includes(Role.MANAGER) && (
          <AssignWorkersToCourses />
        )}
      </MainContainer>
    </BasePage>
  );
};
