// hooks.ts

import {useQuery} from '@tanstack/react-query';
import {getAssignableCourses} from 'api/courses';
import {ApiDataResponse, ApiResponse} from 'types/api/ApiResponse';
import {AssignableCourse} from 'types/courses/courses';

export const useGetAssignableCourses = (
  userId: string,
  page: number,
  size: number
) => {
  return useQuery<ApiDataResponse<ApiResponse<AssignableCourse[]>>, Error>(
    ['courses', page, size],
    () => getAssignableCourses(userId, page, size)
  );
};
