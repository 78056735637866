export const styles: {
  [key: string]: (
    base: {[key: string]: string},
    state?: {[key: string]: boolean}
  ) => void;
} = {
  placeholder: base => ({
    ...base,
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#97ACCB',
  }),

  dropdownIndicator: (base, state) => ({
    ...base,
    fontSize: '30px',
    paddingRight: '13px',
    color: state?.isFocused ? '#3F9EFF' : 'white',
    '&:hover': {
      color: '#3F9EFF',
    },
    svg: {
      width: 15,
      color: state?.isDisabled ? 'grey' : '#3F9EFF',
    },
  }),

  control: (base, state) => ({
    ...base,
    border: state?.isFocused
      ? '1px solid #3F9EFF !important'
      : '1px solid #e5e7eb !important',
    boxShadow: state?.isFocused ? '0 0 0 1px #3F9EFF !important' : '',
    '&:hover': {
      border: state?.isFocused ? 0 : 0,
    },
    borderRadius: '0px',
    backgroundColor: '#F0F0F2',
    padding: '3px 0',
    margin: '0',
    fontSize: '16px',
  }),

  menuPortal: base => ({
    ...base,
    zIndex: 50,
  }),

  option: (base, state) => ({
    ...base,
    fontSize: '16px',
    fontWeight: 'bold',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '18px',
    backgroundColor: state?.isFocused ? '#F4F7FA' : '#FFFFFF',
    '&:hover': {
      color: '#3F9EFF',
      backgroundColor: '#F4F7FA',
    },
    color: '#626569',
  }),

  menu: base => ({
    ...base,
    width: '100%',
    boxShadow: '0px',
    borderRadius: '0px',
    padding: 0,
    marginTop: '10px',
    backgroundColor: '#FAFCFE',
  }),

  singleValue: base => ({
    ...base,
    color: '#544680',
    fontSize: '16px',
    fontWeight: 'bold',
  }),
};
