import React, {useState} from 'react';
import styled from 'styled-components';

// Other
import {Color} from '../../enums/common/Color';

// Types
import {Department, DepartmentUser} from 'types/users/users';

// Componets
import {UserPlus, X} from 'tabler-icons-react';
import {Space, Text, Checkbox} from '@mantine/core';
import {Button} from 'components/button';
import {useTranslation} from 'react-i18next';
import {ReactSelect} from 'components/form/ReactSelect';
import {Table} from 'components/table';

// Styled components
const AssignUsersWrapper = styled('div')``;

const ContentWrapper = styled('div')`
  background: #f1f0f4;
  max-width: 65%;
  padding: 1.25rem 0.9375rem;
`;

const StyledTitle = styled(Text)`
  font-size: 20px;
  line-height: 1.3em;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
  padding-bottom: 10px;
`;

const SelectWrapper = styled('div')`
  max-width: 274px;
  min-width: 274px;
`;

const SpaceBetween = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddUsersButton = styled('div')`
  width: 100%;
  background-color: white;
  cursor: default;
  color: #26145c;
  padding: 5px 12px;
  cursor: pointer;
  :hover {
    color: #26145ccc;
  }
  span {
    font-weight: 600;
  }
`;

interface Props {
  courseId: number;
  courseTitle: string;
  departments: Department[];
  assignedUsers: DepartmentUser[];
  onAssignSelectedUsers: (selectedUsers: DepartmentUser[]) => void;
  unasignedUsers: DepartmentUser[];
}

export const _AssignUsers: React.FC<Props> = ({
  courseTitle,
  departments,
  onAssignSelectedUsers,
  assignedUsers,
  unasignedUsers,
}) => {
  const {t} = useTranslation('Courses');
  // State
  const [show, setShow] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
  const [selectedDeparatment, setSelectedDepartment] = useState<
    number | null | unknown
  >(-1);

  // Variables
  const defaultOption = {value: -1, label: t('assignCourse.chooseDepartment')};
  const departmentSelectOptions =
    departments.map(({name, departmentId}) => ({
      label: name,
      value: departmentId.toString(),
    })) || [];
  const allDepartmentOptions = [defaultOption, ...departmentSelectOptions];

  // Handlers
  const handleAddRemoveUser = (id: number) => {
    const idExists = selectedUserIds.findIndex(_id => _id == id) > -1;
    if (!idExists) return setSelectedUserIds([...selectedUserIds, id]);
    setSelectedUserIds([...selectedUserIds.filter(_id => _id !== id)]);
  };

  const handleToggle = () => {
    if (!show) {
      setSelectedUserIds(assignedUsers.map(({userId}) => userId));
    }
    setShow(!show);
  };

  const handleAssingSelectedUsers = () => {
    const selectedUsers = unasignedUsers.filter(({userId}) =>
      selectedUserIds.includes(userId)
    );
    onAssignSelectedUsers(selectedUsers);
    setShow(false);
  };

  // Renderers
  const renderTableRows = () => {
    const filteredUserCourses = unasignedUsers.filter(({departments}) =>
      departments.map(({id}) => id).includes(Number(selectedDeparatment))
    );

    const _unasignedUsers =
      Number(selectedDeparatment) === -1 ? unasignedUsers : filteredUserCourses;

    if (!_unasignedUsers.length) {
      return (
        <tr>
          <td colSpan={4} align="center">
            {t('assignCourse.noUsersToAssigneToCourse')}
          </td>
        </tr>
      );
    }
    return (
      _unasignedUsers.map((user, index) => (
        <tr
          key={`${user.userId}_${index}`}
          onClick={() => handleAddRemoveUser(user.userId)}
        >
          <td>
            <Checkbox
              onChange={() => handleAddRemoveUser(user.userId)}
              style={{paddingTop: '5px'}}
              checked={selectedUserIds.includes(user.userId)}
            />
          </td>
          <td>{user?.userName || ''}</td>
          <td>{user?.departments?.map(({name}) => name).join(', ') || ''}</td>
        </tr>
      )) || null
    );
  };

  return (
    <AssignUsersWrapper>
      <Button disabled={show} onClick={() => handleToggle()}>
        <UserPlus style={{paddingRight: '5px'}} />
        {t('assignCourse.assignWorkers')}
      </Button>
      <Space h="md" />
      {show && (
        <>
          <ContentWrapper>
            <SpaceBetween>
              <StyledTitle>{courseTitle}</StyledTitle>
              <span
                onClick={() => handleToggle()}
                style={{padding: '5px', cursor: 'pointer'}}
              >
                <X />
              </span>
            </SpaceBetween>
            <SelectWrapper>
              <ReactSelect
                placeholder={t('assignCourse.chooseDepartment')}
                value={allDepartmentOptions.find(
                  department => department.value == selectedDeparatment
                )}
                options={allDepartmentOptions}
                onChange={value => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  setSelectedDepartment((value as any).value);
                }}
              />
            </SelectWrapper>

            <Space h="sm" />
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>{t('assignCourse.person')}</th>
                  <th>{t('assignCourse.team')}</th>
                </tr>
              </thead>
              <tbody>{renderTableRows()}</tbody>
            </Table>
            <AddUsersButton>
              <span onClick={() => handleAssingSelectedUsers()}>
                {t('assignCourse.assignSelectedCourses')}
              </span>
            </AddUsersButton>
          </ContentWrapper>

          <Space h="md" />
        </>
      )}
    </AssignUsersWrapper>
  );
};
