import React from 'react';
import InternalReactSelect, {Props as ReactSelectProps} from 'react-select';

// Styles
import {styles} from './select.styles';

// This component is exported as so, so we can change the design in the future
export const ReactSelect = (props: ReactSelectProps) => {
  return (
    <InternalReactSelect
      styles={styles}
      components={{
        IndicatorSeparator: () => null,
      }}
      {...props}
    />
  );
};
