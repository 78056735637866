import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {Text} from 'components/common';

import {Color} from 'enums/common';
import {RichTextContent} from 'components/RichText';

const ReceivedMessageContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;

  animation: message-received 0.5s ease-in-out;

  @keyframes message-received {
    0% {
      opacity: 0;
      transform: translateX(-20px);
    }
    100% {
      opacity: 1;
    }
  }
`;

const CustomerAvatar = styled.div`
  align-self: flex-end;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url('/images/neutral-avatar.png');
  background-size: cover;
`;

const MessageContent = styled.div`
  max-width: 450px;
  background-color: ${Color.PURPLE_500};
  padding: 16px 16px 12px 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
`;

interface ReceivedMessageProps {
  message: string;
  date?: Date;
  hideDate?: boolean;
  htmlContent?: boolean;
}

export const ReceivedMessage: React.FC<ReceivedMessageProps> = ({
  message,
  date,
  hideDate = false,
  htmlContent = false,
}) => {
  const {t} = useTranslation('Practices');

  const dateString = date
    ? date.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
    : null;

  return (
    <ReceivedMessageContainer>
      <CustomerAvatar />
      <div>
        <Text fontSize={12} fontWeight={500} color={Color.GRAY_200} mb={4}>
          {t('voicebot.customer')}
        </Text>
        <MessageContent>
          {!htmlContent && (
            <Text
              fontSize={14}
              fontWeight={500}
              color={Color.PURPLE_TXT}
              mb={8}
            >
              {message}
            </Text>
          )}
          {htmlContent && <RichTextContent htmlContent={message} />}

          {!hideDate && (
            <Text fontSize={12} fontWeight={500} color={Color.ALPHA_DARK_40}>
              {dateString}
            </Text>
          )}
        </MessageContent>
      </div>
    </ReceivedMessageContainer>
  );
};
