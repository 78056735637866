import React from 'react';
import styled from 'styled-components';

// Components
import {Text} from 'components/common';

// Types
import {Color} from 'enums/common';
import Typing from 'components/typing/Typing';
import {useTranslation} from 'react-i18next';

const CustomerTypingContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;

  animation: message-received 0.5s ease-in-out;

  @keyframes message-received {
    0% {
      opacity: 0;
      transform: translateX(-20px);
    }
    100% {
      opacity: 1;
    }
  }
`;

const CustomerAvatar = styled.div`
  align-self: flex-end;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url('/images/neutral-avatar.png');
  background-size: cover;
`;

const TypingContent = styled.div`
  max-width: 450px;
  background-color: ${Color.PURPLE_500};
  padding: 16px 16px 12px 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
`;

const RecordingContainer = styled.div`
  align-self: flex-end;
  display: flex;
  gap: 10px;
  margin-top: 10px;

  animation: message-sent 0.5s ease-in-out;

  @keyframes message-sent {
    0% {
      opacity: 0;
      transform: translateX(20px);
    }
    100% {
      opacity: 1;
    }
  }
`;

const RecordingContent = styled.div`
  max-width: 450px;
  background-color: ${Color.PURPLE_TXT};
  padding: 16px 16px 12px 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
`;

const SenderAvatar = styled.div`
  align-self: flex-end;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url('/images/avatar.png');
  background-size: cover;
`;

interface Props {
  sender?: boolean;
}

export const ChatbotV3Typing = ({sender = false}: Props) => {
  const {t} = useTranslation('Practices');

  return (
    <>
      {sender ? (
        <RecordingContainer>
          <div>
            <RecordingContent>
              <Typing darkDots={!sender} />
            </RecordingContent>
          </div>
          <SenderAvatar />
        </RecordingContainer>
      ) : (
        <CustomerTypingContainer>
          <CustomerAvatar />
          <div>
            <Text fontSize={12} fontWeight={500} color={Color.GRAY_200} mb={4}>
              {t('voicebot.customer')}
            </Text>
            <TypingContent>
              <Typing darkDots={!sender} />
            </TypingContent>
          </div>
        </CustomerTypingContainer>
      )}
    </>
  );
};
