import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Link, useNavigate} from 'react-router-dom';

import {Accordion, Button, Group, Image} from '@mantine/core';

// Types
import {Color} from 'enums/common';
import {Chapter} from 'types/chapters/chapters';

// Hooks
import {useQueryParams} from 'hooks/useQueryParams';

// utils
import {getChapterId} from 'utils/course.util';

// Components
import {Text} from 'components/common';
import {AccordionLabel} from './AccordionLabel';

const AccordionDropdownContainer = styled.div`
  .mantine-Accordion-panel {
    background-color: white;
  }

  .mantine-Accordion-control {
    background-color: ${Color.PURPLE_500};
    margin-bottom: 5px;
    padding: 0.6rem 0.3rem;
  }
`;

const NavbarItem = styled.div<{allowSelection: boolean}>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  cursor: ${({allowSelection}) => (allowSelection ? 'pointer' : 'default')};
`;

const NavBarItemTitle = styled.div`
  flex: 1;
  font-size: 14px;
  color: ${Color.DARK};
  padding: 0 10px;
  font-weight: ${({pageActive}: {pageActive: boolean}) =>
    pageActive ? 700 : 500};
`;

const CourseHeader = () => {
  return {
    label: {
      justifyContent: 'flex-start',
    },
    root: {
      display: 'flex',
      fontSize: '14px',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      color: Color.DARK,
      marginBottom: '8px',
      borderRadius: 0,
      backgroundColor: '#cdcdf1',
      width: '100%',
    },
  };
};

export interface AccordionDropdownProps {
  chapters: Chapter[];
  allowSelection: boolean;
  isLeadershipCourse?: boolean;
  overviewUrl?: string;
  openAllItems: boolean;
}

export function AccordionDropdown({
  chapters,
  allowSelection,
  isLeadershipCourse = false,
  overviewUrl = '',
  openAllItems = false,
}: AccordionDropdownProps) {
  // Declarations
  const query = useQueryParams();
  const userCourseId = Number(query.get('userCourseId'));
  const pIndex = Number(query.get('pIndex'));
  const cIndex = Number(query.get('cIndex'));

  // Hooks
  const {t} = useTranslation('Courses');
  const navigator = useNavigate();

  // State
  const [activeChapters, setActiveChapters] = useState<string[]>([]);

  const checkIfPageIsActive = (pageIndex: number, chapterIndex: number) => {
    return pageIndex === pIndex && chapterIndex === cIndex;
  };

  const redirectTo = (pageIndex: number, chapterIndex: number) => {
    if (allowSelection) {
      const leaderShipParam = isLeadershipCourse ? '&isLeadership=true' : '';
      navigator(
        `/course?pIndex=${pageIndex}&cIndex=${chapterIndex}&userCourseId=${userCourseId}${leaderShipParam}`
      );
    }
  };

  const handleToggleChapter = (chapterId: string) => {
    if (activeChapters.includes(chapterId))
      return setActiveChapters(
        activeChapters.filter(activeId => activeId === chapterId)
      );

    setActiveChapters([chapterId]);
  };

  useEffect(() => {
    setActiveChapters(
      openAllItems
        ? chapters.map(chapter => getChapterId(chapter))
        : [getChapterId(chapters[cIndex])]
    );
  }, [cIndex, chapters, openAllItems]);

  return (
    <AccordionDropdownContainer>
      <Button
        component={Link}
        disabled={!overviewUrl}
        leftIcon={<Image src="/images/open.svg" />}
        style={CourseHeader().root}
        to={overviewUrl}
      >
        {t('courseDetails.courseOverview')}
      </Button>
      <Accordion multiple value={activeChapters} chevronPosition="left">
        {chapters.map((chapter, chapterIndex) => {
          return (
            <Accordion.Item
              value={`${getChapterId(chapter)}`}
              key={chapterIndex}
            >
              <Accordion.Control
                onClick={() =>
                  handleToggleChapter(getChapterId(chapters[chapterIndex]))
                }
              >
                <AccordionLabel chapter={chapter} />
              </Accordion.Control>
              <Accordion.Panel>
                {chapter.pages.map((page, chapterPageIndex) => (
                  <NavbarItem
                    allowSelection={allowSelection}
                    key={chapterPageIndex}
                    onClick={() => redirectTo(chapterPageIndex, chapterIndex)}
                  >
                    <Group mb={10}>
                      <Image
                        style={{paddingTop: 3}}
                        height={16}
                        width={16}
                        src={
                          page.isCompleted
                            ? '/images/done.svg'
                            : page.resources[0]
                            ? '/images/play.svg'
                            : '/images/paper.svg'
                        }
                      />
                      <NavBarItemTitle
                        pageActive={checkIfPageIsActive(
                          chapterPageIndex,
                          chapterIndex
                        )}
                      >
                        {page.pageTitle}
                      </NavBarItemTitle>
                    </Group>
                  </NavbarItem>
                ))}
                {chapter.exercises.map((exercise, index) => (
                  <NavbarItem allowSelection={false} key={index}>
                    <Group mb={10}>
                      <Image
                        height={16}
                        width={exercise.isCompleted ? 16 : 12}
                        ml={exercise.isCompleted ? 0 : 1.5}
                        src={
                          exercise.isCompleted
                            ? '/images/done.svg'
                            : '/images/voicebot.svg'
                        }
                      />
                      <Text
                        fontSize={14}
                        fontWeight={
                          checkIfPageIsActive(
                            index + chapter.pages.length,
                            chapterIndex
                          )
                            ? 700
                            : 500
                        }
                        color={Color.DARK}
                      >
                        {exercise.title}
                      </Text>
                    </Group>
                  </NavbarItem>
                ))}
                {chapter.quiz && (
                  <NavbarItem
                    allowSelection={allowSelection}
                    onClick={() =>
                      redirectTo(
                        chapter.pages.length + chapter.exercises.length,
                        chapterIndex
                      )
                    }
                  >
                    <Group mb={10}>
                      <Image
                        height={16}
                        width={16}
                        src={
                          chapter.quiz.isCompleted
                            ? '/images/done.svg'
                            : '/images/quiz.svg'
                        }
                      />
                      <Text
                        fontSize={14}
                        fontWeight={
                          checkIfPageIsActive(
                            chapter.pages.length + chapter.exercises.length,
                            chapterIndex
                          )
                            ? 700
                            : 500
                        }
                        color={Color.DARK}
                      >
                        {t('courseDetails.quiz')}
                      </Text>
                    </Group>
                  </NavbarItem>
                )}
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </AccordionDropdownContainer>
  );
}
