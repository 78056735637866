import React from 'react';
import styled from 'styled-components';
import {ChatBotV3Conversation} from 'containers/chatbots/ChatBotV3Container';
import {Color} from 'enums/common';
import {useTranslation} from 'react-i18next';
import {Button, Loader} from '@mantine/core';
import {
  SlideContent,
  SlideContentHeader,
  SlideFooter,
} from 'components/slideText';
import {Text} from 'components/common';
import {ThumbUp} from 'tabler-icons-react';
import {RichTextContent} from 'components/RichText';

type Props = {
  text: string | null;
  handleNextButton: () => void;
  handleShowFeedback: () => void;
  conversation: ChatBotV3Conversation[];
  maxTries: number;
  endConversation: boolean;
  tries: number;
};

const Container = styled.div`
  box-shadow: 0 2px 4px 0 #4d3c8233;
  background-color: '#fefefe !important';
  width: 100%;
  margin: 0 auto;
  min-height: 280px;
`;

const LoaderWrapper = styled.div`
  width: fit-content;
  margin: 0 auto;
  padding: 2rem 0;
`;

const ConversationFeedback = ({
  text,
  handleNextButton,
  handleShowFeedback,
  maxTries,
  tries,
}: Props) => {
  const {t} = useTranslation('Practices');

  return (
    <Container>
      <div>
        <SlideContent style={{height: '100%'}}>
          <SlideContentHeader>
            <Text fontSize={18} fontWeight={700} color={Color.DARK}>
              {t('feedback')}
            </Text>
          </SlideContentHeader>

          {text === null && (
            <LoaderWrapper>
              <Loader
                variant="dots"
                color={Color.DARK}
                size="md"
                height={10}
                style={{alignSelf: 'center'}}
              />
            </LoaderWrapper>
          )}

          {text && text.length > 0 && <RichTextContent htmlContent={text} />}

          {text !== null && text.length === 0 && <>No feedback!</>}
        </SlideContent>

        <SlideFooter style={{position: 'relative'}}>
          {text !== null && tries < maxTries && (
            <Button
              onClick={handleNextButton}
              style={{
                height: '50',
                width: '150',
                fontFamily: 'Montserrat',
                fontSize: 14,
                fontWeight: 700,
                color: Color.WHITE,
                backgroundColor: Color.DARK,
                borderRadius: 0,
                position: 'absolute',
                right: 15,
              }}
            >
              {t('next')}
            </Button>
          )}

          {text !== null && tries === maxTries && (
            <Button
              onClick={handleShowFeedback}
              style={{
                fontFamily: 'Montserrat',
                fontSize: 14,
                fontWeight: 700,
                color: Color.WHITE,
                backgroundColor: Color.DARK,
                borderRadius: 0,
                position: 'absolute',
                right: 15,
              }}
              leftIcon={<ThumbUp />}
            >
              {t('voicebot.analyzeConversation')}
            </Button>
          )}
        </SlideFooter>
      </div>
    </Container>
  );
};

export default ConversationFeedback;
