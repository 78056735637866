import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';

import {BasePage} from 'components/common';
import {
  CoursePreviewFooter,
  CoursePreviewHeader,
  CourseSidebar,
} from 'components/courses/common/course-preview';

import {useGetUserCourseDetails} from 'api/tanstack-hooks/courses/useGetUserCourseDetails';
import {useGetCourseChapters} from 'api/tanstack-hooks/courses/useGetCourseChapters';

const CourseContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f6f6f8;
  min-height: calc(100vh - 80px);
`;

const MainContentContainer = styled.div`
  margin: 20px auto;
  width: 60%;
`;

export const CoursePreview: React.FC = () => {
  const {userCourseId} = useParams();

  const {data: courseDetails} = useGetUserCourseDetails(Number(userCourseId));
  const {data: courseChapters} = useGetCourseChapters(Number(userCourseId));

  return (
    <BasePage>
      {courseDetails && courseChapters && (
        <CourseContainer>
          <MainContentContainer>
            <CoursePreviewHeader
              chapters={courseChapters.data}
              course={courseDetails.data}
              isLeadership={false}
              userCourseId={userCourseId || ''}
            />
            <CoursePreviewFooter course={courseDetails.data} />
          </MainContentContainer>
          <CourseSidebar
            overviewUrl=""
            chapters={courseChapters.data}
            allowSelection={false}
          />
        </CourseContainer>
      )}
    </BasePage>
  );
};
