import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {ArrowNarrowLeft, ArrowNarrowRight} from 'tabler-icons-react';
import ReactMarkdown from 'react-markdown';

import {Button, Space} from '@mantine/core';

import {DarkButtonStyle, LightButtonStyle} from 'styles/buttons';

import {VoicebotV2Conversation} from 'components/practices/voicebots/v2';
import {Text} from 'components/common';

import {Color} from 'enums/common';

import {Conversation} from 'types/practices';
import {ConversationInitialize} from 'types/conversations/conversations';

const SlideContent = styled.div`
  min-height: 500px;
  padding: 32px 32px 0 32px;
  background-color: ${Color.WHITE};
  border: 1px solid #f1f0f0;
`;

const SlideContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f1f0f0;
  padding-bottom: 12px;
`;

const SlideFooter = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  background-color: ${Color.GRAY_500};
  border: 1px solid #e6e6ea;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
  height: 88px;
`;

export interface VoiceBotV2SearchParams {
  pageIndex: number;
  showVoicebot?: boolean;
}

export interface VoicebotV2Props {
  conversation: Conversation;
  conversationInitialize: ConversationInitialize;
  rasaBotKey: string;
  callback: ({isQuiz}: {isQuiz?: boolean | undefined}) => void;
  nextIterationCallback?: () => void;
  onSearchParamsChange?: (props: VoiceBotV2SearchParams) => void;
  currentPageIndex?: number;
  showVoicebot?: boolean;
  isLoading?: boolean;
}

export const VoicebotV2: React.FC<VoicebotV2Props> = ({
  conversation,
  conversationInitialize,
  rasaBotKey,
  callback,
  nextIterationCallback,
  currentPageIndex = 0,
  showVoicebot = false,
  onSearchParamsChange,
  isLoading = false,
}) => {
  // Hooks
  const {t} = useTranslation('Practices');

  // State
  const [currentIndex, setCurrentIndex] = useState(currentPageIndex);
  const [_showVoicebot, setShowVoicebot] = useState(showVoicebot);

  const currentPage = conversation.section.content[currentIndex];

  // Handlers
  const handleGetStarted = () => {
    setShowVoicebot(true);
    setCurrentIndex(0);
  };

  // Effects
  useEffect(() => {
    onSearchParamsChange?.({
      pageIndex: currentIndex,
      showVoicebot: _showVoicebot,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, _showVoicebot]);

  useEffect(() => {
    if (currentIndex !== currentPageIndex) {
      setCurrentIndex(currentPageIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageIndex]);

  useEffect(() => {
    if (showVoicebot !== _showVoicebot) {
      setShowVoicebot(showVoicebot);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showVoicebot]);

  return (
    <>
      {isLoading && (
        <SlideContent>
          <SlideContentHeader />
          <SlideFooter />
        </SlideContent>
      )}
      {_showVoicebot && !isLoading && (
        <VoicebotV2Conversation
          conversationInitialize={conversationInitialize}
          conversationId={conversation.conversation_id}
          voicebotName={conversation.voicebot_name}
          rasaBotKey={rasaBotKey}
          callback={callback}
          nextIterationCallback={nextIterationCallback}
          currentPageIndex={currentPageIndex}
          onSearchParamsChange={onSearchParamsChange}
        />
      )}
      {!_showVoicebot && !isLoading && (
        <>
          <SlideContent>
            <SlideContentHeader>
              <Text fontSize={18} fontWeight={700} color={Color.DARK}>
                {currentPage.page_title}
              </Text>
            </SlideContentHeader>
            {currentPage.paragraphs.map((paragraph, index) => (
              <Text
                key={index}
                fontSize={16}
                fontWeight={500}
                color={Color.PURPLE_TXT}
                mt={20}
              >
                <ReactMarkdown skipHtml={false}>
                  {paragraph.replaceAll('</br>', ' \n')}
                </ReactMarkdown>
              </Text>
            ))}
          </SlideContent>
          <SlideFooter>
            {currentIndex > 0 && (
              <Button
                size="md"
                leftIcon={
                  <ArrowNarrowLeft
                    size={26}
                    strokeWidth={1.4}
                    color={'#26145C'}
                  />
                }
                onClick={() => setCurrentIndex(currentIndex - 1)}
                style={LightButtonStyle}
              >
                {t('prev')}
              </Button>
            )}
            <Space w={10} />
            {currentIndex + 1 < conversation.section.content.length ? (
              <Button
                size="md"
                rightIcon={
                  <ArrowNarrowRight
                    size={26}
                    strokeWidth={1.4}
                    color={'white'}
                  />
                }
                onClick={() => setCurrentIndex(currentIndex + 1)}
                style={DarkButtonStyle}
              >
                {t('next')}
              </Button>
            ) : (
              <Button
                size="md"
                onClick={handleGetStarted}
                style={DarkButtonStyle}
              >
                {t('getStarted')}
              </Button>
            )}
          </SlideFooter>
        </>
      )}
    </>
  );
};
