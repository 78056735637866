import React from 'react';

interface InfoProps {
  className?: string;
}

export const Info: React.FC<InfoProps> = ({className}) => {
  return (
    <svg
      width="42"
      height="41"
      viewBox="0 0 42 41"
      fill="none"
      className={className}
    >
      <circle cx="20.5" cy="20" r="20" fill="#E7E7F8" />
      <path
        d="M26.3333 11.667H14.6667C13.75 11.667 13 12.417 13 13.3337V25.0003C13 25.917 13.75 26.667 14.6667 26.667H18L20.5 29.167L23 26.667H26.3333C27.25 26.667 28 25.917 28 25.0003V13.3337C28 12.417 27.25 11.667 26.3333 11.667ZM26.3333 25.0003H22.3083L21.8167 25.492L20.5 26.8087L19.175 25.4837L18.6917 25.0003H14.6667V13.3337H26.3333V25.0003ZM20.5 24.167L22.0667 20.7337L25.5 19.167L22.0667 17.6003L20.5 14.167L18.9333 17.6003L15.5 19.167L18.9333 20.7337L20.5 24.167Z"
        fill="#5C4D8A"
      />
    </svg>
  );
};
