import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {Image, Space, Text} from '@mantine/core';

import {ForgotPasswordForm} from '../../components/auth/ForgotPasswordForm';

import {Color} from '../../enums/common';

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-color: white;
`;

const StyledLogo = styled.div`
  position: absolute;
  top: 1.25rem;
  left: 2.5rem;
  width: 7.5rem;
  cursor: pointer;
`;

const StyledLoginPage = styled.div`
  width: 50%;
  min-height: 100vh;
`;

const StyledImage = styled.div`
  width: 50%;
  min-height: 100vh;
  background-color: #cce9e5;
  background-size: cover;
  background-position: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLogin = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 9rem;
  width: 25.25rem;
  margin-left: auto;
  margin-right: 6.875rem;
`;

const ForgotText = styled(Text)`
  color: ${Color.DARK};
  font-weight: 700;
  font-size: 1.75rem;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0.5rem;
`;

const EmailText = styled(Text)`
  color: ${Color.LIGHT_BLUE};
  font-weight: 500;
  font-size: 1.125rem;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 1rem;
`;

export const ForgotPassword: React.FC = () => {
  const {t} = useTranslation('Login');

  return (
    <StyledWrapper>
      <StyledLogo>
        <Image src="../../images/logo.png" alt="future-ready-logo" />
      </StyledLogo>
      <StyledLoginPage>
        <StyledLogin>
          <ForgotText>{t('forgotPassword')}</ForgotText>
          <EmailText>{t('enterEmail')}</EmailText>
          <Space h="xl" />
          <ForgotPasswordForm />
        </StyledLogin>
      </StyledLoginPage>
      <StyledImage>
        <Image
          width={500}
          height={500}
          src="../../images/forgotpassword.png"
          alt="future-ready"
        />
      </StyledImage>
    </StyledWrapper>
  );
};
