// useCurrentWeeksOfYear.ts
import {format, parseISO} from 'date-fns';

const DATE_FORMAT = 'yyyy-MM-dd';
const DATE_INPUT_FORMAT = 'yyyy-MM-dd';

export const formatDate = (date: string | Date | undefined | null): string => {
  if (!date) return '';

  try {
    const parsedDate = typeof date === 'string' ? parseISO(date) : date;
    return format(parsedDate, DATE_FORMAT);
  } catch (error) {
    console.error('Invalid date:', date, error);
    return '';
  }
};

export const formatToInputDate = (date: string | Date): string => {
  if (!date) return '';

  try {
    const parsedDate = new Date(date);
    return format(parsedDate, DATE_INPUT_FORMAT);
  } catch (error) {
    console.error('Invalid date:', date, error);
    return '';
  }
};

export const useCurrentWeeksOfYear = (
  t?: (key: string, options?: unknown) => string
) => {
  const getCurrentWeeksOfYear = () => {
    const currentYear = new Date().getFullYear();
    const weeks = [];
    const firstDayOfYear = new Date(currentYear, 0, 1);
    const today = new Date();

    let currentDate = new Date(firstDayOfYear);
    let weekCount = 1;

    if (currentDate.getDay() !== 1) {
      const fromDate = new Date(currentDate);
      const toDate = new Date(currentDate);
      toDate.setDate(toDate.getDate() + (7 - currentDate.getDay()));

      weeks.push({
        label: `${t ? t('Common:week') : 'Week'} ${weekCount}`,
        value: {
          from: formatDate(fromDate),
          to: formatDate(toDate),
        },
      });

      currentDate = new Date(toDate);
      currentDate.setDate(currentDate.getDate() + 1);
      weekCount++;
    }

    while (currentDate.getFullYear() === currentYear && currentDate <= today) {
      const fromDate = new Date(currentDate);
      const toDate = new Date(currentDate);
      toDate.setDate(toDate.getDate() + 6);

      const endDate = toDate > today ? today : toDate;

      weeks.push({
        label: `${t ? t('Common:week') : 'Week'} ${weekCount}`,
        value: {
          from: formatDate(fromDate),
          to: formatDate(endDate),
        },
      });

      currentDate.setDate(currentDate.getDate() + 7);
      weekCount++;
    }

    return weeks;
  };

  return {getCurrentWeeksOfYear};
};
