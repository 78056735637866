import {createAxiosInstance} from 'api/config';

import {ApiDataResponse, ApiResponse} from 'types/api/ApiResponse';
import {
  AssignableCourse,
  AssignedCourse,
  DepartmentProgress,
  EmployeeCourse,
  WorkerProgres,
} from 'types/courses/courses';
import {StartChapterPayload, StartChapterResponse} from 'types/courses/Chapter';
import {Chapter} from 'types/chapters/chapters';
import {CourseUser, UserProfileInfo} from 'types/users/users';

import {
  AssignedCourses,
  Course,
  CoursePc,
  CoursesProgress,
  ToAssignCourses,
} from 'enums/courses/urls';
import {LearningProgress} from 'enums/learning-progress/urls';
import {CompleteChapterPagePayload} from '../../types/courses/ChapterPage';
import {CompleteQuizPayload} from '../../types/courses';
import {EmployeePractice} from 'types/practices';

const axios = createAxiosInstance();

export const getAssignedCourses = async (page: number, size: number) => {
  const {data} = await axios.get<
    ApiDataResponse<ApiResponse<AssignedCourse[]>>
  >(`/${AssignedCourses.COURSES}`, {
    params: {
      page,
      size,
    },
  });

  return data;
};

export const getUnassignedCourses = async (page: number, size: number) => {
  const {data} = await axios.get<
    ApiDataResponse<ApiResponse<AssignedCourse[]>>
  >(`/${ToAssignCourses.COURSES}`, {
    params: {
      page,
      size,
    },
  });

  return data;
};

export const getDepartmentsProgress = async (page: number, size: number) => {
  const {data} = await axios.get<ApiDataResponse<DepartmentProgress[]>>(
    `/${CoursesProgress.PROGRESS}`,
    {
      params: {
        page,
        size,
      },
    }
  );

  return data;
};

export const getDepartmentWorkersProgress = async (
  departmentId: number | null,
  page: number,
  size: number
) => {
  if (!departmentId) return null;
  const {data} = await axios.get<ApiDataResponse<ApiResponse<WorkerProgres[]>>>(
    `/${CoursesProgress.PROGRESS}/${departmentId}`,
    {
      params: {
        page,
        size,
      },
    }
  );

  return data;
};

export const getCourseOverview = async (id: number) => {
  const {data} = await axios.get<ApiDataResponse<AssignedCourse>>(
    `/${CoursePc.COURSE}/${id}/${Course.OVERVIEW}`
  );

  return data;
};

export const getUserCourseDetails = async (userCourseId: number) => {
  const {data} = await axios.get<ApiDataResponse<AssignedCourse>>(
    `/${CoursePc.COURSE}/${userCourseId}`
  );

  return data;
};

export const getCourseChapters = async (userCourseId: number) => {
  const {data} = await axios.get<ApiDataResponse<Chapter[]>>(
    `/${CoursePc.COURSE}/${userCourseId}/${Course.CHAPTERS}`
  );

  return data;
};

export const getCourseChaptersOverview = async (courseId: number) => {
  const {data} = await axios.get<ApiDataResponse<Chapter[]>>(
    `/${CoursePc.COURSE}/${courseId}/${Course.CHAPTERS_OVERVIEW}`
  );

  return data;
};

export const getCourseUsers = async (courseId: number) => {
  const {data} = await axios.get<ApiDataResponse<{elements: CourseUser[]}>>(
    `/${CoursePc.COURSE}/${courseId}/users?page=1&size=1000`
  );

  return data;
};

export const assignUserToCourse = async (
  courseId: number,
  userIds: number[]
): Promise<void> => {
  await axios.post<ApiDataResponse<{elements: CourseUser[]}>>(
    `/${CoursePc.COURSE}/assign`,
    {
      courseId,
      userIds,
    }
  );
};

export const startCourse = async (userCourseId: number) => {
  await axios.put<ApiDataResponse<void>>(
    `/${LearningProgress.LEARNING_PROGRESS}/${userCourseId}/${LearningProgress.START_COURSE}`
  );
};

export const completeCourse = async (userCourseId: number) => {
  await axios.put<ApiDataResponse<void>>(
    `/${LearningProgress.LEARNING_PROGRESS}/${userCourseId}/${LearningProgress.COMPLETE_COURSE}`
  );
};

export const startChapter = async (payload: StartChapterPayload) => {
  const {data} = await axios.post<ApiDataResponse<StartChapterResponse>>(
    `/${LearningProgress.LEARNING_PROGRESS}/${LearningProgress.START_CHAPTER}`,
    payload
  );

  return data;
};

export const completeChapter = async (userChapterId: number) => {
  await axios.put<ApiDataResponse<void>>(
    `/${LearningProgress.LEARNING_PROGRESS}/${userChapterId}/${LearningProgress.COMPLETE_CHAPTER}`
  );
};

export const completeChapterPage = async (
  payload: CompleteChapterPagePayload
) => {
  await axios.post<ApiDataResponse<void>>(
    `/${LearningProgress.LEARNING_PROGRESS}/${LearningProgress.COMPLETE_PAGE}`,
    payload
  );
};

export const completeQuiz = async (payload: CompleteQuizPayload) => {
  await axios.post<ApiDataResponse<void>>(
    `/${LearningProgress.LEARNING_PROGRESS}/${LearningProgress.COMPLETE_QUIZ}`,
    payload
  );
};

export const startExercise = async (userExerciseId: number) => {
  await axios.put<ApiDataResponse<void>>(
    `/${LearningProgress.LEARNING_PROGRESS}/${userExerciseId}/${LearningProgress.START_EXERCISE}`
  );
};

export const startUserExercise = async ({
  userChapterId,
  exerciseId,
}: {
  userChapterId: number;
  exerciseId: number;
}) => {
  const {data} = await axios.put<ApiDataResponse<void>>(
    `/${LearningProgress.LEARNING_PROGRESS}/${LearningProgress.START_EXERCISE}`,
    {
      userChapterId,
      exerciseId,
    }
  );
  return data.data;
};

export const completeExercise = async (userExerciseId: number) => {
  await axios.put<ApiDataResponse<void>>(
    `/${LearningProgress.LEARNING_PROGRESS}/${userExerciseId}/${LearningProgress.COMPLETE_EXERCISE}`
  );
};

export const getUserDetails = async (
  id: number,
  page: number,
  size: number
) => {
  const {data} = await axios.get<
    ApiDataResponse<{
      courses: {
        elements: EmployeeCourse[];
      };
      exercises: {elements: EmployeePractice[]};
      user: UserProfileInfo;
    }>
  >(`/pc-user/${id}/details/?page=${page}&size=${size}`);

  return data.data;
};

export const assignCourses = async (
  userIds: string[],
  courseIds: string[]
): Promise<string> => {
  const response = await axios.post<string>('/users-courses/assign-users', {
    itemIds: courseIds,
    userIds,
  });
  return response.data;
};

export const getAssignableCourses = async (
  userId: string,
  page: number,
  size: number
): Promise<ApiDataResponse<ApiResponse<AssignableCourse[]>>> => {
  const response = await axios.get<
    ApiDataResponse<ApiResponse<AssignableCourse[]>>
  >(`/${CoursePc.COURSE}/${userId}/assign`, {
    params: {page, size},
  });
  return response.data;
};
