export const generateRandomString = (length: number) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const filterFalsyValues = <T extends Record<string, any>>(
  params: T
): T => {
  return Object.fromEntries(
    Object.entries(params).filter(([, value]) => Boolean(value))
  ) as T;
};

export const capitalizeFirstLetter = (value: string) => {
  const _value = value.toLowerCase();
  return _value.charAt(0).toUpperCase() + _value.slice(1);
};
