import {useCallback, useState} from 'react';

// Types
import {ExerciseType} from 'enums/practices';
import {ListPractice} from 'types/practices';

// Constants
export const CourseLevels = {
  expert: 1,
  Expert: 1,
  intermediate: 2,
  Intermediate: 2,
  beginner: 3,
  Beginner: 3,
};

export const CourseFilters = {
  level: 'level',
  date: 'date',
};

export const getExerciseUniqueSkills = (exercises: ListPractice[]) => {
  const _skills: string[] = [];
  exercises?.forEach(({skills}) => {
    skills?.forEach(skill => {
      !_skills.includes(skill) && _skills.push(skill);
    });
  });
  return _skills.sort();
};

export const useFilterExercises = (exercises: ListPractice[]) => {
  const [filter, setFiler] = useState<null | string>(null);
  const [topic, setTopic] = useState<null | string>(null);

  const handleSort = useCallback(
    (a: ListPractice, b: ListPractice) => {
      const aLevel = CourseLevels[a?.level?.name];
      const bLevel = CourseLevels[b?.level?.name];

      if (filter === CourseFilters?.level) return bLevel - aLevel;

      if (filter === CourseFilters.date) {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      }
      return 0;
    },
    [filter]
  );

  const filteredExercises = exercises
    .filter(exercise => (topic ? exercise?.skills?.includes(topic) : true))
    .sort(handleSort);

  return {filter, setFiler, topic, setTopic, filteredExercises};
};

export const generateExerciseRedirectUrl = (practice: ListPractice) => {
  if (practice.exerciseType === ExerciseType.CHAT_BOT_V1) {
    return `/practices/${practice.userExerciseId}`;
  } else if (practice.exerciseType === ExerciseType.CHAT_BOT_V2) {
    return `/practices/v2/rasaBotKey=${practice.rasaBotKey}&userExerciseId=${practice.userExerciseId}`;
  } else {
    return `/practices/v3/userExerciseId=${practice.userExerciseId}`;
  }
};
