import React from 'react';
import {AlertCircle, Eye, EyeOff} from 'tabler-icons-react';
import {Link, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {
  Anchor,
  Button,
  Checkbox,
  Group,
  PasswordInput,
  Space,
  Text,
  TextInput,
} from '@mantine/core';
import {useForm} from '@mantine/form';

import {Color} from '../../enums/common';

import {useLogin} from '../../api/tanstack-hooks/auth';
import {AxiosError} from 'axios';

const StyledForgot = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const StyledChecked = styled.div`
  display: flex;
  align-items: center;
`;

const StyledText = styled(Text)`
  color: ${Color.DARK};
  font-weight: 400;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  margin-left: 0.5rem;
`;

const StyledError = styled.div`
  display: flex;
  align-items: center;
`;

export const LoginForm: React.FC = () => {
  const {t} = useTranslation('Login');
  const navigate = useNavigate();

  const createLoginMutation = useLogin();

  const form = useForm({
    initialValues: {
      account: '',
      password: '',
    },
  });

  const handleSubmit = () => {
    createLoginMutation.mutate(
      {
        ...form.values,
      },
      {
        onSuccess: () => {
          navigate(`/`);
        },
        onError: error => {
          const {statusText} = (error as AxiosError).response || {};
          form.setFieldError(
            'password',
            <>
              <StyledError>
                <AlertCircle size={16} />
                <Text
                  style={{
                    marginLeft: '0.5em',
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  {statusText}
                </Text>
              </StyledError>
            </>
          );
        },
      }
    );
  };
  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Group>
        <TextInput
          {...form.getInputProps('account')}
          required
          size="lg"
          radius="xs"
          variant="unstyled"
          placeholder={t('username')}
          style={{
            border: '0.125rem solid rgba(38,20,92,.12)',
            width: '404px',
            height: '52px',
            marginBottom: '8px',
          }}
          sx={{
            input: {
              fontWeight: 500,
              fontSize: '1rem',
              padding: '0 .9375rem',
              fontFamily: 'Montserrat',
            },
          }}
        />
        <PasswordInput
          {...form.getInputProps('password')}
          required
          size="lg"
          radius="xs"
          variant="unstyled"
          placeholder={t('password')}
          visibilityToggleIcon={({reveal}) =>
            reveal ? (
              <EyeOff color={Color.DARK} size={26} strokeWidth={2.5} />
            ) : (
              <Eye color={Color.DARK} size={26} strokeWidth={2.5} />
            )
          }
          style={{
            border: '0.125rem solid rgba(38,20,92,.12)',
            width: '404px',
            height: '52px',
            marginBottom: '1em',
          }}
          sx={{
            input: {
              fontWeight: 500,
              fontSize: '1rem',
              padding: '0 .9375rem',
              fontFamily: 'Montserrat',
            },
          }}
        />
        <StyledForgot>
          <StyledChecked>
            <Checkbox color="teal" radius="xs" size="xs" />
            <StyledText>{t('rememberMe')}</StyledText>
          </StyledChecked>
          <Anchor
            component={Link}
            to={`/forgot-password`}
            style={{
              color: '#349D8E',
              fontWeight: 700,
              fontSize: 14,
              fontFamily: 'Montserrat',
              marginLeft: '0.5rem',
            }}
          >
            {t('forgotPassword')}
          </Anchor>
        </StyledForgot>
        <Space h="xs" />
        <Button
          onClick={() => {
            form.setFieldError('account', 'Invalid');
            form.setFieldError('password', 'Invalid');
          }}
          type="submit"
          radius="xs"
          styles={() => ({
            root: {
              width: '404px',
              height: '52px',
              color: Color.WHITE,
              backgroundColor: Color.DARK,
              fontWeight: 700,
              fontFamily: 'Montserrat',
              fontSize: '1.25rem',
              '&:hover': {
                backgroundColor: Color.DARK,
              },
            },
          })}
        >
          {t('sendButton')}
        </Button>
      </Group>
    </form>
  );
};
