import React, {useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {Anchor, Breadcrumbs, Container, Text} from '@mantine/core';

import {BasePage, LanguageChangeModal} from '../../components/common';
import {MyProfile} from '../../components/my-profile';

import {Color} from '../../enums/common';

import {useGetProfileInfo} from '../../api/tanstack-hooks/users';

const BreadcrumbPanel = styled.div`
  margin: 16px 0 40px -30px;
`;

export const MyProfilePage: React.FC = () => {
  const {t} = useTranslation('MyProfile');
  const {data} = useGetProfileInfo();

  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);

  const items = [
    {title: t('anchor.home'), href: '/'},
    {
      title: t('anchor.myprofile'),
      href: `/my-profile`,
    },
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));
  return (
    <BasePage>
      <Container>
        <BreadcrumbPanel>
          <Breadcrumbs
            separator="→"
            styles={{
              breadcrumb: {
                color: Color.DARK,
                fontSize: '12px',
                fontFamily: 'Montserrat',
              },
              separator: {color: Color.DARK, fontSize: '12px'},
            }}
          >
            {items}
          </Breadcrumbs>
        </BreadcrumbPanel>
        <Text
          weight={700}
          style={{
            fontFamily: 'Montserrat',
            fontSize: '24px',
            color: Color.DARK,
            marginLeft: '5.6em',
          }}
        >
          {t('label')}
        </Text>
        {data && (
          <MyProfile
            userprofile={data.data}
            setLanguageModalOpen={setIsLanguageModalOpen}
          />
        )}
        <LanguageChangeModal
          opened={isLanguageModalOpen}
          onClose={() => setIsLanguageModalOpen(false)}
        />
      </Container>
    </BasePage>
  );
};
