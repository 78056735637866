import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

// Types
import {Color} from 'enums/common';

// Interface
enum FeedbackCardBgColor {
  LIGHT_PURPLE = Color.PURPLE_200,
  LIGHT_GREEN = Color.GREEN_200,
}

interface FeedbackCardProps {
  title: string;
  buttonText: string;
  backgroundColor: keyof typeof FeedbackCardBgColor;
  imageSrc?: string;
  to?: string;
}

// Styled components props
interface WrapperProps {
  bgColor?: string;
}

// Create the styled component
const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  background-color: ${({bgColor}) => bgColor};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
`;
const Title = styled.div`
  width: 100%;
  padding-bottom: 24px;
  color: ${Color.DARK};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
`;

const LinkButton = styled(Link)`
  width: fit-content;
  color: ${Color.DARK};
  border: solid ${Color.DARK} 2px;
  border-radius: 4px;
  padding: 5px 20px;
  text-decoration: none;
`;

const SectionGroup = styled.div`
  flex: 1;
  padding: 48px 0;
  padding-left: 33px;
  display: flex;
  flex-direction: column;
  max-width: fit-content;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  & img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }
`;

const FeedbackCard: FC<FeedbackCardProps> = ({
  title,
  buttonText,
  backgroundColor,
  imageSrc,
  to,
}) => {
  return (
    <Wrapper
      bgColor={FeedbackCardBgColor[backgroundColor] as unknown as string}
    >
      <SectionGroup>
        <Title>{title}</Title>

        <LinkButton to={to ?? ''}>{buttonText}</LinkButton>
      </SectionGroup>

      {imageSrc && (
        <ImageWrapper>
          {' '}
          <img src={imageSrc} />
        </ImageWrapper>
      )}
    </Wrapper>
  );
};

export default FeedbackCard;
