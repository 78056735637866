import React from 'react';
import styled from 'styled-components';

import {Group} from '@mantine/core';

import {
  ReceivedMessage,
  SentMessage,
  VoiceIndicator,
} from 'components/practices/voicebots';
import {Text} from 'components/common';
import {Alert, Flag, Success} from 'components/icons';

import {Color} from 'enums/common';
import {VB2Message} from 'types/conversations/VB2Message';

const VoicebotV2MessageGroupContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const FeedbackContainer = styled.div`
  align-self: flex-end;
  max-width: 450px;
  padding: 16px;
  margin: 5px 15px 30px 0;
  border-radius: 10px 10px 0 10px;
`;

const NeutralFeedback = styled(FeedbackContainer)`
  background-color: ${Color.INFO_500};
`;

const NegativeFeedback = styled(FeedbackContainer)`
  background-color: ${Color.ERROR_500};
`;

const PositiveFeedback = styled(FeedbackContainer)`
  background-color: ${Color.SUCCESS_500};
`;

export interface VoicebotV2MessageGroupProps {
  messages: VB2Message[];
  isRecording: boolean;
}

export const VoicebotV2MessageGroup: React.FC<VoicebotV2MessageGroupProps> = ({
  messages,
  isRecording,
}) => {
  return (
    <VoicebotV2MessageGroupContainer>
      {messages.map((message, index) => {
        if (message.type === 'bot') {
          return (
            <ReceivedMessage
              key={index}
              message={message.text}
              date={message.date}
            />
          );
        } else {
          return (
            <React.Fragment key={index}>
              <SentMessage message={message.text} date={message.date} />
              {message.feedback && (
                <>
                  {message.feedback.value === 'neutral' && (
                    <NeutralFeedback>
                      <Group spacing={10} mb={8}>
                        <Flag />
                        <Text
                          fontSize={14}
                          fontWeight={500}
                          color={Color.INFO_100}
                        >
                          {message.feedback.title}
                        </Text>
                      </Group>
                      <Text
                        fontSize={12}
                        fontWeight={500}
                        color={Color.INFO_100}
                      >
                        {message.feedback.content}
                      </Text>
                    </NeutralFeedback>
                  )}
                  {message.feedback.value === 'negative' && (
                    <NegativeFeedback>
                      <Group spacing={10} mb={8}>
                        <Alert />
                        <Text
                          fontSize={14}
                          fontWeight={500}
                          color={Color.ERROR_100}
                        >
                          {message.feedback.title}
                        </Text>
                      </Group>
                      <Text
                        fontSize={12}
                        fontWeight={500}
                        color={Color.ERROR_100}
                      >
                        {message.feedback.content}
                      </Text>
                    </NegativeFeedback>
                  )}
                  {message.feedback.value === 'positive' && (
                    <PositiveFeedback>
                      <Group spacing={10} mb={8}>
                        <Success />
                        <Text
                          fontSize={14}
                          fontWeight={500}
                          color={Color.SUCCESS_200}
                        >
                          {message.feedback.title}
                        </Text>
                      </Group>
                      <Text
                        fontSize={12}
                        fontWeight={500}
                        color={Color.SUCCESS_200}
                      >
                        {message.feedback.content}
                      </Text>
                    </PositiveFeedback>
                  )}
                </>
              )}
            </React.Fragment>
          );
        }
      })}
      {isRecording && <VoiceIndicator />}
    </VoicebotV2MessageGroupContainer>
  );
};
