import React, {useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {ArrowNarrowRight} from 'tabler-icons-react';
import produce from 'immer';

// services
import {useGetProfileInfo} from 'api/tanstack-hooks/users';

// Types
import {Color} from 'enums/common';
import {Practice} from 'types/practices';

// Utils
import {MsSpeechToText} from 'utils/common/MsSpeechToText';
import {
  DynamicVarialbes,
  replaceDynamicVariablesInObject,
} from 'utils/course.util';
import {configuration} from 'config';

const {
  voiceBot: {acceptableAccuracy, interpretedTextRetries},
} = configuration();

// Components
import {Button, Group, Image, ThemeIcon} from '@mantine/core';
import {DarkButtonStyle, LightButtonStyle} from 'styles/buttons';
import {Close, Microphone} from 'components/icons';
import {Popup, Text} from 'components/common';
import {BottomTextContainer} from 'components/practices/voicebots';
import {
  VoicebotV1MessageGroup,
  VoiceBotV1SearchParams,
} from 'components/practices/voicebots/v1';
import {PracticePage} from 'types/practices/PracticePage';
import {useSearchParams} from 'react-router-dom';
import {InterpretedTextType} from 'types/conversations/conversations';
import {RichTextContent} from 'components/RichText';

// Styled components
const VoicebotV1ConversationContainer = styled.div`
  box-shadow: 0 2px 4px 0 #4d3c8233;
`;

const ConversationContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #e6e6ea;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 550px;
  padding: 0 32px 0 32px;
  overflow-y: scroll;
`;

const VoicebotHeader = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  padding: 32px 0 12px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #f1f0f0;
  background-color: ${Color.WHITE};
  z-index: 1;
`;

export const Protip = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Color.PURPLE_400};
  height: 32px;
  padding: 0 35px;
`;

const VoicebotFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border: 1px solid #e6e6ea;
  background-color: ${Color.GRAY_500};
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  padding-right: 10px;
  justify-content: flex-end;
  gap: 20px;
`;

const StyledClose = styled(Close)`
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const PopupContentContainer = styled.div`
  padding: 0 20px;
`;

const ReadMoreButton = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  user-select: none;
  cursor: pointer;
  font-weight: 500;
  color: ${Color.DARK};

  :hover {
    color: ${Color.DARK_LIGHTER};
  }
`;

export interface VoicebotV1ConversationProps {
  practice: Practice;
  callback: ({isQuiz}: {isQuiz?: boolean | undefined}) => void;
  onIndexChange?: (props: VoiceBotV1SearchParams) => void;
  currentPageIndex?: number;
  slides: {
    content: string;
  }[];
}

interface PagesType {
  page: PracticePage;
  isAppend: boolean;
  showScriptText: boolean;
  isRecording: boolean;
  interpretedText: InterpretedTextType[] | undefined;
}

export const defaultPageValues = {
  isAppend: false,
  isRecording: false,
  interpretedText: undefined,
  showScriptText: false,
};

export const VoicebotV1Conversation: React.FC<VoicebotV1ConversationProps> = ({
  practice: practiceData,
  callback,
  onIndexChange,
  currentPageIndex = 0,
  slides,
}) => {
  const {t} = useTranslation('Practices');
  const bottomRef = useRef<HTMLDivElement>(null);

  const [searchParams] = useSearchParams();

  // State
  const [practice, setPractice] = useState(practiceData);
  const [currentIndex, setCurrentIndex] = useState(currentPageIndex);
  const [isProtipHidden, setIsProtipHidden] = useState(false);
  const [isTechniqueInfoPopupOpen, setIsTechniqueInfoPopupOpen] =
    useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [interpretedText, setInterpretedText] = useState<
    InterpretedTextType | undefined
  >(undefined);
  const [complete, setComplete] = useState<boolean>(false);
  const [nextPage, setNextPage] = useState(currentPageIndex);
  const [showProTip, setShowProTip] = useState(false);
  const [initPages, setInitPages] = useState<PagesType[]>();
  const [modifiedPages, setModifiedPages] = useState<PagesType[]>();

  const page = practice.pages[currentIndex];
  const keywordsString = page?.keywords.length
    ? `${t('keywords')} ${page.keywords
        .map(keyword => keyword.trim())
        .join(', ')}`
    : '';
  const hasTechniqueInfo = practice.pages.some(page => page.techniques);

  const showVoicebot =
    searchParams.get('showVoicebot') === 'true' ? true : false;

  const isAccessable = showVoicebot || !slides.length;
  const secondAttemptCondition =
    modifiedPages &&
    modifiedPages[currentIndex]?.interpretedText?.[0] &&
    !interpretedText;

  const isLastPage = currentIndex + 1 === practice.pages.length;

  // Memo
  const msSpeechToText = useMemo(() => {
    return new MsSpeechToText(practice.language.id);
  }, [practice.language.id]);

  // Queries
  const {data: userProfileInfo} = useGetProfileInfo();

  const handleSetInitialPages = () => {
    const pages: PagesType[] = practice.pages.map(page => ({
      page,
      ...defaultPageValues,
    }));
    setInitPages(pages);
  };
  const handleCleanState = () => {
    setInterpretedText(undefined);
    setIsRecording(false);
    handleSetInitialPages();
    setModifiedPages([]);
  };

  const recordVoice = async () => {
    setTimeout(() => {
      bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }, 200);
    if (!isRecording) {
      setIsRecording(true);

      const spokenText = (await msSpeechToText.startRecognition()) || '';
      let accuracy = 0;

      if (spokenText) {
        accuracy = msSpeechToText.compareText(
          practice.pages[currentIndex].scriptText,
          spokenText
        );
      }

      setInterpretedText({spokenText, accuracy});
      setIsRecording(false);

      setTimeout(() => {
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
      }, 500);
    }
  };

  // Handlers
  const handleAccurancy = (_pages: PagesType[]): PagesType[] => {
    const page = _pages[currentIndex];
    if (!page.interpretedText) page.interpretedText = [];
    if (
      interpretedText &&
      page.interpretedText?.length < interpretedTextRetries
    ) {
      page.interpretedText.push(interpretedText);
      setInterpretedText(undefined);
      _pages[currentIndex] = page;

      if (isLastPage && interpretedText.accuracy >= acceptableAccuracy) {
        setShowProTip(true);
      }

      if (
        (isLastPage && interpretedText.accuracy >= acceptableAccuracy) ||
        (isLastPage && page.interpretedText.length === 2)
      ) {
        setComplete(true);
        return [..._pages];
      }
      if (page.interpretedText.length === 2) setNextPage(currentIndex + 1);
    }

    return [..._pages];
  };

  const restartPage = () => {
    setInterpretedText(undefined);
  };

  useEffect(() => {
    if (nextPage !== currentIndex) {
      setCurrentIndex(nextPage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextPage]);

  // Effects
  useEffect(() => {
    onIndexChange?.({pageIndex: currentIndex, showVoicebot: true});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  useEffect(() => {
    if (currentIndex !== currentPageIndex) {
      handleCleanState();
      setCurrentIndex?.(currentPageIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageIndex]);

  useEffect(() => {
    if (interpretedText && interpretedText?.accuracy >= acceptableAccuracy) {
      setIsProtipHidden(true);
    } else {
      setIsProtipHidden(false);
    }
  }, [interpretedText]);

  useEffect(() => {
    if (userProfileInfo) {
      let _page = {...practiceData.pages[currentIndex]};
      const firmName = userProfileInfo?.data.organization.name;
      const userName = userProfileInfo?.data.fullName;

      _page = replaceDynamicVariablesInObject(
        _page,
        [
          'customerText',
          'negativeFeedback',
          'objection',
          'positiveFeedback',
          'proTip',
          'scriptText',
          'slideText',
          'title',
        ],
        [
          {type: DynamicVarialbes.FIRM_NAME, value: firmName},
          {type: DynamicVarialbes.USER_NAME, value: userName},
        ]
      );

      setPractice(
        produce(practiceData, (draftPractice: Practice) => {
          draftPractice.pages[currentIndex] = _page;
        })
      );
    }
  }, [currentIndex, userProfileInfo, practiceData]);

  currentIndex + 1 < practice.pages.length;

  useEffect(() => {
    if (isAccessable) {
      handleSetInitialPages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showVoicebot]);

  useEffect(() => {
    if (isAccessable && initPages) {
      let _pages = [...initPages];

      _pages[currentIndex].isRecording = isRecording;
      if (secondAttemptCondition) return setModifiedPages([..._pages]);

      _pages[currentIndex].isAppend = true;
      _pages = handleAccurancy(_pages);

      const pageInterpretedText = _pages[currentIndex].interpretedText;

      if (
        pageInterpretedText &&
        pageInterpretedText?.length === 1 &&
        pageInterpretedText[0]?.accuracy < acceptableAccuracy
      ) {
        _pages[currentIndex].showScriptText = true;
      }
      setModifiedPages([..._pages]);
    }

    setTimeout(() => {
      if (
        !isLastPage &&
        interpretedText &&
        interpretedText.accuracy >= acceptableAccuracy
      ) {
        setNextPage(currentIndex + 1);
      }
    }, 400);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, initPages, showVoicebot, isRecording, interpretedText]);

  return (
    <VoicebotV1ConversationContainer>
      <ConversationContainer>
        <VoicebotHeader>
          <Text fontSize={18} fontWeight={700} color={Color.DARK}>
            {page.title}
          </Text>
          {hasTechniqueInfo && (
            <ReadMoreButton onClick={() => setIsTechniqueInfoPopupOpen(true)}>
              {t('voicebot2.readMore')}
            </ReadMoreButton>
          )}
        </VoicebotHeader>
        {modifiedPages?.map(
          (
            {page, isAppend, isRecording, interpretedText = [], showScriptText},
            index
          ) =>
            isAppend && (
              <VoicebotV1MessageGroup
                interpretedTextRetires={interpretedTextRetries}
                key={index}
                page={page}
                isRecording={isRecording}
                showScriptText={showScriptText}
                hideScriptText={practice.enabledScriptHiding}
                onIndexChange={onIndexChange}
                interpretedText={interpretedText}
                acceptableAccuracy={acceptableAccuracy}
              />
            )
        )}

        {(page?.proTip.length || page?.keywords.length > 0) && showProTip && (
          <BottomTextContainer keywords={keywordsString} text={page.proTip} />
        )}
        <div style={{paddingBottom: '20px'}} ref={bottomRef} />
      </ConversationContainer>
      {!isProtipHidden && (
        <Protip>
          <Group spacing={11}>
            <Microphone />
            <Text fontSize={12} fontWeight={500} color={Color.DARK}>
              {t('pressButton')}
            </Text>
          </Group>
          <StyledClose onClick={() => setIsProtipHidden(true)} />
        </Protip>
      )}
      <VoicebotFooter>
        {!complete ? (
          <ThemeIcon
            style={{cursor: isRecording ? 'not-allowed' : 'pointer'}}
            onClick={recordVoice}
            size={56}
            radius="xl"
            color={isRecording ? Color.GRAY_250 : Color.GREEN_050}
          >
            <Image width={18} height={25} src="/images/voice.svg" />
          </ThemeIcon>
        ) : (
          <ButtonContainer>
            {currentIndex + 1 < practice.pages.length ? (
              <>
                <Button onClick={restartPage} style={LightButtonStyle}>
                  {t('restart')}
                </Button>
                <Button
                  rightIcon={
                    <ArrowNarrowRight
                      size={26}
                      strokeWidth={1.4}
                      color={'white'}
                    />
                  }
                  onClick={() => {
                    setCurrentIndex(currentIndex + 1);
                    setInterpretedText(undefined);
                  }}
                  style={DarkButtonStyle}
                >
                  {t('next')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => callback({isQuiz: false})}
                  style={DarkButtonStyle}
                >
                  {t('complete')}
                </Button>
              </>
            )}
          </ButtonContainer>
        )}
      </VoicebotFooter>
      <Popup
        isVisible={isTechniqueInfoPopupOpen}
        setIsVisible={setIsTechniqueInfoPopupOpen}
      >
        <PopupContentContainer>
          {practice.pages.map((page, id) => (
            <div key={id}>
              {page.techniques && (
                <Text fontSize={16} color={Color.DARK} mt={10}>
                  <RichTextContent htmlContent={page.techniques} />
                </Text>
              )}
            </div>
          ))}
        </PopupContentContainer>
      </Popup>
    </VoicebotV1ConversationContainer>
  );
};
