import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {Image, Space, Text} from '@mantine/core';

import {LoginForm} from '../../components/auth/LoginForm';

import {Color} from '../../enums/common';

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
`;

const StyledLogo = styled.div`
  position: absolute;
  top: 1.25rem;
  left: 2.5rem;
  width: 7.5rem;
  cursor: pointer;
`;

const StyledLoginPage = styled.div`
  width: 50%;
  min-height: 100vh;
  background-color: white;
`;

const StyledImage = styled.div`
  width: 50%;
  min-height: 100vh;
  background: url('../../images/login.svg');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
`;

const StyledLogin = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 9rem;
  width: 25.25rem;
  margin-left: auto;
  margin-right: 6.875rem;
`;

const StyledTitle = styled(Text)`
  color: ${Color.DARK};
  font-weight: 700;
  font-size: 1.75rem;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0.5rem;
`;

const ImageText = styled(Text)`
  color: ${Color.WHITE};
  width: 24.125rem;
  margin-top: 11rem;
  margin-left: 6.5rem;
  font-weight: 700;
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0.5rem;
`;

export const LoginPage: React.FC = () => {
  const {t} = useTranslation('Login');

  return (
    <StyledWrapper>
      <StyledLogo>
        <Image src="../../images/logo.png" alt="future-ready-logo" />
      </StyledLogo>
      <StyledLoginPage>
        <StyledLogin>
          <StyledTitle>{t('title')}</StyledTitle>
          <Space h="xl" />
          <Space h="xl" />
          <LoginForm />
        </StyledLogin>
      </StyledLoginPage>
      <StyledImage>
        <ImageText>{t('imageTitle')}</ImageText>
      </StyledImage>
    </StyledWrapper>
  );
};
