import React, {useState} from 'react';
import {
  Space,
  Avatar,
  Select,
  Pagination,
  Box,
  Text,
  Progress,
} from '@mantine/core';
import styled from 'styled-components';

// Components
import {BasePage} from 'components/common';
import {MainContainer} from 'components/common/Containers';
import FeedbackCard from 'components/common/FeedbackCard/FeedbackCard';
import {UserMetrics} from 'pages/UserProfile/_UserMetrics';
import {
  TrainingTimeChart,
  TrainingTimeChartProps,
} from 'components/dashboard/common/TrainingTimeChart';
import {AllSummaryCourses} from 'components/courses/user/SummaryCourse/AllSummaryCourses';
import {Column, DynamicTable} from 'components/table/DynamicTable';

// Types
import {Color} from 'enums/common';
import {TeamEmployee} from 'types/users/users';

// Utils
import {useCurrentWeeksOfYear} from 'utils/common/datesUtil';
import {capitalizeFirstLetter} from 'utils/common';

// Hooks
import {useUserDashboard} from 'api/tanstack-hooks/users/useUserDashboard';
import {useUserTeamOverview} from 'api/tanstack-hooks/department/useUserTeamOverview';
import {useTranslation} from 'react-i18next';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 16px;
`;

const StyledWelcome = styled.div`
  background-color: ${Color.DARK};
  height: 230px;
`;

const Title = styled.div`
  color: #ff7575;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.15px;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding-bottom: 16px;
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  gap: 0;
  padding: 24px;
  border-radius: 4px;
`;

const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 1;

  input {
    color: ${Color.DARK};
  }
`;

const ChartTitle = styled.div`
  color: ${Color.DARK};
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`;

const Subtitle = styled.div`
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.15px;
  padding-bottom: 24px;
`;

const ChartFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  color: ${Color.DARK};

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

interface BulletPointProps {
  width?: string;
  height?: string;
  backgroundColor?: string;
}

const BulletPoint = styled.span<BulletPointProps>`
  display: inline-block;
  width: ${({width = '10px'}) => width};
  height: ${({height = '10px'}) => height};
  background-color: ${({backgroundColor = 'black'}) => backgroundColor};
  border-radius: 50%;
  margin-right: 4px;
`;

const ProgresBarLabel = styled(Text)`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

const TableTitle = styled.div`
  color: ${Color.DARK};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding: 16px 0;
`;

const PaginationWrapper = styled.div`
  padding: 12px;
  background-color: white;
  margin-top: 1px;
  border-radius: 0 0 4px 4px;
`;

export const UserDashboard = () => {
  // Hooks
  const {t} = useTranslation('Dashboard');
  const {getCurrentWeeksOfYear} = useCurrentWeeksOfYear(t);
  const weeks = getCurrentWeeksOfYear();
  // Initialize state
  const initialWeek = weeks[weeks.length - 1].value;

  // State
  const [sortKey, setSortKey] = useState<string>('');
  const [{from, to}, setSelectedWeek] = useState(initialWeek);
  const [page, setPage] = useState<number>(1);
  const [size] = useState<number>(5);

  const {data: userTeamOverviewData, isLoading} = useUserTeamOverview({
    page,
    size,
    from,
    to,
    sortKey,
  });

  const {data: userProfile} = useUserDashboard({
    from,
    to,
  });

  const {
    assignedCourses,
    totalAssignedCourses,
    completedCourses,
    avgLearningTimePerWeek,
    recommendedLearningTimePerWeek,
    points,
    name,
  } = userProfile?.data || {};

  // Table columns renderers
  const columns: Column<TeamEmployee>[] = [
    {
      slug: 'name',
      title: {name: `${t('table.teamMember')}`, sortable: true},
      key: 'name',
      render: ({name, avatar}: TeamEmployee) => (
        <Box style={{display: 'flex', alignItems: 'center'}}>
          <Avatar src={avatar} size="md" radius="lg" alt="User profile image" />
          {name}
        </Box>
      ),
    },
    {
      slug: 'awards',
      title: {name: `${t('awards')}`, sortable: true},
      key: 'awards',
      render: ({awards}: TeamEmployee) => (
        <Box style={{display: 'flex', alignItems: 'center'}}>🏆{awards}</Box>
      ),
    },
    {
      slug: 'totalLearningTime',
      title: {name: `${t('table.totalLearningTime')}`, sortable: true},
      key: 'totalLearningTime',
      render: ({totalLearningTime, recommendedLearningTime}: TeamEmployee) => (
        <Box style={{display: 'flex', flexDirection: 'column', maxWidth: 280}}>
          <Box style={{display: 'flex', justifyContent: 'space-between'}}>
            <ProgresBarLabel>{totalLearningTime}min</ProgresBarLabel>
            <ProgresBarLabel>{recommendedLearningTime}min</ProgresBarLabel>
          </Box>
          <Box>
            <Progress
              value={(totalLearningTime / recommendedLearningTime) * 100}
              color={Color.GREEN_050}
            />
          </Box>
        </Box>
      ),
    },
  ];

  // Time details
  const timeDetails: TrainingTimeChartProps[] = Object.entries(
    userProfile?.data?.learningTimePerDay || {}
  )?.map(([day, {actualLearningTime, recommendedLearningTime}]) => ({
    day: capitalizeFirstLetter(day.substring(0, 3)),
    userMinutes: actualLearningTime,
    recommendedMinutes: recommendedLearningTime,
  }));

  return (
    <BasePage>
      <StyledWelcome />
      <MainContainer style={{marginTop: '-120px'}}>
        <Header>
          <div>
            <Title>{`${t('welcomeBanner.bannerTitle')}, ${name}!`}</Title>
            <Subtitle>{`${t('endUserSubtitle')}`}</Subtitle>
          </div>
          <Select
            placeholder="Choose a week"
            data={weeks.map(week => ({
              value: JSON.stringify(week.value),
              label: week.label,
            }))}
            value={JSON.stringify({from, to})}
            onChange={value => setSelectedWeek(JSON.parse(String(value)))}
            dropdownPosition="bottom"
            withinPortal
            styles={{
              wrapper: {
                alignSelf: 'flex-end',
              },
              item: {
                '&[data-selected], &[data-hovered]': {
                  backgroundColor: Color.GRAY_50,
                  color: Color.DARK,
                },
                '&[data-selected]': {
                  '&:hover': {
                    backgroundColor: Color.GRAY_50,
                  },
                },
              },
            }}
          />
        </Header>
        <SectionWrapper>
          <UserMetrics
            assignedCoursesLength={totalAssignedCourses || 0}
            avgLearningTimePerWeek={avgLearningTimePerWeek}
            completedCourses={completedCourses}
            points={points}
            recommendedLearningTimePerWeek={recommendedLearningTimePerWeek}
          />

          <ChartWrapper>
            <ChartHeader>
              <ChartTitle>{`${t('UserProfile:chart.title')}`}</ChartTitle>
            </ChartHeader>
            <div
              style={{
                width: '100%',
                marginRight: '-80px',
                marginTop: '-25px',
              }}
            >
              <TrainingTimeChart
                maxMinutes={recommendedLearningTimePerWeek ?? 60}
                timeDetails={timeDetails}
              />
            </div>
            <ChartFooter>
              <div>
                <BulletPoint backgroundColor={Color.PURPLE_TXT} />{' '}
                <span> {`${t('UserProfile:chart.recommended')}`}</span>
              </div>
              <div>
                <BulletPoint backgroundColor={Color.BLUE_300} />{' '}
                <span>{`${t('UserProfile:chart.actual')}`}</span>
              </div>
            </ChartFooter>
          </ChartWrapper>
        </SectionWrapper>

        <FeedbackCard
          imageSrc={'/images/noFeedback.png'}
          backgroundColor="LIGHT_GREEN"
          buttonText={`${t('goToTheFeedbacks')}`}
          title={`${t('endUserFeedbackCardTitle')}`}
          to={`/dashboard/feedback/userId/${userProfile?.data.id || ''}`}
        />

        <TableTitle>{t('table.title')}</TableTitle>

        <DynamicTable
          isLoading={isLoading}
          data={userTeamOverviewData?.data?.elements || []}
          columns={columns}
          rowsPerPage={size}
          onSort={({key, order}) =>
            setSortKey(`${String(key)},${order.toUpperCase()}`)
          }
        />
        <PaginationWrapper>
          <Pagination
            value={page}
            onChange={number => setPage(number)}
            total={Math.ceil((userTeamOverviewData?.data?.total || 0) / size)}
            size="sm"
            siblings={1}
            withControls={false}
            withEdges
            position="right"
            styles={{
              control: {
                '&[data-active]': {
                  backgroundColor: '#26145C',
                  color: 'white',
                },
              },
            }}
          />
        </PaginationWrapper>

        <AllSummaryCourses courses={assignedCourses || []} hasButton />

        <Space h={50} />
      </MainContainer>
    </BasePage>
  );
};
