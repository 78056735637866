import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {Group} from '@mantine/core';

import {Alert, Success} from 'components/icons';
import {Text} from 'components/common';
import {
  ReceivedMessage,
  SentMessage,
  VoiceIndicator,
} from 'components/practices/voicebots';

import {PracticePage} from 'types/practices/PracticePage';

import {Color} from 'enums/common';
import {VoiceBotV1SearchParams} from '../VoicebotV1/VoicebotV1';
import {InterpretedTextType} from 'types/conversations/conversations';

const VoicebotV1MessageGroupContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const FeedbackMessage = styled.div`
  max-width: 450px;
  align-self: flex-end;
  padding: 16px 16px 20px 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;

  @keyframes message-sent {
    0% {
      opacity: 0;
      transform: translateX(20px);
    }

    100% {
      opacity: 1;
    }
  }
`;

const PositiveFeedbackMessage = styled(FeedbackMessage)`
  margin: 16px 10px 0 0;
  background-color: ${Color.SUCCESS_500};

  animation: message-sent 1s ease-in-out;
`;

const NegativeFeedbackMessage = styled(FeedbackMessage)`
  margin: 16px 10px 0 0;
  background-color: ${Color.ERROR_500};

  animation: message-sent 0.5s ease-in-out;
`;

export interface VoicebotV1MessageGroupProps {
  page: PracticePage;
  isRecording: boolean;
  hideScriptText: boolean;
  onIndexChange?: (props: VoiceBotV1SearchParams) => void;
  interpretedTextRetires: number;
  interpretedText?: InterpretedTextType[];
  acceptableAccuracy: number;
  showScriptText: boolean;
}

export const VoicebotV1MessageGroup: React.FC<VoicebotV1MessageGroupProps> = ({
  page,
  isRecording,
  hideScriptText,
  interpretedTextRetires,
  interpretedText = [],
  acceptableAccuracy,
  showScriptText,
}) => {
  const {t} = useTranslation('Practices');

  const showFeedback = interpretedText.length >= interpretedTextRetires;

  const [isScriptTextHidden, setIsScriptTextHidden] = useState(false);

  const isResponseAccurate = !!interpretedText.find(
    item => item.accuracy >= acceptableAccuracy
  );

  useEffect(() => {
    if (isRecording && hideScriptText) {
      setIsScriptTextHidden(true);
    } else {
      setIsScriptTextHidden(false);
    }
  }, [hideScriptText, isRecording]);

  useEffect(() => {
    if (!showFeedback) {
      setIsScriptTextHidden(false);
    }

    if (showFeedback && isResponseAccurate) {
      setIsScriptTextHidden(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFeedback]);

  const renderResponse = (
    {accuracy, spokenText}: InterpretedTextType,
    index: number
  ) => {
    if (accuracy > acceptableAccuracy) {
      return (
        <React.Fragment key={`${accuracy}_${index}`}>
          {page.customerText && (
            <ReceivedMessage message={page.customerText} date={new Date()} />
          )}
          {spokenText && (
            <SentMessage message={page?.scriptText} date={new Date()} />
          )}
          <PositiveFeedbackMessage>
            <Group spacing={9} mb={8}>
              <Success />
              <Text fontSize={14} fontWeight={500} color={Color.SUCCESS_200}>
                {t('positive')}
              </Text>
            </Group>
            <Text fontSize={12} fontWeight={500} color={Color.SUCCESS_200}>
              {page.positiveFeedback}
            </Text>
          </PositiveFeedbackMessage>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment key={`${accuracy}_${index}`}>
        {spokenText && <SentMessage message={spokenText} date={new Date()} />}
        <NegativeFeedbackMessage>
          <Group spacing={9} mb={8}>
            <Alert />
            <Text fontSize={14} fontWeight={500} color={Color.ERROR_100}>
              {t('negative')}
            </Text>
          </Group>
          <Text fontSize={12} fontWeight={500} color={Color.ERROR_100}>
            {page.negativeFeedback}
          </Text>
        </NegativeFeedbackMessage>
      </React.Fragment>
    );
  };

  return (
    <VoicebotV1MessageGroupContainer>
      <>
        {page.objection && (
          <ReceivedMessage message={page.objection} date={new Date()} />
        )}
        {!isScriptTextHidden && !showScriptText && (
          <SentMessage
            isScriptText
            message={page?.scriptText}
            date={new Date()}
          />
        )}
        {interpretedText.map((item, index) => renderResponse(item, index))}

        {isRecording && <VoiceIndicator />}
      </>
    </VoicebotV1MessageGroupContainer>
  );
};
