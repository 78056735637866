import React from 'react';
import styled from 'styled-components';

import {Loader} from '@mantine/core';

import {Color} from 'enums/common';

const VoiceIndicatorContainer = styled.div`
  align-self: flex-end;
  box-sizing: border-box;
  height: 40px;
  width: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.PURPLE_TXT};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
  margin: 16px 10px 0 0;

  animation: fadein 0.5s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const VoiceIndicator: React.FC = () => {
  return (
    <VoiceIndicatorContainer>
      <Loader variant="dots" color={Color.WHITE} size="md" height={10} />
    </VoiceIndicatorContainer>
  );
};
