import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {Button, Image} from '@mantine/core';

import {UserContext} from 'contexes';

import {DisabledTopicButtonStyle} from 'styles/buttons';

import {Text} from 'components/common';

import {Color} from 'enums/common';
import {Role} from 'enums/auth';

import {AssignedCourse} from 'types/courses/courses';

const CoursePreviewFooterContainer = styled.div`
  padding-top: 30px;
  padding-left: 130px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  max-width: 45em;
`;

const GridItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 24px;
  text-transform: capitalize;
`;

const TopicContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 50em;
`;

const DesscriptionContainer = styled.div`
  max-width: 45em;
`;

export interface CoursePreviewFooterProps {
  course: AssignedCourse;
}

export const CoursePreviewFooter: React.FC<CoursePreviewFooterProps> = ({
  course,
}) => {
  const {t} = useTranslation();

  const [userContext] = useContext(UserContext);

  return (
    <CoursePreviewFooterContainer>
      <DesscriptionContainer>
        <Text fontSize={18} fontWeight={600} color={Color.DARK} mb={14}>
          {t('Courses:courseDetails.description')}
        </Text>
        <Text fontSize={14} fontWeight={400} color={Color.DARK} mb={45}>
          {course.summary}
        </Text>
      </DesscriptionContainer>
      <Text fontSize={18} fontWeight={600} color={Color.DARK} mb={14}>
        {t('Courses:courseDetails.includes')}
      </Text>
      <Grid>
        <GridItem>
          <Image src="/images/chaptersleft.svg" height={30} width={30} />
          <Text fontSize={14} fontWeight={500} color={Color.DARK}>
            {course.totalChaptersNum} {t('Courses:courseDetails.chapters')}
          </Text>
        </GridItem>
        {userContext.authority.includes(Role.MEMBER) && (
          <GridItem>
            <Image src="/images/graduation.svg" height={30} width={30} />
            {/*// TODO - This is not the Level type, it's a string, interface should be fixed*/}
            <Text fontSize={14} fontWeight={500} color={Color.DARK}>
              {t(`Dashboard:courseCards.${course.level}`)}
            </Text>
          </GridItem>
        )}
        <GridItem>
          <Image src="/images/clock.svg" height={30} width={30} />
          <Text fontSize={14} fontWeight={500} color={Color.DARK}>
            {course.totalLearnTime} {t('Courses:courseDetails.minutes')}
          </Text>
        </GridItem>
        <GridItem>
          <Image src="/images/vector.svg" height={30} width={30} />
          <Text fontSize={14} fontWeight={500} color={Color.DARK}>
            {t('Courses:courseDetails.certificate')}
          </Text>
        </GridItem>
      </Grid>
      {course.skills.length > 0 && (
        <div>
          <Text
            fontSize={18}
            fontWeight={600}
            color={Color.DARK}
            mb={14}
            mt={25}
          >
            {t('Courses:courseDetails.topics')}
          </Text>
          <TopicContainer>
            {course.skills.map((skill, index) => (
              <Button
                disabled
                key={index}
                size="xs"
                style={DisabledTopicButtonStyle}
              >
                {skill}
              </Button>
            ))}
          </TopicContainer>
        </div>
      )}
    </CoursePreviewFooterContainer>
  );
};
