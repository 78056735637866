import React, {useEffect} from 'react';
import styled from 'styled-components';

import {useClickOutside} from '@mantine/hooks';

import {X} from 'tabler-icons-react';

const PopupContainer = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const PopupContentContainer = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  justify-content: space-between;
  border-radius: 10px;
  background-color: white;

  @media screen and (max-width: 1024px) {
    width: 90%;
  }

  @media screen and (max-width: 480px) {
    width: 95%;
  }
`;

const PopupHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CloseIcon = styled(X)`
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const DynamicContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  max-height: 70vh;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export interface PopupProps {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  children?: React.ReactNode;
}

export const Popup: React.FC<PopupProps> = ({
  isVisible,
  setIsVisible,
  children,
}) => {
  const ref = useClickOutside(() => setIsVisible(false));

  useEffect(() => {
    if (isVisible) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      document.querySelector('body')!.style.overflow = 'hidden';
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      document.querySelector('body')!.style.overflow = 'auto';
    }
  }, [isVisible]);

  return (
    <>
      {isVisible && (
        <PopupContainer>
          <PopupContentContainer ref={ref}>
            <PopupHeader>
              <CloseIcon color="gray" onClick={() => setIsVisible(false)} />
            </PopupHeader>
            <DynamicContentContainer>{children}</DynamicContentContainer>
          </PopupContentContainer>
        </PopupContainer>
      )}
    </>
  );
};
