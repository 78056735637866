import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {useGetUnassignedCourses} from 'api/tanstack-hooks/courses';
import {Color} from '../../../enums/common';

// Components
import {Space, Text} from '@mantine/core';
import {EntityTileGrid} from 'components/Grids';
import {EntityCard} from 'components/Entity';

const FlexContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  color: ${Color.DARK};
`;

const StyledCourseTitle = styled(Text)`
  font-size: 20px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 35px; */
  align-items: center;
`;

export const AssignWorkersToCourses: React.FC = () => {
  const {t} = useTranslation('Dashboard');

  // Hooks
  const page = 1;
  const size = 1000;
  const {data} = useGetUnassignedCourses(page, size);
  const courses = data?.data?.elements || [];

  return (
    <>
      {data && (
        <>
          <StyledHeader>
            <StyledCourseTitle>{t('allCourses')}</StyledCourseTitle>
          </StyledHeader>

          <FlexContainer>
            <EntityTileGrid>
              {courses.map(course => (
                <EntityCard
                  to={`/manager/course/${course.courseId}/details`}
                  key={course.courseId}
                  coverImage={course.coverImage}
                  title={course.courseName}
                  subtitle={course?.courseSubtitle}
                  totalChaptersLabel={
                    course.totalChaptersNum === 1
                      ? t('courseCards.chapterLeft', {
                          chapters: course.totalChaptersNum,
                        })
                      : t('courseCards.chaptersLeft', {
                          chapters: course.totalChaptersNum,
                        })
                  }
                  courseLevelLabel={t(`courseCards.${course.level.name}`)}
                  assignedLabel={t('courseCards.assigned')}
                  buttonLabel={t('table.assignWorkers')}
                  learningPercent={course.learningPercent}
                />
              ))}
            </EntityTileGrid>
            <Space h={60} />
          </FlexContainer>
        </>
      )}
    </>
  );
};
