import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {Color} from '../../../enums/common';

import {
  NativeSelect,
  Pagination as MantinePagination,
  Space,
} from '@mantine/core';

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  background-color: white;
  width: 100%;
  padding: 3px 10px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${Color.DARK};
  font-size: 12px;
  font-weight: 400;
  margin-right: 10px;
`;

export interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  page: number;
  setPage: (page: number) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  page,
  setPage,
  setItemsPerPage,
}) => {
  const {t} = useTranslation('Common');

  const start = (page - 1) * itemsPerPage + 1;
  const currentMaximum = page * itemsPerPage;
  const end = currentMaximum < totalItems ? currentMaximum : totalItems;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPage(1);

    setItemsPerPage(Number(event.currentTarget.value));
  };

  return (
    <PaginationContainer data-testid="pagination">
      <TextContainer>{t('rowsPerPage')}</TextContainer>
      <NativeSelect
        size="xs"
        value={itemsPerPage}
        onChange={event => handleItemsPerPageChange(event)}
        data={[
          {
            value: '15',
            label: `15 `,
          },
          {
            value: '30',
            label: `30 `,
          },
          {
            value: '50',
            label: `50 `,
          },
        ]}
        styles={{
          wrapper: {
            marginLeft: 10,
          },
          input: {
            fontFamily: 'Montserrat',
            fontWeight: 500,
            color: Color.DARK,
          },
        }}
      />
      <Space w={20} />
      <TextContainer>
        {t('pagination', {start: start, end: end, total: totalItems})}
      </TextContainer>
      <Space w={20} />
      <MantinePagination
        color="#f4f4f4"
        size="xs"
        radius="xl"
        value={page}
        total={totalPages || 1}
        onChange={setPage}
      />
    </PaginationContainer>
  );
};
