import {useQuery} from '@tanstack/react-query';

import {getAssignedPractices} from '../../practices';

export const useGetAssignedPractices = (page: number, limit: number) => {
  return useQuery(
    ['practices', page, limit],
    () => getAssignedPractices(page, limit),
    {
      cacheTime: 0,
    }
  );
};
