import {useQuery} from '@tanstack/react-query';

import {getAssignedCourses} from '../../courses/courses';

export const useGetAssignedCourses = (page: number, size: number) => {
  return useQuery(
    ['courses', page, size],
    () => getAssignedCourses(page, size),
    {
      cacheTime: 0,
    }
  );
};
