import {useQuery} from '@tanstack/react-query';
import {getCourseChaptersOverview} from '../../courses/courses';

export const useGetCourseChaptersOverview = (courseId: number) => {
  return useQuery(
    ['chapters/overview', courseId],
    () => getCourseChaptersOverview(courseId),
    {
      enabled: !!courseId,
    }
  );
};
