import React from 'react';

export const Alert: React.FC = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M8.48667 0H3.51333L0 3.51333V8.48667L3.51333 12H8.48667L12 8.48667V3.51333L8.48667 0ZM10.6667 7.93333L7.93333 10.6667H4.06667L1.33333 7.93333V4.06667L4.06667 1.33333H7.93333L10.6667 4.06667V7.93333ZM6 9.33333C6.36819 9.33333 6.66667 9.03486 6.66667 8.66667C6.66667 8.29848 6.36819 8 6 8C5.63181 8 5.33333 8.29848 5.33333 8.66667C5.33333 9.03486 5.63181 9.33333 6 9.33333ZM6.66667 2.66667H5.33333V7.33333H6.66667V2.66667Z"
        fill="#9B160C"
      />
    </svg>
  );
};
