import React, {useEffect, useState} from 'react';
import {ArrowNarrowLeft, ArrowNarrowRight} from 'tabler-icons-react';
import {useTranslation} from 'react-i18next';

import {Button, Space} from '@mantine/core';

import {DarkButtonStyle, LightButtonStyle} from 'styles/buttons';

import {Text} from 'components/common';
import {RichTextContent} from 'components/RichText';

import {VoicebotV1Conversation} from '../VoicebotV1Conversation/VoicebotV1Conversation';

import {Practice} from 'types/practices';

import {Color} from 'enums/common';
import {
  SlideContent,
  SlideContentHeader,
  SlideFooter,
} from 'components/slideText';

interface Slide {
  content: string;
}

export interface VoiceBotV1SearchParams {
  pageIndex: number;
  showVoicebot?: boolean;
}

export interface PracticeDetailsProps {
  practice: Practice;
  callback: ({isQuiz}: {isQuiz?: boolean | undefined}) => void;
  onIndexChange?: (props: VoiceBotV1SearchParams) => void;
  currentPageIndex?: number;
  showVoicebot?: boolean;
}

export const VoicebotV1: React.FC<PracticeDetailsProps> = ({
  practice,
  callback,
  onIndexChange,
  currentPageIndex = 0,
  showVoicebot = false,
}) => {
  const {t} = useTranslation('Practices');

  // Slide indexes
  const [currentIndex, setCurrentIndex] = useState(currentPageIndex);
  const [_showVoicebot, setShowVoicebot] = useState(showVoicebot);
  const [slides, setSlides] = useState<Slide[]>([]);

  // Handlers
  const handleGetStarted = () => {
    setShowVoicebot(true);
    setCurrentIndex(0);
  };

  // Effects
  useEffect(
    () => {
      const newSlides = [];

      for (const page of practice.pages) {
        if (page.slideText) {
          console.log(page.slideText);
          newSlides.push({
            content: page.slideText,
          });
        }
      }

      setSlides(newSlides);

      if (!newSlides.length) {
        handleGetStarted();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [practice]
  );

  useEffect(() => {
    if (currentIndex !== currentPageIndex) {
      setCurrentIndex(currentPageIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageIndex]);

  return (
    <>
      {_showVoicebot || !slides.length ? (
        <VoicebotV1Conversation
          onIndexChange={onIndexChange}
          practice={practice}
          callback={callback}
          currentPageIndex={currentPageIndex}
          slides={slides}
        />
      ) : (
        <>
          <SlideContent>
            <SlideContentHeader>
              <Text fontSize={18} fontWeight={700} color={Color.DARK}>
                {t('introduction')}
              </Text>
            </SlideContentHeader>
            <Text
              fontSize={16}
              fontWeight={500}
              color={Color.PURPLE_TXT}
              mt={20}
            >
              <RichTextContent htmlContent={slides[currentIndex]?.content} />
            </Text>
          </SlideContent>
          <SlideFooter>
            {currentIndex > 0 && (
              <Button
                size="md"
                leftIcon={
                  <ArrowNarrowLeft
                    size={26}
                    strokeWidth={1.4}
                    color={'#26145C'}
                  />
                }
                onClick={() => setCurrentIndex(currentIndex - 1)}
                style={LightButtonStyle}
              >
                {t('prev')}
              </Button>
            )}
            <Space w={10} />
            {currentIndex + 1 < slides.length ? (
              <Button
                size="md"
                rightIcon={
                  <ArrowNarrowRight
                    size={26}
                    strokeWidth={1.4}
                    color={'white'}
                  />
                }
                onClick={() => setCurrentIndex(currentIndex + 1)}
                style={DarkButtonStyle}
              >
                {t('next')}
              </Button>
            ) : (
              <Button
                size="md"
                onClick={handleGetStarted}
                style={DarkButtonStyle}
              >
                {t('getStarted')}
              </Button>
            )}
          </SlideFooter>
        </>
      )}
    </>
  );
};
