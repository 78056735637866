import React from 'react';

export const Success: React.FC = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M13.6667 2.45301L6.06004 10.0663L3.23337 7.23967L4.17337 6.29967L6.06004 8.18634L12.7267 1.51967L13.6667 2.45301ZM12.1934 5.81301C12.28 6.19301 12.3334 6.59301 12.3334 6.99967C12.3334 9.94634 9.94671 12.333 7.00004 12.333C4.05337 12.333 1.66671 9.94634 1.66671 6.99967C1.66671 4.05301 4.05337 1.66634 7.00004 1.66634C8.05337 1.66634 9.02671 1.97301 9.85337 2.49967L10.8134 1.53967C9.73337 0.779674 8.42004 0.333008 7.00004 0.333008C3.32004 0.333008 0.333374 3.31967 0.333374 6.99967C0.333374 10.6797 3.32004 13.6663 7.00004 13.6663C10.68 13.6663 13.6667 10.6797 13.6667 6.99967C13.6667 6.20634 13.52 5.44634 13.2667 4.73967L12.1934 5.81301Z"
        fill="#1C5B06"
      />
    </svg>
  );
};
