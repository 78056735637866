import React from 'react';
import styled from 'styled-components';

interface TextContainerProps {
  color?: string;
  fontSize?: number;
  fontWeight?: number;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
}

const TextContainer = styled.div`
  font-family: 'Montserrat', sans-serif;
  color: ${(props: TextContainerProps) => props.color};
  font-size: ${(props: TextContainerProps) => `${props.fontSize}px`};
  font-weight: ${(props: TextContainerProps) => props.fontWeight};
  margin-top: ${(props: TextContainerProps) => `${props.mt}px`};
  margin-right: ${(props: TextContainerProps) => `${props.mr}px`};
  margin-bottom: ${(props: TextContainerProps) => `${props.mb}px`};
  margin-left: ${(props: TextContainerProps) => `${props.ml}px`};

  p {
    margin: 0;
  }
`;

interface TextProps {
  children: React.ReactNode;
  color?: string;
  fontSize?: number;
  fontWeight?: number;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  className?: string;
}

export const Text: React.FC<TextProps> = ({
  color,
  fontSize,
  fontWeight,
  mt,
  mr,
  mb,
  ml,
  children,
  className,
}) => {
  return (
    <TextContainer
      color={color}
      fontWeight={fontWeight}
      fontSize={fontSize}
      mt={mt}
      mr={mr}
      mb={mb}
      ml={ml}
      className={className}
    >
      {children}
    </TextContainer>
  );
};
