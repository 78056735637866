import React, {ReactNode, useState} from 'react';
import {Loader, Table} from '@mantine/core';
import styled from 'styled-components';
import {ChevronsDown, ChevronsUp} from 'tabler-icons-react';

// Types
import {Color} from 'enums/common';

// Components
import {NoDataPlaceholder} from 'components/common';

const Wrapper = styled.div<{height?: string}>`
  background-color: white;
  height: ${({height = 'auto'}) => (height === 'fixed' ? '700px' : height)};
  overflow-y: auto;
`;

const StyledTable = styled(Table)`
  white-space: nowrap;
  border-collapse: collapse;
  border-bottom: 1px solid ${Color.GRAY};
  & thead {
    & tr > th {
      padding: 14px 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: ${Color.DARK};
    }
  }

  & tbody {
    & tr > td {
      padding: 2.5px 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: ${Color.DARK};
    }
  }
`;

const THWrapper = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

const LoaderWrapper = styled.div<{height: string}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({height}) => height};
`;

interface Title {
  name: string;
  sortable: boolean;
  order?: 'asc' | 'desc';
}

export interface Column<T> {
  slug: keyof T | string;
  title: Title;
  key: keyof T | string;
  render?: (value: T) => ReactNode;
  isSticky?: boolean;
}

export interface SortParams<T> {
  key: keyof T;
  order: 'asc' | 'desc';
}

interface TableProps<T> {
  data: T[];
  columns: Column<T>[];
  iconAction?: (row: T) => ReactNode;
  onSort?: ({key, order}: SortParams<T>) => void;
  isLoading?: boolean;
  rowsPerPage?: number;
  height?: 'fixed' | 'auto';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DynamicTable = <T extends Record<string, any>>({
  data,
  columns,
  iconAction,
  onSort,
  isLoading = false,
  height = 'auto',
  rowsPerPage,
}: TableProps<T>) => {
  const [sortConfig, setSortConfig] = useState<{
    key: keyof T;
    order: 'asc' | 'desc';
  } | null>(null);

  const handleSort = (sortedKey: keyof T) => {
    if (!sortedKey) return;
    setSortConfig(prevSortConfig => {
      if (prevSortConfig && prevSortConfig.key === sortedKey) {
        onSort?.({
          key: sortedKey,
          order: prevSortConfig.order === 'asc' ? 'desc' : 'asc',
        });
        return {
          key: sortedKey,
          order: prevSortConfig.order === 'asc' ? 'desc' : 'asc',
        };
      } else {
        onSort?.({key: sortedKey, order: 'asc'});
        return {key: sortedKey, order: 'asc'};
      }
    });
  };

  const sortedColumns = columns.map(column => ({
    ...column,
    title: {
      ...column.title,
      order:
        sortConfig && sortConfig.key === column.key
          ? sortConfig.order
          : undefined,
    },
  }));

  const rows = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan={15} align="center" style={{padding: 2}}>
            <LoaderWrapper
              height={
                height === 'fixed'
                  ? '650px'
                  : rowsPerPage === 5
                  ? '200px'
                  : '400px'
              }
            >
              <Loader
                color="#3C2C6C"
                style={{verticalAlign: 'middle'}}
                size="md"
              />
            </LoaderWrapper>
          </td>
        </tr>
      );
    }

    if (!data.length)
      return (
        <tr>
          <td colSpan={15} align="center">
            <LoaderWrapper height={'650px'}>
              <NoDataPlaceholder
                title="No team members data available!"
                content="It looks like there are no team members added yet. Choose another team to see more data."
                align="center"
                imageSrc="/images/noFeedbackTab.png"
              />
            </LoaderWrapper>
          </td>
        </tr>
      );

    return data.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {columns.map(({key, render}, colIndex) => (
          <td
            key={`${colIndex}_${String(key)}`}
            style={{
              ...((colIndex === 0 || colIndex === 1) && {
                position: 'sticky',
                left: 0,
                zIndex: 2,
                backgroundColor: 'white',
              }),
              ...(colIndex === 1 && {
                position: 'sticky',
                left: '50px',
                zIndex: 1,
                backgroundColor: 'white',
              }),
            }}
          >
            {render ? render(row) : row[key]}
          </td>
        ))}
        {iconAction && <td>{iconAction(row)}</td>}
      </tr>
    ));
  };

  return (
    <Wrapper height={height}>
      <div style={{width: '100%', overflowX: 'scroll', paddingBottom: 16}}>
        <StyledTable style={{backgroundColor: 'white', borderRadius: 4}}>
          <thead>
            <tr>
              {sortedColumns.map(
                ({title: {name, sortable, order}, key}, index) => (
                  <th
                    key={index}
                    style={{
                      cursor: sortable ? 'pointer' : 'default',
                      ...(index === 0 && {
                        position: 'sticky',
                        left: 0,
                        zIndex: 2,
                        backgroundColor: 'white',
                      }),
                      ...(index === 1 && {
                        position: 'sticky',
                        left: '50px',
                        zIndex: 1,
                        backgroundColor: 'white',
                      }),
                    }}
                    onClick={() => sortable && handleSort(key)}
                  >
                    <THWrapper
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {name}
                      {sortable &&
                        (order === 'asc' ? (
                          <ChevronsUp width={16} height={16} />
                        ) : order === 'desc' ? (
                          <ChevronsDown width={16} height={16} />
                        ) : (
                          <span style={{width: 16}}></span>
                        ))}
                    </THWrapper>
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>{rows()}</tbody>
        </StyledTable>
      </div>
    </Wrapper>
  );
};
