import React from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {BasePage} from 'components/common';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Anchor, Breadcrumbs} from '@mantine/core';

// Types
import {ConversationInitializePayload} from 'types/conversations/conversations';
import {VoiceBotV2SearchParams} from 'components/practices/voicebots/v2';

// Api
import {
  useCompleteExercise,
  useGetConversation,
  useInitializeConversation,
} from 'api/tanstack-hooks/practices';
import {useGetProfileInfo} from 'api/tanstack-hooks/users';

// Styles
import {BreadcrumbsStyle} from 'styles';

// Componets
import {VoicebotV2, VoicebotV2Sidebar} from 'components/practices/voicebots/v2';

const PracticeV2Container = styled.div`
  display: flex;
  height: calc(100vh - 80px);
  justify-content: space-between;
`;

const PracticeContentContainer = styled.div`
  width: 55%;
  margin: 0 auto;
`;

export const PracticeV2: React.FC = () => {
  // Hooks
  const {t} = useTranslation('Practices');
  const [searchParams, setSearchParams] = useSearchParams();
  const {rasaBotKey, userExerciseId} = useParams();
  const navigator = useNavigate();
  if (!rasaBotKey || !userExerciseId) {
    throw new Error(
      'The rasa bot key and user exercise id are required for VB2 to work'
    );
  }
  const {data: userProfileInfoData} = useGetProfileInfo();
  const {data: getConversationData} = useGetConversation(
    {
      structure_name: rasaBotKey,
      user_name: userProfileInfoData?.data.email,
    },
    !!userProfileInfoData
  );
  // Declarations
  const pageIndex = parseInt(searchParams.get('pIndex') || '0');
  const showVoicebot =
    searchParams.get('showVoicebot') === 'true' ? true : false;
  const conversationInitializePayload = {
    voicebot_name: getConversationData?.voicebot_name,
    auto_text_config: {
      firm_name: userProfileInfoData?.data.organization.name,
      user_name: userProfileInfoData?.data.fullName,
      name_keys: {
        spec: userProfileInfoData?.data.gender.name,
      },
    },
  };
  const {
    data: conversationInitializeData,
    isLoading,
    isFetching,
  } = useInitializeConversation(
    conversationInitializePayload as ConversationInitializePayload,
    getConversationData?.conversation_id as string,
    !!userProfileInfoData && !!getConversationData
  );
  const completeExerciseMutation = useCompleteExercise();

  // handlers
  const completeExercise = () => {
    completeExerciseMutation.mutate(Number(userExerciseId), {
      onSuccess: () => {
        navigator('/practices');
      },
    });
  };
  const handleOnSearchParamsChange = ({
    pageIndex: _pageIndex,
    showVoicebot: _showVoicebot,
  }: VoiceBotV2SearchParams) => {
    console.log('SEARCH_PARAMS_CHANGE', {_pageIndex, showVoicebot});
    setSearchParams(
      {
        pIndex: _pageIndex.toString(),
        ...(_showVoicebot && {showVoicebot: _showVoicebot.toString()}),
      },
      {replace: true}
    );
  };

  // Renderers
  const breadCrumbItems = [
    {title: t('anchor.home'), href: '/'},
    {
      title: t('anchor.practices'),
      href: '/practices',
    },
    {title: `${getConversationData?.section.title}`},
  ].map((item, index) => (
    <Anchor key={index} href={item.href}>
      {item.title}
    </Anchor>
  ));

  return (
    <BasePage>
      {getConversationData && conversationInitializeData && (
        <PracticeV2Container>
          <PracticeContentContainer>
            <Breadcrumbs separator=">" styles={BreadcrumbsStyle}>
              {breadCrumbItems}
            </Breadcrumbs>
            <VoicebotV2
              isLoading={isLoading || isFetching}
              onSearchParamsChange={handleOnSearchParamsChange}
              conversation={getConversationData}
              conversationInitialize={conversationInitializeData}
              rasaBotKey={rasaBotKey}
              callback={completeExercise}
              currentPageIndex={pageIndex}
              showVoicebot={showVoicebot}
            />
          </PracticeContentContainer>
          <VoicebotV2Sidebar
            showVoicebot={showVoicebot}
            currentPageIndex={pageIndex}
            conversation={getConversationData}
            conversationInitialize={conversationInitializeData}
            onSearchParamsChange={handleOnSearchParamsChange}
          />
        </PracticeV2Container>
      )}
    </BasePage>
  );
};
