import React from 'react';

// Components
import {Accordion} from '@mantine/core';
import {Text} from 'components/common';
import {Microphone} from 'components/icons';
import styled from 'styled-components';

// Types
import {Color} from 'enums/common';
import {Practice} from 'types/practices';
import {VoiceBotV1SearchParams} from '../VoicebotV1/VoicebotV1';

const VoicebotV1AccordionContainer = styled.div`
  .mantine-Accordion-panel {
    background-color: white;
  }

  .mantine-Accordion-control {
    height: 40px;
    background-color: ${Color.PURPLE_500};
    margin-bottom: 5px;
  }
`;

const AccordionPanel = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  gap: 10px;
`;

const AccordionHeader = styled.div`
  border-bottom: 1px solid rgba(38, 20, 92, 0.12);
  margin-bottom: 16px;
`;

export interface VoicebotV1AccordionProps {
  practice: Practice;
  currentPageIndex: number;
  onIndexChange?: (props: VoiceBotV1SearchParams) => void;
}

export const VoicebotV1Accordion: React.FC<VoicebotV1AccordionProps> = ({
  practice,
  currentPageIndex,
}) => {
  return (
    <VoicebotV1AccordionContainer>
      <AccordionHeader>
        <Text fontSize={16} fontWeight={700} color={Color.DARK} mb={10}>
          {practice.title}
        </Text>
      </AccordionHeader>
      <Accordion defaultValue={practice.title} chevronPosition="left">
        <Accordion.Item value={practice.title}>
          <Accordion.Panel>
            {practice.pages.map((page, index) => (
              <AccordionPanel style={{cursor: 'default'}} key={page.id}>
                <Microphone />
                <Text
                  fontSize={14}
                  fontWeight={index === currentPageIndex ? 700 : 500}
                  color={Color.PURPLE_150}
                >
                  {page.title}
                </Text>
              </AccordionPanel>
            ))}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </VoicebotV1AccordionContainer>
  );
};
