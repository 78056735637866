import {useMutation} from '@tanstack/react-query';
import i18n from '../../../i18n';
import {ChangePasswordPayload} from '../../../types/auth/Auth';
import {
  endNotification,
  generateRandomString,
  startNotification,
} from '../../../utils/common';
import {changePassword} from '../../services/auth';

export const useChangePassword = () => {
  const randomId = generateRandomString(20);

  return useMutation(
    (payload: ChangePasswordPayload) => changePassword(payload),
    {
      onMutate: () => {
        startNotification(randomId);
      },
      onSuccess: () => {
        endNotification(
          randomId,
          i18n.t('passwordChangeSuccess', {ns: 'Auth'}),
          true
        );
      },
      onError: () => {
        endNotification(
          randomId,
          i18n.t('passwordChangeFailed', {ns: 'Auth'}),
          false
        );
      },
    }
  );
};
