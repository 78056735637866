import React, {useContext} from 'react';
import {Navigate, Outlet} from 'react-router-dom';

import {UserContext} from '../contexes';

import {Role} from '../enums/auth';

import {checkForRoles} from '../utils/auth';

interface ProtectedRouteProps {
  requiredRoles?: Role[];
}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const [userContext] = useContext(UserContext);

  if (props.requiredRoles) {
    if (checkForRoles(userContext.authority, props.requiredRoles)) {
      return <Outlet />;
    }

    return <Navigate to="/denied" />;
  }

  return <Outlet />;
};
