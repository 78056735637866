import React from 'react';

export const Microphone: React.FC = () => {
  return (
    <svg width="10" height="13" viewBox="0 0 10 13" fill="none">
      <path
        d="M4.99967 8.33301C6.10634 8.33301 6.99967 7.43967 6.99967 6.33301V2.33301C6.99967 1.22634 6.10634 0.333008 4.99967 0.333008C3.89301 0.333008 2.99967 1.22634 2.99967 2.33301V6.33301C2.99967 7.43967 3.89301 8.33301 4.99967 8.33301ZM8.33301 6.33301C8.33301 8.17301 6.83967 9.66634 4.99967 9.66634C3.15967 9.66634 1.66634 8.17301 1.66634 6.33301H0.333008C0.333008 8.68634 2.07301 10.6197 4.33301 10.9463V12.9997H5.66634V10.9463C7.92634 10.6197 9.66634 8.68634 9.66634 6.33301H8.33301Z"
        fill="#26145C"
      />
    </svg>
  );
};
