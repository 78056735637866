import styled from 'styled-components';

import {Container} from '@mantine/core';

export const MainContainer = styled(Container)`
  max-width: 80%;
  @media screen and (min-width: 1728px) {
    max-width: 67%;
  }
  @media screen and (min-width: 1920px) {
    max-width: 61%;
  }
  padding-bottom: 30px;
`;
