/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {ArrowNarrowRight} from 'tabler-icons-react';

// Api
import {
  conversationInitialize,
  getConversation,
} from 'api/conversations/conversations';
import {
  useCompleteChapter,
  useCompleteChapterPage,
  useCompleteCourse,
  useCompleteExercise,
  useCompleteQuiz,
  useStartChapter,
  useStartUserExercise,
} from 'api/tanstack-hooks/courses';
import {getProfileInfo} from 'api/user/user';

// Types
import {Chapter, ChapterPage} from 'types/courses';
import {AssignedCourse} from 'types/courses/courses';
import {Resource} from 'types/courses/Resource';
import {Conversation} from 'types/practices';
import {ConversationInitialize} from 'types/conversations/conversations';
import {Color} from 'enums/common';
import {ExerciseType} from 'enums/practices';
import {Exercise} from 'types/chapters/chapters';

// Styles
import {BreadcrumbsStyle} from 'styles';
import {DarkButtonStyle} from 'styles/buttons';

// Components
import {Anchor, Breadcrumbs, Button, Image, Text} from '@mantine/core';
import {
  VoicebotV1,
  VoiceBotV1SearchParams,
} from 'components/practices/voicebots/v1';
import {VoicebotV2} from 'components/practices/voicebots/v2';
import {CourseQuiz} from 'components/courses/common';
import ChatBotV3Container from 'containers/chatbots/ChatBotV3Container';

// Styled components
const CourseContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -40px;
  text-align: center;
  width: 56em;

  @media screen and (min-width: 1728px) {
    margin-left: 40px;
  }
  @media screen and (min-width: 1920px) {
    margin-left: 80px;
  }
`;

const StyledImage = styled(Image)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledVideo = styled.video`
  width: 100%;
`;

const StyledText = styled(Text)`
  padding-top: 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: ${Color.DARK};
`;

const DescriptionText = styled(Text)`
  padding-top: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: ${Color.DARK};
`;

const PageTitle = styled(Text)`
  padding-top: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: ${Color.DARK};
`;

const ModifiedDarkButtonStyle = {
  ...DarkButtonStyle,
  alignSelf: 'flex-end',
  marginTop: '10px',
};

export interface CourseContentProps {
  course: AssignedCourse;
  chapters: Chapter[];
  refetch?: () => void;
}

export const CourseContent: React.FC<CourseContentProps> = ({
  chapters,
  course,
  refetch,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const chapterIndex = Number(searchParams.get('cIndex'));
  const chapterPageIndex = Number(searchParams.get('pIndex'));
  const isLeadershipCourse = searchParams.get('isLeadership') === 'true';
  const userCourseId = Number(searchParams.get('userCourseId'));
  const vb1Index = Number(searchParams.get('vb1Index')) || 0;
  const showVoicebot =
    searchParams.get('showVoicebot') === 'true' ? true : false;

  const {t} = useTranslation('Practices');
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);

  const [chapter, setChapter] = useState<Chapter>(chapters[chapterIndex]);
  const [startChapterId, setStartChapterId] = useState<number | null>(null);
  const [chapterPage, setChapterPage] = useState<ChapterPage>(
    chapters[chapterIndex].pages[chapterPageIndex]
  );

  const chapterPageLength =
    chapter.pages.length + chapter.exercises.length + (chapter.quiz ? 1 : 0);

  const [exercise, setExercise] = useState<Exercise>();
  const [resource, setResource] = useState<Resource>();
  const [conversation, setConversation] = useState<Conversation>();
  const [conversationInitializeInfo, setConversationInitializeInfo] =
    useState<ConversationInitialize>();
  const [userChapterId, setUserChapterId] = useState<number>();
  const [userExerciseId, setUserExerciseId] = useState<number>();
  const [conversationId, setConversationId] = useState<number>();

  const startChapterMutation = useStartChapter();
  const completeChapterMutation = useCompleteChapter();
  const completeChapterPageMutation = useCompleteChapterPage();
  const completeCourseMutation = useCompleteCourse();
  const completeQuizMutation = useCompleteQuiz();
  const startUserExerciseMutation = useStartUserExercise();
  const completeExerciseMutation = useCompleteExercise();

  const handleOnPageIndexChange = ({
    pageIndex: _pageIndex,
    showVoicebot: _showVoicebot,
  }: VoiceBotV1SearchParams) => {
    setSearchParams({
      pIndex: chapterPageIndex.toString(),
      cIndex: chapterIndex.toString(),
      userCourseId: userCourseId.toString(),
      ...(isLeadershipCourse && {isLeadership: isLeadershipCourse.toString()}),
      vb1Index: _pageIndex.toString(),
      ...(showVoicebot && {showVoicebot: showVoicebot.toString()}),
      ...(typeof _showVoicebot === 'boolean' && {
        showVoicebot: _showVoicebot.toString(),
      }),
    });
  };

  const handleStartExercise = (exercise?: Exercise) => {
    if (!exercise || !userChapterId || !chapter) return;
    startUserExerciseMutation.mutate(
      {userChapterId, exerciseId: exercise.id},
      {
        onSuccess: response => {
          exercise.description = (
            response! as {description: string}
          ).description;

          setUserExerciseId(
            (response! as {userExerciseId: number}).userExerciseId
          );
          setConversationId(
            (response! as {conversationId: number}).conversationId
          );
          setExercise(exercise);
        },
      }
    );
  };

  const handleCompleteExercise = (exercise?: Exercise) => {
    if (!exercise && !userExerciseId) return;
    completeExerciseMutation.mutate(userExerciseId!, {
      onSuccess: () => refetch?.(),
    });
  };

  const advanceVoicebotV2 = async () => {
    if (conversation) {
      const profileInfo = await getProfileInfo();

      const conversationInitializePayload = {
        voicebot_name: conversation.voicebot_name,
        auto_text_config: {
          firm_name: profileInfo.data.organization.name,
          user_name: profileInfo.data.fullName,
          name_keys: {
            spec: profileInfo.data.gender.name,
          },
        },
      };

      const initializeData = await conversationInitialize(
        conversationInitializePayload,
        conversation.conversation_id
      );

      setConversationInitializeInfo(initializeData);
    }
  };

  const handleAdvanceCourse = ({isQuiz = false}: {isQuiz?: boolean}) => {
    handleCompleteExercise(exercise);
    setExercise(undefined);
    setConversationId(undefined);

    if (userChapterId && chapterPage) {
      completeChapterPageMutation.mutate(
        {
          userChapterId,
          pageId: chapterPage.pageId,
        },
        {onSuccess: () => refetch?.()}
      );
    }

    let newChapterPageIndex: number;
    let newChapterIndex: number;

    if (chapterPageIndex === chapterPageLength - 1) {
      newChapterPageIndex = 0;
      newChapterIndex = chapterIndex + 1;
    } else {
      newChapterPageIndex = chapterPageIndex + 1;
      newChapterIndex = chapterIndex;
    }

    const isNextChapterOrPageAvailable = newChapterIndex <= chapters.length - 1;

    let redirectUrl: string;

    if (isNextChapterOrPageAvailable) {
      redirectUrl = `/course?pIndex=${newChapterPageIndex}&cIndex=${newChapterIndex}&chapterId=${chapters[chapterIndex]?.chapterId}&userCourseId=${course.userCourseId}&isLeadership=${isLeadershipCourse}`;
    } else {
      if (isLeadershipCourse) {
        redirectUrl = '/manager/salesCourses';
      } else {
        redirectUrl = '/courses';
      }
    }

    if (!isNextChapterOrPageAvailable) {
      completeCourseMutation.mutate(course.userCourseId);
    }

    if (!newChapterPageIndex && userChapterId) {
      completeChapterMutation.mutate(userChapterId);
    }

    if (chapters[chapterIndex].quiz && userChapterId && isQuiz) {
      completeQuizMutation.mutate({
        userChapterId,
        quizId: chapter.quiz.quizId,
      });
    }

    navigate(redirectUrl);
  };

  // Start exercise effect
  useEffect(() => {
    handleStartExercise(exercise);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exercise, userChapterId, chapter]);

  useEffect(() => {
    const exercise = chapter.exercises[chapterPageIndex - chapter.pages.length];
    setChapter(chapters[chapterIndex]);
    setChapterPage(chapter.pages[chapterPageIndex]);
    setExercise(exercise);
    setResource(chapterPage?.resources[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chapterIndex,
    chapterPageIndex,
    chapterPage?.resources,
    chapter.exercises,
    chapter.pages,
    chapter,
    chapters,
  ]);

  useEffect(() => {
    setChapter(chapters[chapterIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterIndex, chapters]);

  useEffect(() => {
    setChapterPage(chapter.pages[chapterPageIndex]);
    setExercise(chapter.exercises[chapterPageIndex - chapter.pages.length]);
    setResource(chapterPage?.resources[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterPageIndex, chapter, chapterPage]);

  useEffect(() => {
    async function fetchVB2Info() {
      const profileInfo = await getProfileInfo();

      const conversation = await getConversation({
        structure_name: exercise?.rasaBotKey || '',
        user_name: profileInfo.data.email,
      });

      setConversation(conversation);

      const conversationInitializePayload = {
        voicebot_name: conversation.voicebot_name,
        auto_text_config: {
          firm_name: profileInfo.data.organization.name,
          user_name: profileInfo.data.fullName,
          name_keys: {
            spec: profileInfo.data.gender.name,
          },
        },
      };

      const initializeData = await conversationInitialize(
        conversationInitializePayload,
        conversation.conversation_id
      );

      setConversationInitializeInfo(initializeData);
    }

    if (exercise && exercise.exerciseType === ExerciseType.CHAT_BOT_V2) {
      void fetchVB2Info();
    }
  }, [exercise]);

  useEffect(() => {
    if (
      (resource?.resourceType.id === 2 || resource?.resourceType.id === 1) &&
      videoRef.current
    ) {
      videoRef.current.load();
    }
  }, [resource]);

  useEffect(() => {
    const handleUserChapterId = () => {
      if (chapter.chapterId !== startChapterId) {
        startChapterMutation.mutate(
          {
            userCourseId: course.userCourseId,
            chapterId: chapter.chapterId,
          },
          {
            onSuccess: data => {
              setUserChapterId(data.data.userChapterId);
            },
          }
        );
        setStartChapterId(chapter.chapterId);
      }
    };

    handleUserChapterId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapter]);

  const breadcrumbItems = [
    {title: t('anchor.home'), href: '/'},
    {
      title: t('anchor.courses'),
      href: '/courses',
    },
    {title: `${course.courseName}`},
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  return (
    <div>
      <Breadcrumbs mb="sm" separator="→" styles={BreadcrumbsStyle}>
        {breadcrumbItems}
      </Breadcrumbs>
      {chapter && chapterPage && chapterPageIndex < chapterPageLength && (
        <CourseContentContainer>
          {resource && resource.resourceType.id === 0 && (
            <ImageDiv>
              <StyledImage width={400} height={250} src={resource.url} />
            </ImageDiv>
          )}
          {resource && resource.resourceType.id === 1 && (
            <StyledVideo controls ref={videoRef} controlsList="nodownload">
              <source src={resource.url} type="audio/wav" />
            </StyledVideo>
          )}
          {resource && resource.resourceType.id === 2 && (
            <>
              <StyledVideo controls ref={videoRef} controlsList="nodownload">
                <source src={resource.url} type="video/mp4" />
              </StyledVideo>
              {chapterPage?.text?.length && (
                <DescriptionText>{chapterPage.text}</DescriptionText>
              )}
            </>
          )}
          {!resource && (
            <>
              <StyledText>{chapter.chapterTitle}</StyledText>
              <PageTitle>{chapterPage.pageTitle}</PageTitle>
              <StyledText>{chapter.pages[chapterPageIndex]?.text}</StyledText>
            </>
          )}
          <Button
            onClick={() => handleAdvanceCourse({isQuiz: false})}
            rightIcon={
              <ArrowNarrowRight size={26} strokeWidth={1.4} color={'white'} />
            }
            style={ModifiedDarkButtonStyle}
          >
            {t('next')}
          </Button>
        </CourseContentContainer>
      )}
      {chapter &&
        !chapterPage &&
        exercise &&
        chapterPageIndex < chapterPageLength && (
          <>
            {exercise.exerciseType === ExerciseType.CHAT_BOT_V1 && (
              <>
                <VoicebotV1
                  practice={exercise}
                  callback={handleAdvanceCourse}
                  currentPageIndex={vb1Index}
                  showVoicebot={showVoicebot}
                  onIndexChange={handleOnPageIndexChange}
                />
              </>
            )}
            {exercise.exerciseType === ExerciseType.CHAT_BOT_V2 && (
              <>
                {conversation && conversationInitializeInfo && (
                  <VoicebotV2
                    conversation={conversation}
                    conversationInitialize={conversationInitializeInfo}
                    rasaBotKey={exercise.rasaBotKey}
                    callback={handleAdvanceCourse}
                    nextIterationCallback={advanceVoicebotV2}
                    showVoicebot={showVoicebot}
                    onSearchParamsChange={handleOnPageIndexChange}
                  />
                )}
              </>
            )}

            {userExerciseId &&
              conversationId &&
              exercise.exerciseType === ExerciseType.CHAT_BOT_V3 && (
                <ChatBotV3Container
                  userExerciseId={userExerciseId}
                  exercise={exercise}
                  conversationId={conversationId}
                  isCourse
                  handleAdvance={handleAdvanceCourse}
                />
              )}
          </>
        )}
      {chapter && !chapterPage && !exercise && chapter.quiz && (
        <CourseQuiz quiz={chapter.quiz} callback={handleAdvanceCourse} />
      )}
    </div>
  );
};
