import React, {FC} from 'react';
import {Progress} from '@mantine/core';
import styled from 'styled-components';
import {MetricCard} from 'components/common/Cards/MetricCard';
import {Color} from 'enums/common';
import {useTranslation} from 'react-i18next';

interface UserMetricsProps {
  points?: number;
  avgLearningTimePerWeek?: number;
  completedCourses?: number;
  assignedCoursesLength?: number;
  awards?: number;
  recommendedLearningTimePerWeek?: number;
}

const MetricWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  min-width: 300px;
  & > div {
    flex: 1;
  }
`;

const ProgressBarLabel = styled.div<{color?: string}>`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: ${({color = Color.DARK}) => color};
`;

const AwardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;

const Image = styled.img`
  width: 127px;
  height: auto;
  margin-top: -10px;
`;

const Awards = styled.div`
  color: ${Color.DARK};
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.15px;
`;

const AwardsTitle = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${Color.DARK};
`;

const ProgressBar: FC<{value: number; color: string}> = ({value, color}) => (
  <Progress value={value} color={color} />
);

const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;

const SpaceBetweenFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const UserMetrics: FC<UserMetricsProps> = ({
  assignedCoursesLength,
  completedCourses,
  avgLearningTimePerWeek,
  points,
  awards,
  recommendedLearningTimePerWeek,
}) => {
  const progressValue =
    ((typeof recommendedLearningTimePerWeek !== 'undefined' &&
    avgLearningTimePerWeek
      ? Math.min(avgLearningTimePerWeek, recommendedLearningTimePerWeek)
      : 0) /
      (recommendedLearningTimePerWeek || 1)) *
    100;

  const {t} = useTranslation('Dashboard');

  return (
    <MetricWrapper>
      <MetricCard
        title={t('metricCards.pointsCollected')}
        metricValue={points ? points : '0'}
      >
        <AwardsWrapper>
          <Awards>
            <AwardsTitle>{t('awards')}</AwardsTitle>
            🏆{awards}
          </Awards>
          <Image src="/images/collectedPoints.svg" />
        </AwardsWrapper>
      </MetricCard>
      <MetricCard
        title={t('metricCards.trainingTime')}
        metricValue={`${avgLearningTimePerWeek ?? 0} min`}
      >
        <ColumnFlex>
          <SpaceBetweenFlex>
            <ProgressBarLabel>{avgLearningTimePerWeek}min</ProgressBarLabel>
            <ProgressBarLabel>
              {recommendedLearningTimePerWeek}min
            </ProgressBarLabel>
          </SpaceBetweenFlex>
          <div>
            <ProgressBar value={progressValue} color={Color.GREEN_050} />
          </div>
          <SpaceBetweenFlex style={{paddingTop: '5px'}}>
            <ProgressBarLabel color="#8F87A8">{t('actual')}</ProgressBarLabel>
            <ProgressBarLabel color="#8F87A8">
              {t('recommended')}
            </ProgressBarLabel>
          </SpaceBetweenFlex>
        </ColumnFlex>
      </MetricCard>
      <MetricCard
        title={t('metricCards.coursesCompleted')}
        metricValue={`${completedCourses ?? 0}/${assignedCoursesLength ?? 0}`}
      />
    </MetricWrapper>
  );
};
