import {useQuery} from '@tanstack/react-query';
import {useContext} from 'react';
import {UserContext} from '../../../contexes';
import {ApiDataResponse} from '../../../types/api/ApiResponse';
import {LoginResponse} from '../../../types/auth/Auth';
import {validateToken} from '../../services/auth';

export const useToken = (token: string | null) => {
  const [, setUserContext] = useContext(UserContext);

  return useQuery(['validateToken', token], () => validateToken(token), {
    onSuccess(data: ApiDataResponse<LoginResponse>) {
      setUserContext(data.data);
    },
    retry: false,
  });
};
