import React, {
  ButtonHTMLAttributes,
  FC,
  DetailedHTMLProps,
  ReactNode,
} from 'react';
import styled from 'styled-components';
import {Button as MantineButton} from '@mantine/core';

import {Color} from '../../enums/common';

const StyledButton = styled(MantineButton)`
  font-size: 16;
  font-family: 'Montserrat';
  font-weight: 600;
  border-color: ${Color.DARK};
  background-color: ${Color.DARK};
  color: ${Color.WHITE};
  border-radius: 4px;
  height: '2.5em';
  margin-top: '30px';
  align-self: 'flex-end';
  &:hover {
    background-color: ${Color.DARK_LIGHTER};
  }
  &:disabled {
    background-color: ${Color.DARK_LIGHTER};
  }
`;

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: ReactNode;
}

export const Button: FC<ButtonProps> = props => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // <MantineButton {...(props as any)} style={StyledButton().root} />
  <StyledButton {...props} />
);
