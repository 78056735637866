import {useQuery} from '@tanstack/react-query';

import {ConversationInitializePayload} from '../../../types/conversations/conversations';

import {conversationInitialize} from '../../conversations/conversations';

export const useInitializeConversation = (
  conversation: ConversationInitializePayload,
  conversation_id: string,
  enabled?: boolean
) => {
  return useQuery(
    ['initializeConversation', conversation_id],
    () => conversationInitialize(conversation, conversation_id),
    {
      enabled,
      cacheTime: 0,
    }
  );
};
