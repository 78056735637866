import React from 'react';
import styled from 'styled-components';

import {Accordion, Image} from '@mantine/core';

import {Text} from 'components/common';
import {Script} from 'components/icons';

import {Color} from 'enums/common';

import {ConversationInitialize} from 'types/conversations/conversations';
import {Conversation} from 'types/practices';
import {VoiceBotV2SearchParams} from '../VoicebotV2/VoicebotV2';

const VoicebotV2AccordionContainer = styled.div`
  .mantine-Accordion-panel {
    background-color: white;
  }

  .mantine-Accordion-control {
    height: 40px;
    background-color: ${Color.PURPLE_500};
    margin-bottom: 5px;
  }
`;

const AccordionPanel = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  gap: 10px;
`;

const AccordionHeader = styled.div`
  border-bottom: 1px solid rgba(38, 20, 92, 0.12);
  margin-bottom: 16px;
`;

export interface VoicebotV2AccordionProps {
  conversation: Conversation;
  conversationInitialize: ConversationInitialize;
  currentPageIndex?: number;
  onSearchParamsChange?: (props: VoiceBotV2SearchParams) => void;
  showVoicebot?: boolean | null;
}

export const VoicebotV2Accordion: React.FC<VoicebotV2AccordionProps> = ({
  conversation,
  conversationInitialize,
  currentPageIndex = 0,
  onSearchParamsChange,
  showVoicebot,
}) => {
  return (
    <VoicebotV2AccordionContainer>
      <AccordionHeader>
        <Text fontSize={16} fontWeight={700} color={Color.DARK} mb={20}>
          {conversation.structure_title}
        </Text>
      </AccordionHeader>
      <Accordion
        defaultValue={conversation.section.title}
        chevronPosition="left"
      >
        <Accordion.Item value={conversation.section.title}>
          <Accordion.Control>
            <Text fontSize={14} fontWeight={700} color={Color.DARK}>
              {conversation.section.title}
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            {conversation.section.content.map((section, index) => (
              <AccordionPanel
                style={{cursor: 'pointer'}}
                key={index}
                onClick={() =>
                  onSearchParamsChange?.({
                    pageIndex: index,
                  })
                }
              >
                <Script />
                <Text
                  fontWeight={
                    index === currentPageIndex && !showVoicebot ? 700 : 500
                  }
                  fontSize={14}
                  color={Color.PURPLE_150}
                >
                  {section.section_title}
                </Text>
              </AccordionPanel>
            ))}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      {conversationInitialize && (
        <Accordion
          defaultValue={conversationInitialize.section.title}
          chevronPosition="left"
        >
          <Accordion.Item value={conversationInitialize.section.title}>
            <Accordion.Control>
              <Text fontSize={14} fontWeight={700} color={Color.DARK}>
                {conversationInitialize.section.title}
              </Text>
            </Accordion.Control>
            <Accordion.Panel>
              {conversationInitialize.section.content.map(
                (conversation, index) => (
                  <AccordionPanel key={index}>
                    <Image height={16} width={12} src="/images/voicebot.svg" />
                    <Text
                      fontSize={14}
                      fontWeight={
                        index === currentPageIndex && showVoicebot ? 700 : 500
                      }
                      color={Color.PURPLE_150}
                    >
                      {conversation.text}
                    </Text>
                  </AccordionPanel>
                )
              )}
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      )}
    </VoicebotV2AccordionContainer>
  );
};
