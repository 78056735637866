import React from 'react';
import styled from 'styled-components';

// Components
import {Image, Loader} from '@mantine/core';
import {Text} from 'components/common';

// Enums
import {Color} from 'enums/common';
import {RichTextContent} from 'components/RichText';
import {Feedback} from './ChatBotV3Feedback';
import {useTranslation} from 'react-i18next';

interface Props {
  title: string;
  feedback: Feedback | null;
  loading: boolean;
  summary: string | null;
}

// Styled components

const Container = styled.div`
  padding: 2rem;
  width: 100%;
  height: calc(100% - 80px);
  overflow-y: auto;
  border: 4px solid #e0f1ff;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;

const TitleWrapper = styled.div`
  padding: 1rem 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const ChatbotV3Analyses = ({
  title,
  loading = false,
  feedback,
  summary,
}: Props) => {
  const {t} = useTranslation('Practices');
  return (
    <Container>
      <Header>
        <Image width={86} height={56} src="/images/success.png" />

        <TitleWrapper>
          <Text fontSize={14} fontWeight={700} color={Color.DARK}>
            {title}
          </Text>
        </TitleWrapper>
        {loading && (
          <Loader
            variant="dots"
            color={Color.DARK}
            size="md"
            height={10}
            style={{alignSelf: 'center'}}
          />
        )}
      </Header>

      {feedback &&
        feedback.analyses &&
        feedback.analyses.map((item, index) => (
          <div style={{padding: '10px 0px'}} key={`analyses-${index}`}>
            <Text fontSize={10} fontWeight={700} color={Color.DARK}>
              {t('voicebot.conversation')} #{index + 1}
            </Text>
            <div style={{border: '1px solid #e0f1ff', width: '100%'}} />
            <RichTextContent htmlContent={item.feedback} />
          </div>
        ))}

      {feedback && (
        <div style={{padding: '10px 0px'}}>
          <Text fontSize={10} fontWeight={700} color={Color.DARK}>
            {t('summary')}
          </Text>
          <div style={{border: '1px solid #BCE0FE', width: '100%'}} />
          {summary && <RichTextContent htmlContent={summary} />}
        </div>
      )}

      {!summary && feedback && (
        <Loader
          variant="dots"
          color={Color.DARK}
          size="md"
          height={10}
          style={{alignSelf: 'center', width: '100%'}}
        />
      )}
    </Container>
  );
};

export default ChatbotV3Analyses;
