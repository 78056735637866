import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

// Types
import {ListPractice} from 'types/practices';
import {ExerciseType} from 'enums/practices';

// Service
import {useStartExercise} from 'api/tanstack-hooks/practices';

// Components
import {Color} from 'enums/common';
import {Text} from 'components/common';
import {EntityTileGrid} from 'components/Grids';
import {EntityCard} from 'components/Entity';
import styled from 'styled-components';

// Utils
import {generateExerciseRedirectUrl} from 'utils/exercise.util';

// Styles
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FlexContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  color: ${Color.DARK};
`;

// Types
export interface AllExercisesProps {
  practices: ListPractice[];
}

export const AllPractices: FC<AllExercisesProps> = ({practices}) => {
  // Hooks
  const {t} = useTranslation('Practices');
  const startExerciseMutation = useStartExercise();

  // Handlers
  const handlePracticeClick = (practice: ListPractice) => {
    startExerciseMutation.mutate(practice.userExerciseId);
  };

  return (
    <>
      <StyledHeader>
        <Text fontSize={20} fontWeight={700} color={Color.DARK}>
          {t('allPractices')}
        </Text>
      </StyledHeader>
      <FlexContainer style={{width: '100%'}}>
        <EntityTileGrid>
          {practices
            .sort((a, b) => a.completionPercent - b.completionPercent)
            .map(practice => (
              <EntityCard
                courseLevelLabel={
                  practice?.level?.name &&
                  t(`Dashboard:courseCards.${practice?.level?.name}`)
                }
                to={generateExerciseRedirectUrl(practice)}
                key={`${practice.id}_${practice.date}`}
                coverImage={practice.coverImage}
                title={practice.title}
                subtitle={practice?.subtitle}
                hideProgress={
                  practice.exerciseType === ExerciseType.CHAT_BOT_V3
                }
                totalChaptersLabel={`${practice.duration} ${t(
                  'practiceCards.practiceMinutes'
                )}`}
                learningPercent={practice.completionPercent}
                timesCompleted={
                  practice.timesCompleted > 0
                    ? `${t('Practices:practiceCards.timesCompleted')}: ${
                        practice.timesCompleted
                      }`
                    : ''
                }
                onClick={() => handlePracticeClick(practice)}
                buttonLabel={
                  practice.completionPercent === 0 ||
                  practice.exerciseType === ExerciseType.CHAT_BOT_V3
                    ? t('start')
                    : practice.completionPercent === 50
                    ? t('continue')
                    : t('restart')
                }
              />
            ))}
        </EntityTileGrid>
      </FlexContainer>
    </>
  );
};
