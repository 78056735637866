import React, {useState} from 'react';
import styled from 'styled-components';

import {createStyles} from '@mantine/core';

import {CollapseButton} from 'components/common';
import {
  VoicebotV1Accordion,
  VoiceBotV1SearchParams,
} from 'components/practices/voicebots/v1';

import {Practice} from 'types/practices';

const useSidebarStyles = createStyles({
  closed: {
    width: '4em',
    transitionDuration: '600ms',
  },
  open: {
    width: '26%',
    transitionDuration: '600ms',
  },
  transform: {
    transform: 'rotate(180deg)',
  },
});

const VoicebotV1SidebarContainer = styled.div`
  position: relative;
  display: flex;
  min-height: 100%;
  background-color: white;
  box-shadow: 0 2px 4px 0 #4d3c8233;
`;

const StyledCollapseButton = styled(CollapseButton)`
  position: absolute;
  top: 24px;
  left: -18px;
`;

const SidebarContent = styled.div`
  width: 80%;
  margin: 56px auto 0 auto;
`;

interface VoicebotV1SidebarProps {
  practice: Practice;
  currentPageIndex?: number;
  onIndexChange?: (props: VoiceBotV1SearchParams) => void;
}

export const VoicebotV1Sidebar: React.FC<VoicebotV1SidebarProps> = ({
  practice,
  currentPageIndex = 0,
  onIndexChange,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const {classes, cx} = useSidebarStyles();

  return (
    <VoicebotV1SidebarContainer
      className={cx(isOpen ? classes.open : classes.closed)}
    >
      <StyledCollapseButton
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <SidebarContent>
          <VoicebotV1Accordion
            currentPageIndex={currentPageIndex}
            practice={practice}
            onIndexChange={onIndexChange}
          />
        </SidebarContent>
      )}
    </VoicebotV1SidebarContainer>
  );
};
