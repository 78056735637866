import styled from 'styled-components';

// Enums
import {Color} from 'enums/common';

// Styled components
export const SlideContent = styled.div`
  min-height: 500px;
  padding: 32px 32px 0 32px;
  background-color: ${Color.WHITE};
  border: 1px solid #f1f0f0;
`;

export const SlideContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f1f0f0;
  padding-bottom: 12px;
`;

export const SlideFooter = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  background-color: ${Color.GRAY_500};
  border: 1px solid #e6e6ea;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
  height: 88px;
`;
