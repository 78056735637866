import {Table as MantineTable} from '@mantine/core';
import styled from 'styled-components';

export const Table = styled(MantineTable)`
  thead {
    font-family: Montserrat, sans-serif;
    background-color: #f0f0f2;
    color: #26145c;
  }

  th {
    line-height: 1.25rem;
    font-size: 0.75rem;
    font-weight: 600 !important;
    color: #26145ccc !important;
  }

  tbody {
    font-family: 'Montserrat', sans-serif;
    background-color: #ffffff !important;

    tr {
      border-bottom: 0.125rem solid #f1f1f1;
      &:hover {
        background-color: beige;
      }
    }
  }

  td {
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400 !important;
    color: #26145ccc !important;
    background-color: 000000;
    border-bottom: 5px #a09cad !important;
  }
`;
