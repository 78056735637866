import React from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {Anchor, Breadcrumbs} from '@mantine/core';

import {BreadcrumbsStyle} from 'styles';

import {BasePage} from 'components/common';
import {
  VoicebotV1,
  VoiceBotV1SearchParams,
  VoicebotV1Sidebar,
} from 'components/practices/voicebots/v1';

import {
  useCompleteExercise,
  useGetExercise,
} from 'api/tanstack-hooks/practices';

const PracticeV1Container = styled.div`
  display: flex;
  min-height: calc(100vh - 80px);
  justify-content: space-between;
`;

const PracticeContentContainer = styled.div`
  width: 55%;
  margin: 0 auto;
`;

export const PracticeV1: React.FC = () => {
  const {t} = useTranslation('Practices');
  const navigator = useNavigate();

  // Hooks
  const {userExerciseId} = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const {data} = useGetExercise(Number(userExerciseId));
  const completeExerciseMutation = useCompleteExercise();
  const pageIndex = parseInt(searchParams.get('pIndex') || '0');
  const showVoicebot = searchParams.get('showVoicebot') === 'true';

  // Handlers
  const handleOnCompleteExercise = () => {
    if (data) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      completeExerciseMutation.mutate(Number(userExerciseId!), {
        onSuccess: () => {
          navigator('/practices');
        },
      });
    }
  };

  const handleOnPageIndexChange = ({
    pageIndex: _pageIndex,
    showVoicebot: _showVoicebot,
  }: VoiceBotV1SearchParams) => {
    setSearchParams({
      pIndex: _pageIndex.toString(),
      ...(showVoicebot &&
        !_showVoicebot && {showVoicebot: showVoicebot.toString()}),
      ...(typeof _showVoicebot === 'boolean' && {
        showVoicebot: _showVoicebot.toString(),
      }),
    });
  };

  const breadcrumbItems = [
    {title: t('anchor.home'), href: '/'},
    {
      title: t('anchor.practices'),
      href: '/practices',
    },
    {title: `${data?.data.title}`},
  ].map((item, index) => (
    <Anchor key={index} href={item.href}>
      {item.title}
    </Anchor>
  ));

  return (
    <BasePage>
      {data && (
        <PracticeV1Container>
          <PracticeContentContainer>
            <Breadcrumbs separator=">" styles={BreadcrumbsStyle}>
              {breadcrumbItems}
            </Breadcrumbs>
            <VoicebotV1
              currentPageIndex={pageIndex}
              practice={data.data}
              callback={handleOnCompleteExercise}
              onIndexChange={handleOnPageIndexChange}
              showVoicebot={showVoicebot}
            />
          </PracticeContentContainer>
          <VoicebotV1Sidebar
            onIndexChange={handleOnPageIndexChange}
            currentPageIndex={pageIndex}
            practice={data.data}
          />
        </PracticeV1Container>
      )}
    </BasePage>
  );
};
