import React from 'react';
import {Header} from '../Header/Header';

export interface BasePageProps {
  children?: React.ReactNode;
}

export const BasePage: React.FC<BasePageProps> = ({children}) => {
  return (
    <div>
      <Header />
      {children}
    </div>
  );
};
