import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import {Info} from 'components/icons';
import {Text} from 'components/common';

import {Color} from 'enums/common';

const BottomTextContainerComponent = styled.div`
  padding: 30px 0 20px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px solid ${Color.PURPLE_400};
  margin: 20px 0 0 0;

  animation: fade-in 2s ease-in-out;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const StyledInfo = styled(Info)`
  position: absolute;
  top: -20px;
`;

const TextContent = styled.div`
  max-width: 80%;
`;

interface BottomTextContainerProps {
  text: string;
  keywords?: string;
}

export const BottomTextContainer: React.FC<BottomTextContainerProps> = ({
  text,
  keywords,
}) => {
  return (
    <BottomTextContainerComponent>
      <StyledInfo />
      <TextContent style={{wordBreak: 'break-word'}}>
        <Text fontSize={14} fontWeight={500} color={Color.PURPLE_TXT}>
          <ReactMarkdown>{text}</ReactMarkdown>
        </Text>
        {keywords && (
          <Text fontSize={14} fontWeight={500} color={Color.PURPLE_TXT} mb={10}>
            <ReactMarkdown>{keywords}</ReactMarkdown>
          </Text>
        )}
      </TextContent>
    </BottomTextContainerComponent>
  );
};
