import {useCallback, useState} from 'react';
import {Chapter} from 'types/courses';

// Types
import {AssignedCourse} from 'types/courses/courses';

// Constants
export const CourseLevels = {
  expert: 1,
  Expert: 1,
  intermediate: 2,
  Intermediate: 2,
  beginner: 3,
  Beginner: 3,
};

export const CourseFilters = {
  level: 'level',
  date: 'date',
  length: 'length',
};

export const getCoursesUniqueSkills = (courses: AssignedCourse[]) => {
  const _skills: string[] = [];
  courses.forEach(({skills}) => {
    skills.forEach(skill => {
      !_skills.includes(skill) && _skills.push(skill);
    });
  });
  return _skills.sort();
};

export const useFilterCourses = (courses: AssignedCourse[]) => {
  const [filter, setFiler] = useState<null | string>(null);
  const [topic, setTopic] = useState<null | string>(null);

  const handleSort = useCallback(
    (a: AssignedCourse, b: AssignedCourse) => {
      const aLevel = CourseLevels[a.level?.name];
      const bLevel = CourseLevels[b.level?.name];
      if (filter === CourseFilters.level) return bLevel - aLevel;
      if (filter === CourseFilters.length)
        return a.totalChapterNum - b.totalChapterNum;
      if (filter === CourseFilters.date)
        return (
          new Date(b.createDate).getTime() - new Date(a.createDate).getTime()
        );
      return 0;
    },
    [filter]
  );

  const filteredCourses = courses
    .filter(course => (topic ? course.skills.includes(topic) : true))
    .sort(handleSort);

  return {filter, setFiler, topic, setTopic, filteredCourses};
};

export const getChapterId = (chapter: Chapter, returnNumber = false) => {
  const id = `${chapter.chapterId || chapter.id || ''}`;
  if (returnNumber) parseInt(id);
  return id;
};

export enum DynamicVarialbes {
  USER_NAME = '{{USER_NAME}}',
  FIRM_NAME = '{{FIRM_NAME}}',
}

export interface DynamicVariables {
  value: string;
  type: DynamicVarialbes;
}

export const replaceDynamicVariables = (
  content: string,
  dynamicVariables: DynamicVariables[]
) => {
  let _content = content;
  dynamicVariables.forEach(({value, type}) => {
    _content = _content.replace(type, value);
  });
  return _content;
};

export const replaceDynamicVariablesInObject = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: any,
  objectKeys: string[],
  dynamicVariables: DynamicVariables[]
) => {
  const _obj = {...obj};
  objectKeys.forEach(key => {
    _obj[key] = replaceDynamicVariables(_obj[key], dynamicVariables);
  });
  return _obj;
};
