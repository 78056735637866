import {useQuery} from '@tanstack/react-query';
import {getDashboardMetrics} from 'api/department/department';
import {ApiDataResponse} from 'types/api/ApiResponse';
import {DashboardMetricsData, DashboardMetricsParams} from 'types/users/users';

export const useDashboardMetrics = ({
  departmentId,
  from,
  to,
}: DashboardMetricsParams) => {
  return useQuery<ApiDataResponse<DashboardMetricsData>, Error>(
    ['dashboardMetrics', {departmentId, from, to}],
    () => getDashboardMetrics({departmentId, from, to}),
    {
      enabled: !!departmentId,
    }
  );
};
