import React, {useState} from 'react';
import styled from 'styled-components';

import {createStyles} from '@mantine/core';

import {AccordionDropdown} from '../../CourseDetails/Accordions/AccordionDropdown';

import {CollapseButton} from 'components/common';

import {Chapter} from 'types/chapters/chapters';

const useSidebarStyles = createStyles({
  closed: {
    width: '4em',
    transitionDuration: '600ms',
  },
  open: {
    width: '32%',
    transitionDuration: '600ms',
  },
  transform: {
    transform: 'rotate(180deg)',
  },
});

const CourseSidebarContainer = styled.div`
  position: relative;
  display: flex;
  min-height: 100%;
  background-color: white;
  box-shadow: 0 2px 4px 0 #4d3c8233;
`;

const StyledCollapseButton = styled(CollapseButton)`
  position: absolute;
  top: 24px;
  left: -18px;
`;

const SidebarContent = styled.div`
  width: 80%;
  margin: 56px auto 0 auto;
`;

interface CoursePreviewSidebarProps {
  chapters: Chapter[];
  allowSelection: boolean;
  isLeadershipCourse?: boolean;
  overviewUrl: string;
}

export const CourseSidebar: React.FC<CoursePreviewSidebarProps> = ({
  chapters,
  allowSelection,
  isLeadershipCourse = false,
  overviewUrl,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const {classes, cx} = useSidebarStyles();

  return (
    <CourseSidebarContainer
      className={cx(isOpen ? classes.open : classes.closed)}
    >
      <StyledCollapseButton
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <SidebarContent>
          <AccordionDropdown
            openAllItems={!overviewUrl}
            allowSelection={allowSelection}
            chapters={chapters}
            isLeadershipCourse={isLeadershipCourse}
            overviewUrl={overviewUrl}
          />
        </SidebarContent>
      )}
    </CourseSidebarContainer>
  );
};
