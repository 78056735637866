import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

// Service
import {useStartExercise} from 'api/tanstack-hooks/courses';

// Components
import {EntityTileGrid} from 'components/Grids';
import {EntityCard} from 'components/Entity';
import styled from 'styled-components';
import {Text} from 'components/common';

// Types
import {Color} from 'enums/common';
import {ExerciseType} from 'enums/practices';
import {ListPractice} from 'types/practices';

// Types
export interface ToCompletePracticesProps {
  practices: ListPractice[];
}

// Styles
const FlexContainer = styled.div`
  width: 100%;
  margin-top: 6px;
  color: ${Color.DARK};
`;

export const ToCompletePractices: FC<ToCompletePracticesProps> = ({
  practices,
}) => {
  // Hooks
  const {t} = useTranslation('Practices');
  const startExerciseMutation = useStartExercise();

  // Handlers
  const generateRedirectUrl = (practice: ListPractice) => {
    if (practice.exerciseType === ExerciseType.CHAT_BOT_V1) {
      return `/practices/${practice.userExerciseId}`;
    } else if (practice.exerciseType === ExerciseType.CHAT_BOT_V2) {
      return `/practices/v2/rasaBotKey=${practice.rasaBotKey}&userExerciseId=${practice.userExerciseId}`;
    } else {
      return `/practices/v3/userExerciseId=${practice.userExerciseId}`;
    }
  };

  const handlePracticeClick = (practice: ListPractice) => {
    if (practice.exerciseType === ExerciseType.CHAT_BOT_V3) return;
    startExerciseMutation.mutate(practice.userExerciseId);
  };

  return (
    <>
      <Text fontSize={20} fontWeight={700} color={Color.DARK} mb={10}>
        {t('Practices:continueWhereLeft')}
      </Text>
      <FlexContainer style={{width: '100%'}}>
        <EntityTileGrid>
          {practices
            .filter(
              practice =>
                practice.completionPercent < 100 &&
                practice.completionPercent > 0
            )
            .sort((a, b) => a.completionPercent - b.completionPercent)
            .sort(
              (a, b) =>
                new Date(b.lastActivity).getTime() -
                new Date(a.lastActivity).getTime()
            )
            .map(practice => (
              <EntityCard
                to={generateRedirectUrl(practice)}
                key={`${practice.id}_${practice.date}`}
                coverImage={practice.coverImage}
                title={practice.title}
                subtitle={practice?.subtitle}
                totalChaptersLabel={`${practice.duration} ${t(
                  'practiceCards.practiceMinutes'
                )}`}
                hideProgress={
                  practice.exerciseType === ExerciseType.CHAT_BOT_V3
                }
                learningPercent={practice.completionPercent}
                timesCompleted={
                  practice.timesCompleted > 0
                    ? `${t('Practices:practiceCards.timesCompleted')}: ${
                        practice.timesCompleted
                      }`
                    : ''
                }
                onClick={() => handlePracticeClick(practice)}
                buttonLabel={
                  practice.completionPercent === 0 ||
                  practice.exerciseType === ExerciseType.CHAT_BOT_V3
                    ? t('start')
                    : practice.completionPercent === 50
                    ? t('continue')
                    : t('restart')
                }
              />
            ))}
        </EntityTileGrid>
      </FlexContainer>
    </>
  );
};
