import React from 'react';

export const Flag: React.FC = () => {
  return (
    <svg width="11" height="12" viewBox="0 0 11 12">
      <path
        d="M5.24016 2.00033L5.50683 3.33366H9.00016V7.33366H6.76016L6.4935 6.00033H1.66683V2.00033H5.24016ZM6.3335 0.666992H0.333496V12.0003H1.66683V7.33366H5.40016L5.66683 8.66699H10.3335V2.00033H6.60016L6.3335 0.666992Z"
        fill="#0D499D"
      />
    </svg>
  );
};
