import {Color} from 'enums/common';

export const BreadcrumbsStyle = {
  root: {
    marginTop: 16,
    marginBottom: 40,
  },
  breadcrumb: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: 500,
    color: Color.DARK,
  },
  separator: {color: Color.DARK, fontSize: '12px', fontWeight: 500},
};
