import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';

// Api
import {useRessetPassword} from 'api/tanstack-hooks/auth/useResetPassword';

// Style
import {Color} from '../../enums/common/Color';

// Components
import {Button, Group, Text, TextInput} from '@mantine/core';
import {useForm} from '@mantine/form';
import {AlertCircle} from 'tabler-icons-react';

// Styled components
const StyledError = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 15px;
`;

export const ForgotPasswordForm: React.FC = () => {
  const {t} = useTranslation('Login');
  const navigate = useNavigate();

  // hooks
  const {onSubmit, getInputProps, setFieldError} = useForm({
    initialValues: {
      email: '',
    },
  });
  const {mutate} = useRessetPassword();

  const handleOnSubmit = ({email}: {email: string}) => {
    mutate(email, {
      onSuccess: () => {
        navigate(`/`);
      },
      onError: () => {
        setFieldError(
          'email',
          <>
            <StyledError>
              <AlertCircle size={16} />
              <Text
                style={{
                  marginLeft: '0.5em',
                  fontSize: 12,
                  fontWeight: 500,
                }}
              >
                {t('resetPasswordFailed')}
              </Text>
            </StyledError>
          </>
        );
      },
    });
  };

  return (
    <form onSubmit={onSubmit(handleOnSubmit)}>
      <Group>
        <TextInput
          type="email"
          required
          size="lg"
          radius="xs"
          variant="unstyled"
          placeholder={t('email')}
          style={{
            border: '0.125rem solid rgba(38,20,92,.12)',
            width: '404px',
            height: '52px',
            marginBottom: '8px',
          }}
          sx={{
            input: {
              fontWeight: 500,
              fontSize: '1rem',
              padding: '0 .9375rem',
              fontFamily: 'Montserrat',
            },
          }}
          {...getInputProps('email', {type: 'input'})}
        />
        <Button
          type="submit"
          radius="xs"
          styles={() => ({
            root: {
              marginTop: '5px',
              width: '404px',
              height: '52px',
              color: Color.WHITE,
              backgroundColor: Color.DARK,
              fontWeight: 700,
              fontFamily: 'Montserrat',
              fontSize: '1.25rem',
              '&:hover': {
                backgroundColor: Color.DARK,
              },
            },
          })}
        >
          {t('send')}
        </Button>
      </Group>
    </form>
  );
};
