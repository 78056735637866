import {useCurrentWeeksOfYear} from 'utils/common/datesUtil';
import {create} from 'zustand';
import {persist} from 'zustand/middleware';

interface FilteredParams {
  departmentId: string | null;
  page: number;
  size: number;
  from: string | null;
  to: string | null;
  levelIds: string[];
  searchKey: string;
}

interface Actions {
  setDepartmentId: (departmentId: string | null) => void;
  setPage: (page: number) => void;
  setSize: (size: number) => void;
  setFrom: (from: string | null) => void;
  setTo: (to: string | null) => void;
  setLevelIds: (levelIds: string[]) => void;
  setSearchKey: (searchKey: string) => void;
}

// Initial state
const {getCurrentWeeksOfYear} = useCurrentWeeksOfYear();
const weeks = getCurrentWeeksOfYear();
const {from, to} =
  weeks.length > 0 ? weeks[weeks.length - 1].value : {from: '', to: ''};

const useLocalAndUrlStore = create<FilteredParams & Actions>()(
  persist(
    set => ({
      departmentId: null,
      page: 1,
      size: 15,
      from,
      to,
      levelIds: [],
      searchKey: '',
      setDepartmentId: departmentId => set(() => ({departmentId})),
      setPage: page => set(() => ({page})),
      setSize: size => set(() => ({size})),
      setFrom: from => set(() => ({from})),
      setTo: to => set(() => ({to})),
      setLevelIds: levelIds => set(() => ({levelIds})),
      setSearchKey: searchKey => set(() => ({searchKey})),
    }),
    {
      name: 'table-params',
    }
  )
);

export default useLocalAndUrlStore;
