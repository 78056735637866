import React, {useState} from 'react';
import styled from 'styled-components';

import {createStyles} from '@mantine/core';

import {CollapseButton} from 'components/common';
import {VoicebotV2Accordion} from 'components/practices/voicebots/v2';

import {Conversation} from 'types/practices';
import {ConversationInitialize} from 'types/conversations/conversations';
import {VoiceBotV2SearchParams} from '../VoicebotV2/VoicebotV2';

const useStyles = createStyles({
  closed: {
    width: '4em',
    transitionDuration: '600ms',
  },
  open: {
    width: '26%',
    transitionDuration: '600ms',
  },
  transform: {
    transform: 'rotate(180deg)',
  },
});

const VoicebotV2SidebarContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  background-color: white;
  box-shadow: 0 2px 4px 0 #4d3c8233;
`;

const StyledCollapseButton = styled(CollapseButton)`
  position: absolute;
  top: 24px;
  left: -18px;
`;

const SidebarContent = styled.div`
  width: 80%;
  margin: 56px auto 0 auto;
`;

export interface VoicebotV2SidebarProps {
  conversation: Conversation;
  conversationInitialize: ConversationInitialize;
  currentPageIndex?: number;
  onSearchParamsChange?: (props: VoiceBotV2SearchParams) => void;
  showVoicebot?: boolean | null;
}

export const VoicebotV2Sidebar: React.FC<VoicebotV2SidebarProps> = ({
  conversation,
  conversationInitialize,
  currentPageIndex = 0,
  onSearchParamsChange,
  showVoicebot,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const {classes, cx} = useStyles();

  return (
    <VoicebotV2SidebarContainer
      className={cx(isOpen ? classes.open : classes.closed)}
    >
      <StyledCollapseButton
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <SidebarContent>
          <VoicebotV2Accordion
            currentPageIndex={currentPageIndex}
            conversation={conversation}
            conversationInitialize={conversationInitialize}
            onSearchParamsChange={onSearchParamsChange}
            showVoicebot={showVoicebot}
          />
        </SidebarContent>
      )}
    </VoicebotV2SidebarContainer>
  );
};
