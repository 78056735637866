import {create} from 'zustand';
import {persist} from 'zustand/middleware';

interface ChatBotCommunicationStore {
  responseTime: string;
  setResponseTime: (value: string) => void;
}

export const useChatBotCommunicationStore = create<ChatBotCommunicationStore>()(
  persist(
    set => ({
      responseTime: '4000',
      setResponseTime: (value: string) => set({responseTime: value}),
    }),
    {
      name: 'response-time',
    }
  )
);
