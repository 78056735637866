export const detectLanguageId = (voicebotName: string | undefined) => {
  if (voicebotName) {
    const splitVoicebotName = voicebotName.split('-');

    if (splitVoicebotName[splitVoicebotName.length - 1] === 'nb') {
      return 1;
    }
  }

  return 2;
};
