import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Eye, EyeOff} from 'tabler-icons-react';

import {Button, Card, PasswordInput, Text, Title} from '@mantine/core';
import {useForm} from '@mantine/form';

import {useChangePassword} from '../../api/tanstack-hooks/auth';

import {Color} from '../../enums/common/Color';

import {UserProfileInfo} from '../../types/users/users';

const StyledNames = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-top: 10px;
  padding-bottom: 5px;
`;

const StyledBody = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 8px;
`;

export interface ProfileProps {
  userprofile: UserProfileInfo;
}

export const PasswordChangeFrom: React.FC<ProfileProps> = ({userprofile}) => {
  const {t} = useTranslation('MyProfile');

  const changePasswordMutation = useChangePassword();

  const form = useForm({
    initialValues: {
      oldPassword: '',
      newPassword: '',
    },
    validate: {
      newPassword: value => {
        if (value === form.values.oldPassword) {
          return t('changePassword.samePasswordError');
        }
        return null;
      },
    },
  });

  const handleSubmit = (oldPassword: string, newPassword: string) => {
    changePasswordMutation.mutate(
      {
        password: oldPassword,
        newPassword,
      },
      {
        onSuccess: () => {
          localStorage.removeItem('authToken');
          window.location.reload();
        },
      }
    );
  };

  return (
    <>
      <Card
        style={{
          marginLeft: '8.6em',
          width: '842px',
          height: '380px',
          padding: '2em 3em 0 3em',
        }}
        withBorder
        shadow="sm"
        p="lg"
        radius="xs"
        mt="xl"
      >
        <Card.Section withBorder inheritPadding py="xs">
          <StyledHeader>
            <StyledNames>
              <Text
                style={{
                  fontSize: '14px',
                  fontWeight: 700,
                  fontFamily: 'Montserrat',
                  color: Color.DARK,
                  paddingTop: '5px',
                }}
              >
                {t('name')}
              </Text>
            </StyledNames>
            <StyledNames>
              <Text
                style={{
                  fontSize: '18px',
                  fontWeight: 700,
                  fontFamily: 'Montserrat',
                  color: Color.DARK,
                }}
              >
                {userprofile.fullName}
              </Text>
            </StyledNames>
          </StyledHeader>
        </Card.Section>
        <StyledBody>
          <StyledNames>
            <Text
              style={{
                fontSize: '14px',
                fontWeight: 700,
                fontFamily: 'Montserrat',
                color: Color.DARK,
                paddingBottom: '42px',
                paddingTop: '26px',
              }}
            >
              {t('email')}
            </Text>
            <Text
              style={{
                fontSize: '14px',
                fontWeight: 700,
                fontFamily: 'Montserrat',
                color: Color.DARK,
                paddingBottom: '42px',
              }}
            >
              {t('currentPassword')}
            </Text>
            <Text
              style={{
                fontSize: '14px',
                fontWeight: 700,
                fontFamily: 'Montserrat',
                color: Color.DARK,
                paddingBottom: '48px',
              }}
            >
              {t('newPassword')}
            </Text>
          </StyledNames>
          <StyledNames>
            <Title
              style={{
                fontSize: '16px',
                fontWeight: 500,
                fontFamily: 'Montserrat',
                color: Color.DARK,
                paddingBottom: '32px',
                paddingTop: '26px',
              }}
            >
              {userprofile.email}
            </Title>
            <form
              onSubmit={form.onSubmit(values =>
                handleSubmit(values.oldPassword, values.newPassword)
              )}
            >
              <PasswordInput
                required
                size="md"
                radius="xs"
                variant="unstyled"
                placeholder={t('password')}
                {...form.getInputProps('oldPassword')}
                visibilityToggleIcon={({reveal}) =>
                  reveal ? (
                    <EyeOff color={Color.DARK} size={26} strokeWidth={2.5} />
                  ) : (
                    <Eye color={Color.DARK} size={26} strokeWidth={2.5} />
                  )
                }
                style={{
                  border: '0.125rem solid rgba(38,20,92,.12)',
                  width: '380px',
                  height: '42px',
                  marginBottom: '1.2em',
                }}
                sx={{
                  input: {
                    fontWeight: 500,
                    fontSize: '1rem',
                    padding: '0 .9375rem',
                    fontFamily: 'Montserrat',
                  },
                }}
              />
              <PasswordInput
                required
                size="md"
                radius="xs"
                variant="unstyled"
                placeholder={t('password')}
                {...form.getInputProps('newPassword')}
                visibilityToggleIcon={({reveal}) =>
                  reveal ? (
                    <EyeOff color={Color.DARK} size={26} strokeWidth={2.5} />
                  ) : (
                    <Eye color={Color.DARK} size={26} strokeWidth={2.5} />
                  )
                }
                style={{
                  border: '0.125rem solid rgba(38,20,92,.12)',
                  width: '380px',
                  height: '42px',
                  marginBottom: '1.2em',
                }}
                sx={{
                  input: {
                    fontWeight: 500,
                    fontSize: '1rem',
                    padding: '0 .9375rem',
                    fontFamily: 'Montserrat',
                  },
                }}
              />
              <Button
                type="submit"
                radius="xs"
                styles={() => ({
                  root: {
                    fontSize: 14,
                    fontFamily: 'Montserrat',
                    backgroundColor: Color.DARK,
                    fontWeight: 600,
                    width: '145px',
                    height: '48px',

                    '&:hover': {
                      backgroundColor: Color.DARK,
                    },
                  },
                })}
              >
                {t('saveChanges')}
              </Button>
            </form>
          </StyledNames>
        </StyledBody>
      </Card>
    </>
  );
};
