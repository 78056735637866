// hooks/useCourseFeedback.ts
import {useQuery} from '@tanstack/react-query';
import {getCourseFeedback} from 'api/user/user';
import {ApiDataResponse} from 'types/api/ApiResponse';
import {UserFeedback, CourseFeedbackParams} from 'types/users/users';

// Custom hook to fetch course feedback
export const useCourseFeedback = ({id, from, to}: CourseFeedbackParams) => {
  return useQuery<ApiDataResponse<UserFeedback>, Error>(
    ['courseFeedback', id, from, to],
    () => getCourseFeedback(id, from, to),
    {
      enabled: !!id,
    }
  );
};
