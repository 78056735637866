import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

// Import Utils
import {getCtaTitle} from 'utils/card';

// Import Styes
import {Color} from 'enums/common';

// Import Components
import {Space, Text} from '@mantine/core';
import {AssignedCourse} from 'types/courses/courses';
import {EntityTileGrid} from 'components/Grids';
import {EntityCard} from 'components/Entity';

const StyledCourseTitle = styled(Text)`
  margin-top: 16px;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
`;

const FlexContainer = styled.div`
  margin-top: 16px;
  color: ${Color.DARK};
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export interface AllSalesCoursesProps {
  courses: AssignedCourse[];
}

export const AllSalesCourses: React.FC<AllSalesCoursesProps> = ({courses}) => {
  const {t} = useTranslation('Dashboard');

  return (
    <>
      <StyledHeader>
        <StyledCourseTitle>{t('allCourses')}</StyledCourseTitle>
      </StyledHeader>
      <FlexContainer>
        <EntityTileGrid>
          {courses.map(course => (
            <EntityCard
              to={`/salesCourseDetails=${course.courseId}&userCourseId=${course.userCourseId}&isLeadership=true`}
              key={course.courseId}
              coverImage={course.coverImage}
              title={course.courseName}
              subtitle={course?.courseSubtitle}
              totalChaptersLabel={t('courseCards.chaptersLeft', {
                chapters: course.totalChapterNum,
              })}
              courseLevelLabel={t(`courseCards.${course.level?.name}`)}
              assignedLabel={t('courseCards.assigned')}
              buttonLabel={getCtaTitle(course.learningPercent)}
              learningPercent={course.learningPercent}
            />
          ))}
        </EntityTileGrid>
        <Space h={40} />
      </FlexContainer>
    </>
  );
};
