import React, {useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

// Other
import {Color} from '../../enums/common';

// Types
import {DepartmentUser} from 'types/users/users';

// Components
import {Container, Space, Text} from '@mantine/core';
import {BasePage} from '../../components/common';
import {ReactSelect} from 'components/form/ReactSelect';
import {Table} from 'components/table';

// Partias
import {_AssignUsers} from './_AssignUsers';

// Apis
import {assignUserToCourse} from 'api/courses/courses';

// Hooks
import {useGetCourseOverview} from 'api/tanstack-hooks/courses';
import {useGetDepartmentNames} from 'api/tanstack-hooks/department/useGetDepartmentNames';
import {useGetCourseUsers} from 'api/tanstack-hooks/courses/useGetCourseUsers';
import {useGetDepartmentUsers} from 'api/tanstack-hooks/department/useGetDeparmentUsers';

// Styled components
const StyledContainer = styled(Container)`
  margin-top: 30px;
  max-width: 80%;
  @media screen and (min-width: 1728px) {
    max-width: 67%;
  }
  @media screen and (min-width: 1920px) {
    max-width: 61%;
  }
`;

const StyledTitle = styled(Text)`
  font-size: 32px;
  line-height: 1.3em;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
  padding-bottom: 10px;
`;

const StyledTitleMd = styled(Text)`
  font-size: 22px;
  line-height: 1.3em;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
  padding-bottom: 10px;
`;

const SelectWrapper = styled('div')`
  max-width: 274px;
  min-width: 274px;
`;

const SpaceBetween = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AssignCourse: React.FC = () => {
  const {courseId = ''} = useParams();
  const {t} = useTranslation('Courses');

  // Hooks
  const {data: departmentNamesData} = useGetDepartmentNames();
  const {data} = useGetCourseOverview(+courseId);
  const courseTitle = data?.data.courseName ?? '';
  const defaultOption = {value: -1, label: t('assignCourse.chooseDepartment')};
  const {data: courseUsersData, refetch: refetchCourseUsers} =
    useGetCourseUsers(+courseId);
  const {data: unasignedUsersData, refetch: refetchUnasignedUsers} =
    useGetDepartmentUsers(+courseId);

  // State
  const [selectedUsersForAssignment, setSelectedUsersForAssignment] = useState<
    DepartmentUser[]
  >([]);
  const [selectedDeparatment, setSelectedDepartment] = useState<
    number | null | unknown
  >(-1);
  const departmentSelectOptions =
    departmentNamesData?.data.map(({name, departmentId}) => ({
      label: name,
      value: departmentId.toString(),
    })) || [];
  const allDepartmentOptions = [defaultOption, ...departmentSelectOptions];

  // Handlers
  const handleSaveSelectedUsers = async (
    users: DepartmentUser[] = selectedUsersForAssignment
  ) => {
    const userIds: number[] = users.map(({userId}) => userId);
    await assignUserToCourse(+courseId, userIds);
    await refetchCourseUsers();
    setSelectedUsersForAssignment([]);
    await refetchUnasignedUsers();
  };

  const handleAssignSelectedUsers = (selectedUsers: DepartmentUser[]) => {
    setSelectedUsersForAssignment(selectedUsers);
    handleSaveSelectedUsers(selectedUsers);
  };

  // Renderers
  const renderExisitngUsers = () => {
    const courseUsers = courseUsersData?.data?.elements || [];
    const filteredUserCourses = courseUsers.filter(({departments}) =>
      departments.map(({id}) => id).includes(Number(selectedDeparatment))
    );

    const _courseUsers =
      Number(selectedDeparatment) === -1 ? courseUsers : filteredUserCourses;

    if (!_courseUsers.length && !selectedUsersForAssignment.length) {
      return (
        <tr>
          <td colSpan={4} align="center">
            {t('assignCourse.noUsersAssignedToCourse')}
          </td>
        </tr>
      );
    }
    return (
      _courseUsers.map((user, index) => (
        <tr key={`${user.user.id}_${index}`}>
          <td>{user?.user?.name || ''}</td>
          <td>{user?.departments?.map(({name}) => name).join(', ') || ''}</td>
          <td>
            {t(`assignCourse.courseStatus.${user.status}`, {defaultValue: ''})}
          </td>
          <td>{user?.learningPercent}%</td>
        </tr>
      )) || null
    );
  };

  const renderToBeAssignedUsers = () => {
    if (!selectedUsersForAssignment.length) return null;
    return (
      selectedUsersForAssignment.map((user, index) => (
        <tr key={`${user.userId}_${index}`}>
          <td>{user?.userName || ''}</td>
          <td>{user?.departments?.map(({name}) => name).join(', ') || ''}</td>
          <td>-</td>
          <td>-</td>
        </tr>
      )) || null
    );
  };

  return (
    <BasePage>
      <StyledContainer>
        <StyledTitle>{courseTitle}</StyledTitle>
        <StyledTitleMd>
          {t('assignCourse.assignACourseToEmployees')}
        </StyledTitleMd>
        <_AssignUsers
          courseId={Number(courseId)}
          departments={departmentNamesData?.data || []}
          courseTitle={courseTitle}
          assignedUsers={selectedUsersForAssignment}
          onAssignSelectedUsers={handleAssignSelectedUsers}
          unasignedUsers={unasignedUsersData?.data || []}
        />

        <StyledTitleMd>
          {t('assignCourse.overviewOfCourseEmployees')}
        </StyledTitleMd>
        <SpaceBetween>
          <SelectWrapper>
            <ReactSelect
              placeholder={t('assignCourse.chooseDepartment')}
              value={allDepartmentOptions.find(
                department => department.value == selectedDeparatment
              )}
              options={allDepartmentOptions}
              onChange={value => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setSelectedDepartment((value as any).value);
              }}
            />
          </SelectWrapper>
        </SpaceBetween>
        <Space h="md" />
        <Table>
          <thead>
            <tr>
              <th>{t('assignCourse.person')}</th>
              <th>{t('assignCourse.team')}</th>
              <th>{t('assignCourse.status')}</th>
              <th>{t('assignCourse.progress')}</th>
            </tr>
          </thead>
          <tbody>
            {renderExisitngUsers()}
            {renderToBeAssignedUsers()}
          </tbody>
        </Table>
      </StyledContainer>
      <Space h="lg" />
    </BasePage>
  );
};
