import React, {useContext} from 'react';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Logo} from '../Logo/Logo';
import {UserAvatar} from '../UserAvatar/UserAvatar';

import {UserContext} from '../../../contexes';

import {Color} from '../../../enums/common';
import {Role} from '../../../enums/auth';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
  align-items: center;
  height: 80px;
  background-color: ${Color.DARK};

  a {
    align-items: center;
    text-decoration: none;
    color: white;
    font-size: 14px;
  }
`;

const SpanStyled = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

const NavLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  font-weight: bold;
  font-size: 18px;
`;

interface StyleProps {
  isActive: boolean;
}

const NavLinkStyle = ({isActive}: StyleProps) => {
  return {
    color: isActive ? '#74c1b7' : 'white',
    borderTop: isActive ? '4px solid #74c1b7' : '4px solid transparent',
    padding: '24px 0px',
  };
};

export const Header: React.FC = () => {
  const {t} = useTranslation('Common');
  const [userContext] = useContext(UserContext);

  return (
    <HeaderContainer>
      <Logo />
      <NavLinkContainer>
        {userContext.authority.includes(Role.MEMBER) && (
          <>
            <NavLink to="/dashboard" style={NavLinkStyle}>
              <SpanStyled>{t('navbar.dashboard')}</SpanStyled>
            </NavLink>
            <NavLink to="/courses" style={NavLinkStyle}>
              <SpanStyled>{t('navbar.courses')}</SpanStyled>
            </NavLink>
            <NavLink to="/practices" style={NavLinkStyle}>
              <SpanStyled>{t('navbar.practices')}</SpanStyled>
            </NavLink>
          </>
        )}
        {userContext.authority.includes(Role.MANAGER) && (
          <>
            <NavLink to="/dashboard" style={NavLinkStyle}>
              <SpanStyled>{t('navbar.dashboard')}</SpanStyled>
            </NavLink>
            <NavLink to="/courses" style={NavLinkStyle}>
              <SpanStyled>{t('navbar.courses')}</SpanStyled>
            </NavLink>
            <NavLink to="/manager/salesCourses" style={NavLinkStyle}>
              <SpanStyled>{t('navbar.salesCourses')}</SpanStyled>
            </NavLink>
          </>
        )}
      </NavLinkContainer>
      <UserAvatar />
    </HeaderContainer>
  );
};
