import {createAxiosInstance} from 'api/config';
import {Conversations, ServerTime} from '../../enums/conversations/urls';

import {
  ConversationInitialize,
  ConversationInitializePayload,
  ConversationMessage,
  ConversationMessagePayload,
  ConversationPayload,
} from '../../types/conversations/conversations';
import {Conversation} from '../../types/practices';
import {configuration} from 'config';

const axios = createAxiosInstance(configuration().baseConversationsUrl);

export const getConversation = async (
  payload: ConversationPayload
): Promise<Conversation> => {
  const {data} = await axios.post(`/${Conversations.CONVERSATIONS}`, payload);

  return data;
};

export const conversationInitialize = async (
  conversation: ConversationInitializePayload,
  conversation_id: string
): Promise<ConversationInitialize> => {
  const {data} = await axios.post(
    `/${Conversations.CONVERSATION_INITIALIZE}/${conversation_id}`,
    conversation
  );

  return data;
};

export const conversationMessage = async (
  conversation: ConversationMessagePayload,
  conversation_id: string
): Promise<ConversationMessage> => {
  const {data} = await axios.post(
    `/${Conversations.RASA_CONVERSATION}/${conversation_id}`,
    conversation
  );

  return data;
};

export const getServerTime = async () => {
  const {data} = await axios.get(`/${ServerTime.GET_SERVER_TIME}`);

  return data;
};
