import {createAxiosInstance} from 'api/config';
import {Department as DepartmentUrls} from '../../enums/department/urls';

import {ApiDataResponse, ApiResponse} from '../../types/api/ApiResponse';
import {
  DashboardMetricsData,
  DashboardMetricsParams,
  Department,
  DepartmentUser,
  TeamOverviewParams,
  TeamEmployee,
  UserTeamOverviewParams,
} from 'types/users/users';
import {configuration} from 'config';

const axios = createAxiosInstance();

export const getDepartmentNames = async () => {
  const {data} = await axios.get<ApiDataResponse<Department[]>>(
    `${configuration().baseApiUrl}/${DepartmentUrls.NAMES}`
  );

  return data;
};

export const getDepartmentUsers = async (courseId: number) => {
  const {data} = await axios.get<ApiDataResponse<DepartmentUser[]>>(
    `${configuration().baseApiUrl}/${
      DepartmentUrls.DEPARTMENT
    }/users?courseId=${courseId}`
  );

  return data;
};

export const getDashboardMetrics = async ({
  departmentId,
  from,
  to,
}: DashboardMetricsParams): Promise<ApiDataResponse<DashboardMetricsData>> => {
  const response = await axios.get<ApiDataResponse<DashboardMetricsData>>(
    `${configuration().baseApiUrl}/${
      DepartmentUrls.DEPARTMENT
    }/${departmentId}/dashboard-metrics`,
    {
      params: {
        from,
        to,
      },
    }
  );
  return response.data;
};

export const getTeamOverview = async ({
  departmentId,
  page,
  size,
  from,
  to,
  levelIds,
  searchKey,
  sortKey,
}: TeamOverviewParams): Promise<
  ApiDataResponse<ApiResponse<TeamEmployee[]>>
> => {
  const response = await axios.get<
    ApiDataResponse<ApiResponse<TeamEmployee[]>>
  >(
    `${configuration().baseApiUrl}/${
      DepartmentUrls.DEPARTMENT
    }/${departmentId}/team-overview`,
    {
      params: {
        from,
        levelIds,
        page,
        searchKey,
        size,
        sortKey,
        to,
      },
    }
  );

  return response.data;
};

export const getUserTeamOverview = async ({
  page,
  size,
  from,
  to,
  sortKey,
}: UserTeamOverviewParams): Promise<
  ApiDataResponse<ApiResponse<TeamEmployee[]>>
> => {
  const response = await axios.get<
    ApiDataResponse<ApiResponse<TeamEmployee[]>>
  >(
    `${configuration().baseApiUrl}/${DepartmentUrls.DEPARTMENT}/team-overview`,
    {
      params: {
        page,
        size,
        from,
        to,
        sortKey,
      },
    }
  );

  return response.data;
};
