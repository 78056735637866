import {createAxiosInstance} from 'api/config';

import {ApiDataResponse, ApiResponse} from '../../types/api/ApiResponse';
import {ListPractice, Practice} from '../../types/practices';

import {AssignedPractices} from 'enums/practices/urls';
import {Exercise} from 'enums/exercises';
import {LearningProgress} from 'enums/learning-progress';

const axios = createAxiosInstance();

export const getAssignedPractices = async (page: number, size: number) => {
  const {data} = await axios.get<ApiDataResponse<ApiResponse<ListPractice[]>>>(
    `/${AssignedPractices.PRACTICES}`,
    {
      params: {
        page,
        size,
      },
    }
  );

  return data;
};

export const getExercise = async (userExerciseId: number) => {
  const {data} = await axios.get<ApiDataResponse<Practice>>(
    `/${Exercise.GET_EXERCISE}/${userExerciseId}`
  );

  return data;
};

export const startExercise = async (userExerciseId: number) => {
  const {data} = await axios.put<ApiDataResponse<void>>(
    `/${LearningProgress.LEARNING_PROGRESS}/${userExerciseId}/${LearningProgress.START_EXERCISE}`
  );

  return data;
};

export const completeExercise = async (userExerciseId: number) => {
  const {data} = await axios.put<ApiDataResponse<void>>(
    `/${LearningProgress.LEARNING_PROGRESS}/${userExerciseId}/${LearningProgress.COMPLETE_EXERCISE}`
  );

  return data;
};

export const chatBotV3Communication = async ({
  message,
  conversationId,
}: {
  message: string;
  conversationId: number;
}) => {
  const {data} = await axios.post(`/conversation/${conversationId}`, {
    message,
  });

  return data;
};

export const generateChatFeedback = async ({
  conversationId,
  isClientSatisfied,
}: {
  conversationId: number;
  isClientSatisfied: boolean;
}) => {
  const {data} = await axios.get(`/conversation/${conversationId}/feedback`, {
    params: {isClientSatisfied},
  });

  return data;
};

export const getChatAnalyses = async ({
  conversationIds,
}: {
  conversationIds: number[];
}) => {
  const {data} = await axios.post(`/conversation/analyzes`, conversationIds);

  return data;
};

export const getChatSummary = async ({
  conversationIds,
}: {
  conversationIds: number[];
}) => {
  const {data} = await axios.post(`/conversation/summary`, conversationIds);

  return data;
};
