import React from 'react';

import './typing.scss';

interface Props {
  darkDots?: boolean;
}

const Typing = ({darkDots = false}: Props) => {
  const dotSchema = darkDots ? 'dark' : 'light';

  return (
    <div className="typing">
      <div className={dotSchema} />
      <div className={dotSchema} />
      <div className={dotSchema} />
    </div>
  );
};

export default Typing;
