import React from 'react';
import styled from 'styled-components';

import {Anchor, Breadcrumbs, Button, Text} from '@mantine/core';

import {Color} from '../../../../enums/common';
import {AssignedCourse} from '../../../../types/courses/courses';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Chapter} from 'types/chapters/chapters';

const StyledTitle = styled(Text)`
  font-size: 28px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
  padding-bottom: 12px;
`;

const CoursePreviewHeaderContainer = styled.div`
  background-color: #e7e7f8;
  height: 300px;
  padding-top: 15px;
  padding-left: 130px;
`;

const StyledButton = () => {
  return {
    root: {
      fontFamily: 'Montserrat',
      color: Color.WHITE,
      borderRadius: 0,
      backgroundColor: Color.DARK,
      width: '13em',
      height: '2.8em',
    },
    '&:hover': {
      backgroundColor: Color.DARK,
    },
  };
};

export interface ManagerTopCourseDetailsProps {
  courses: AssignedCourse;
  chapters: Chapter[];
}

export const ManagerTopCourseDetails: React.FC<
  ManagerTopCourseDetailsProps
> = ({courses}) => {
  const {t} = useTranslation('Courses');

  const items = [
    {title: t('anchor.home'), href: '/'},
    {
      title: t('anchor.courses'),
      href: '/courses',
    },
    {title: `${courses.courseName}`},
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  return (
    <CoursePreviewHeaderContainer>
      <Breadcrumbs
        style={{paddingBottom: '70px'}}
        separator="→"
        styles={{
          breadcrumb: {
            color: Color.DARK,
            fontSize: '12px',
            fontFamily: 'Montserrat',
          },
          separator: {color: Color.DARK, fontSize: '12px'},
        }}
      >
        {items}
      </Breadcrumbs>
      <StyledTitle>{courses.courseName}</StyledTitle>
      <Button
        component={Link}
        to={`/manager/course/${courses.courseId}/assign`}
        style={StyledButton().root}
      >
        {t('courseDetails.assignToACourse')}
      </Button>
    </CoursePreviewHeaderContainer>
  );
};
