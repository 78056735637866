import React from 'react';
import styled from 'styled-components';

// Components
import {Image, Loader} from '@mantine/core';
import {Text} from 'components/common';

// Enums
import {Color} from 'enums/common';
import {useTranslation} from 'react-i18next';
import {RichTextContent} from 'components/RichText';

interface Props {
  title: string;
  description: string;
  hint?: string;
  success?: boolean;
  centered?: boolean;
  boldTitle?: boolean;
  loading?: boolean;
  descriptionCentered?: boolean;
  html?: boolean;
  style?: Record<string, unknown>;
}

// Styled components
const Container = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  height: 100%;
  justify-content: center;
`;

const InformationWrapper = styled.div`
  padding: 2rem 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: ${({success}: {success: boolean}) => (success ? '100%' : 'auto')};
  border: 4px solid #e0f1ff;
`;

const TextWrapper = styled.div`
  padding: 1rem 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({centered}: {centered: boolean}) =>
    centered ? 'center' : 'start'};
  text-align: ${({centered}: {centered: boolean}) =>
    centered ? 'center' : 'start'};
  justify-content: center;
`;

const TitleWrapper = styled.div`
  padding: 1rem 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const ChatBotDummyContainer = ({
  title,
  description,
  success = false,
  centered = false,
  boldTitle = false,
  loading = false,
  html = false,
  style = {},
}: Props) => {
  const {t} = useTranslation('Practices');

  return (
    <Container style={style}>
      {!success && (
        <TitleWrapper>
          <Text fontSize={18} fontWeight={700} color={Color.DARK}>
            {t('voicebot.tutorialTitle')}
          </Text>
        </TitleWrapper>
      )}
      <InformationWrapper success={success}>
        {!success && <Image width={41} height={41} src="/images/star.svg" />}
        {success && <Image width={86} height={56} src="/images/success.png" />}

        <TextWrapper centered={centered}>
          <TitleWrapper>
            <Text
              fontSize={14}
              fontWeight={boldTitle ? 700 : 500}
              color={Color.DARK}
            >
              {title}
            </Text>
          </TitleWrapper>
          {loading && (
            <Loader
              variant="dots"
              color={Color.DARK}
              size="md"
              height={10}
              style={{alignSelf: 'center'}}
            />
          )}

          {html && <RichTextContent htmlContent={description} />}
          {!html && (
            <Text fontSize={14} fontWeight={500} color={Color.DARK}>
              {!loading && (
                <span style={{whiteSpace: 'pre-line'}}>
                  <RichTextContent htmlContent={description} />
                </span>
              )}
            </Text>
          )}
        </TextWrapper>
      </InformationWrapper>
    </Container>
  );
};

export default ChatBotDummyContainer;
