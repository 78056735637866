import {Button, createStyles, Modal, Space, Text} from '@mantine/core';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import i18n from '../../../i18n';

import {Color} from '../../../enums/common';

export interface LanguageModalProps {
  opened: boolean;
  onClose: () => void;
}

const StyledButton = () => {
  return {
    root: {
      fontSize: 14,
      fontFamily: 'Montserrat',
      fontWeight: 600,
      borderColor: Color.DARK,
      backgroundColor: Color.DARK,
      color: Color.WHITE,
      marginLeft: 220,
      borderRadius: '0',

      '&:hover': {
        backgroundColor: Color.DARK,
      },
    },
  };
};

const useStyles = createStyles({
  Text: {
    fontSize: '17px',
    color: Color.DARK,
    paddingBottom: '15px',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    cursor: 'pointer',
  },

  active: {
    fontWeight: 700,
  },
});

export const LanguageChangeModal: React.FC<LanguageModalProps> = ({
  opened,
  onClose,
}) => {
  const {t} = useTranslation('MyProfile');
  const [active, setActive] = useState(i18n.language);
  const {classes, cx} = useStyles();

  const handleChangeLanguage = () => {
    void i18n.changeLanguage(active);
    onClose();
  };

  const handleClose = () => {
    setActive(i18n.language);
    onClose();
  };

  return (
    <Modal
      centered
      data-testid="language-modal"
      size="lg"
      title={t('language')}
      opened={opened}
      onClose={handleClose}
    >
      <Space h="xs" />
      <Text
        className={cx(classes.Text, {[classes.active]: active === 'en'})}
        onClick={() => {
          setActive('en');
        }}
      >
        {i18n.language === 'en' ? 'English' : 'Engelsk'}
      </Text>
      <Text
        className={cx(classes.Text, {[classes.active]: active === 'no'})}
        onClick={() => {
          setActive('no');
        }}
      >
        {i18n.language === 'en' ? 'Norwegian' : 'Norsk'}
      </Text>

      <Text
        className={cx(classes.Text, {[classes.active]: active === 'sv'})}
        onClick={() => {
          setActive('sv');
        }}
      >
        {i18n.language === 'en'
          ? 'Swedish'
          : i18n.language === 'no'
          ? 'Svensk'
          : 'Svenska'}
      </Text>
      <Space h="xs" />
      <Button onClick={handleChangeLanguage} style={StyledButton().root}>
        {t('modal.save')}
      </Button>
    </Modal>
  );
};
