import React from 'react';

// Hooks
import {useGetAssignedPractices} from 'api/tanstack-hooks/practices';

import {AllPractices} from 'components/courses/user/AllPractices';
import {BasePage} from 'components/common';
import {MainContainer} from 'components/common/Containers';
import styled from 'styled-components';
import {Space} from '@mantine/core';
import {ToCompletePractices} from 'components/courses/user';

// Styled components
const StyledWelcomeItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

// Declarations
const PAGE = 1;
const LIMIT = 100;

export const Practices: React.FC = () => {
  const {data} = useGetAssignedPractices(PAGE, LIMIT);
  const practices = data?.data?.elements || [];

  return (
    <BasePage>
      <MainContainer>
        <StyledWelcomeItems>
          <Space h={16} />

          {<ToCompletePractices practices={practices} />}
        </StyledWelcomeItems>
        <AllPractices practices={practices} />
      </MainContainer>
    </BasePage>
  );
};
