const envConfig = {
  env: process.env.REACT_APP_ENV,
  isProduction: process.env.NODE_ENV === 'production',
  gaKey: process.env.REACT_APP_GA_KEY || '',
  baseApiUrl: process.env.REACT_APP_BASE_API_URL,
  baseConversationsUrl: process.env.REACT_APP_BASE_CONVERSATIONS_URL,
  i18nDebug: process.env.REACT_APP_I18N_DEBUG,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  voiceBot: {
    interpretedTextRetries: 2,
    acceptableAccuracy: 0.5,
  },
};

export const configuration = () => {
  if (!envConfig.env) throw new Error('There is no "REACT_APP_ENV" setted up!');

  if (!envConfig.baseApiUrl)
    throw new Error('There is no "REACT_APP_BASE_API_URL" setted up!');

  if (!envConfig.baseConversationsUrl)
    throw new Error(
      'There is no "REACT_APP_BASE_CONVERSATIONS_URL" setted up!'
    );

  if (!envConfig.gaKey) {
    console.warn('There is no "REACT_APP_GA_KEY" setted up!');
  }

  if (!envConfig.i18nDebug)
    throw new Error('There is no "REACT_APP_I18N_DEBUG" setted up!');

  return envConfig;
};
