import {useQuery} from '@tanstack/react-query';
import {getTeamOverview} from 'api/department/department';
import {ApiDataResponse, ApiResponse} from 'types/api/ApiResponse';
import {TeamOverviewParams, TeamEmployee} from 'types/users/users';
import {filterFalsyValues} from 'utils/common';

export const useTeamOverview = (params: TeamOverviewParams) => {
  const {departmentId, page, size} = filterFalsyValues(params);
  return useQuery<ApiDataResponse<ApiResponse<TeamEmployee[]>>, Error>(
    ['teamOverview', params],
    () => getTeamOverview(filterFalsyValues(params)),
    {
      enabled: !!(departmentId && page && size),
    }
  );
};
