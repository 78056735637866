import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {Text} from 'components/common';

import {Color} from 'enums/common';

const SentMessageContainer = styled.div`
  align-self: flex-end;
  display: flex;
  gap: 10px;
  margin-top: 10px;

  animation: message-sent 0.5s ease-in-out;

  @keyframes message-sent {
    0% {
      opacity: 0;
      transform: translateX(20px);
    }
    100% {
      opacity: 1;
    }
  }
`;

const CustomerAvatar = styled.div`
  align-self: flex-end;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url('/images/avatar.png');
  background-size: cover;
`;

const MessageContent = styled.div`
  max-width: 450px;
  background-color: ${Color.PURPLE_TXT};
  padding: 16px 16px 12px 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
`;

interface SentMessageProps {
  message: string;
  date?: Date;
  hideDate?: boolean;
  isScriptText?: boolean;
}

export const SentMessage: React.FC<SentMessageProps> = ({
  message,
  date,
  hideDate = false,
  isScriptText = false,
}) => {
  const {t} = useTranslation('Practices');

  const dateString = date
    ? date.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
    : null;

  return (
    <SentMessageContainer>
      <div>
        {!isScriptText && (
          <Text fontSize={12} fontWeight={500} color={Color.GRAY_200} mb={4}>
            {t('voicebot.yourResponse')}
          </Text>
        )}
        {isScriptText && (
          <Text fontSize={12} fontWeight={500} color={Color.GRAY_200} mb={4}>
            {t('voicebot.bestPractice')}
          </Text>
        )}
        <MessageContent>
          <Text fontSize={14} fontWeight={500} color={Color.WHITE} mb={8}>
            {message}
          </Text>
          {!hideDate && (
            <Text fontSize={12} fontWeight={500} color={Color.ALPHA_WHITE_56}>
              {dateString}
            </Text>
          )}
        </MessageContent>
      </div>
      <CustomerAvatar />
    </SentMessageContainer>
  );
};
