import {createAxiosInstance} from 'api/config';

import {Auth} from '../../../enums/auth/urls';
import {Role} from 'enums/auth/Role';

import {ApiDataResponse} from '../../../types/api/ApiResponse';
import {
  ChangePasswordPayload,
  LoginPayload,
  LoginResponse,
} from '../../../types/auth/Auth';

const axios = createAxiosInstance();

const validateRoles = (user: LoginResponse, roles: Role[]) => {
  let hasAccess = false;

  for (const role of roles) {
    if (user.authority.includes(role)) {
      hasAccess = true;
      break;
    }
  }

  if (!hasAccess) {
    throw new Error('No permissions to enter this app.');
  }
};

export const login = async (
  payload: LoginPayload
): Promise<ApiDataResponse<LoginResponse>> => {
  const {data} = await axios.post(`/${Auth.LOGIN}`, payload);

  validateRoles(data.data, [Role.MEMBER, Role.MANAGER]);

  return data;
};

export const changePassword = async (payload: ChangePasswordPayload) => {
  const {data} = await axios.put(`/${Auth.CHANGE_PASSWORD}`, payload);

  return data;
};

export const validateToken = async (
  token: string | null
): Promise<ApiDataResponse<LoginResponse>> => {
  if (!token) {
    throw new Error('Token is required');
  }

  const {data} = await axios.get(`/${Auth.VALIDATE_TOKEN}`, {
    headers: {
      token,
    },
  });

  validateRoles(data.data, [Role.MEMBER, Role.MANAGER]);

  return data;
};

export const resetPassword = async (email: string) => {
  const {data} = await axios.put(`/${Auth.PASSWOR_RESET}`, {email});

  return data;
};
