import React from 'react';
import styled from 'styled-components';

interface CollapseButtonContainerProps {
  isOpen: boolean;
}

const CollapseButtonContainer = styled.svg`
  cursor: pointer;

  transform: ${(props: CollapseButtonContainerProps) =>
    props.isOpen ? 'rotate(0deg)' : 'rotate(180deg)'};

  :hover {
    opacity: 0.8;
  }
`;

interface CollapseButtonProps {
  isOpen: boolean;
  onClick: () => void;
  className?: string;
}

export const CollapseButton: React.FC<CollapseButtonProps> = ({
  isOpen,
  onClick,
  className,
}) => {
  return (
    <CollapseButtonContainer
      isOpen={isOpen}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      onClick={onClick}
      className={className}
    >
      <rect width="32" height="32" rx="16" fill="#DDDDF8" />
      <path
        d="M11.3415 11L10.1665 12.175L13.9832 16L10.1665 19.825L11.3415 21L16.3415 16L11.3415 11Z"
        fill="#26145C"
      />
      <path
        d="M16.8332 11L15.6582 12.175L19.4749 16L15.6582 19.825L16.8332 21L21.8332 16L16.8332 11Z"
        fill="#26145C"
      />
    </CollapseButtonContainer>
  );
};
