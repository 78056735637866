import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

// Types
import {Color} from 'enums/common';

// Components
import {AssignedCourse} from 'types/courses/courses';
import {EntityTileGrid} from 'components/Grids';
import {EntityCard} from 'components/Entity';
import {Text} from 'components/common';

// Utils
import {getCtaTitle} from 'utils/card';

const FlexContainer = styled.div`
  width: 100%;
  margin-top: 6px;
  color: ${Color.DARK};
`;
export interface ToCompleteCoursesProps {
  courses: AssignedCourse[];
}

export const ToCompleteCourses: React.FC<ToCompleteCoursesProps> = ({
  courses,
}) => {
  const {t} = useTranslation('Dashboard');

  return (
    <>
      <Text fontSize={20} fontWeight={700} color={Color.DARK} mb={10}>
        {t('Practices:continueWhereLeft')}
      </Text>
      <FlexContainer>
        <EntityTileGrid>
          {courses.length > 0 &&
            courses
              .filter(
                course =>
                  course.learningPercent > 0 && course.learningPercent < 100
              )
              .sort((a, b) => a.learningPercent - b.learningPercent)
              .sort(
                (a, b) =>
                  new Date(b.lastActivity).getTime() -
                  new Date(a.lastActivity).getTime()
              )
              .map(course => (
                <EntityCard
                  to={`/courseDetails=${course.courseId}&userCourseId=${course.userCourseId}`}
                  key={`${course.courseId}_${course.createDate}`}
                  coverImage={course.coverImage}
                  title={course.courseName}
                  subtitle={course?.courseSubtitle}
                  totalChaptersLabel={t('courseCards.chaptersLeft', {
                    chapters: course.totalChapterNum,
                  })}
                  timesCompleted={
                    course.timesCompleted > 0
                      ? `${t('Courses:courseDetails.timesCompleted')}: ${
                          course.timesCompleted
                        }`
                      : ''
                  }
                  courseLevelLabel={t(`courseCards.${course.level.name}`)}
                  buttonLabel={getCtaTitle(course.learningPercent)}
                  learningPercent={course.learningPercent}
                />
              ))}
        </EntityTileGrid>
      </FlexContainer>
    </>
  );
};
