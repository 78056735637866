import React, {FC} from 'react';
import {Color} from 'enums/common';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div<{align: 'left' | 'center'}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${({align}) => (align === 'left' ? 'flex-start' : 'center')};
`;

const Title = styled.div`
  padding: 14px 0;
  color: ${Color.DARK};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Content = styled.div<{align: 'left' | 'center'}>`
  width: 100%;
  color: ${Color.DARK};
  font-size: 16px;
  text-align: ${({align}) => align};
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 24px;
`;

const LinkButton = styled(Link)`
  width: fit-content;
  background-color: ${Color.DARK};
  color: white;
  border-radius: 4px;
  padding: 5px 16px 5px 20px;
  text-decoration: none;
`;

interface NofeedbackProps {
  to?: string;
  buttonText?: string;
  content: string;
  title: string;
  imageSrc: string;
  align?: 'center' | 'left';
}

export const NoDataPlaceholder: FC<NofeedbackProps> = ({
  to,
  buttonText,
  content,
  title,
  imageSrc,
  align = 'left',
}) => {
  return (
    <Wrapper align={align}>
      <img src={imageSrc} />
      <Title>{title}</Title>
      <Content align={align}>{content}</Content>
      {buttonText && to && <LinkButton to={to}>Go to course</LinkButton>}
    </Wrapper>
  );
};
