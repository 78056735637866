export enum LearningProgress {
  LEARNING_PROGRESS = 'learning-progress',
  START_COURSE = 'start-course',
  COMPLETE_COURSE = 'complete-course',
  START_CHAPTER = 'start-chapter',
  COMPLETE_CHAPTER = 'complete-chapter',
  COMPLETE_PAGE = 'complete-page',
  COMPLETE_QUIZ = 'complete-quiz',
  START_EXERCISE = 'start-exercise',
  COMPLETE_EXERCISE = 'complete-exercise',
}
