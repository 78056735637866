import {useQuery} from '@tanstack/react-query';
import {getUserDashboard} from 'api/user/user';
import {UserDashboardParams} from 'types/users/users';
import {filterFalsyValues} from 'utils/common';

export const useUserDashboard = (params: UserDashboardParams) => {
  const {from, to} = filterFalsyValues(params);
  return useQuery(
    ['userDashboard', {from, to}],
    () => getUserDashboard({from, to}),
    {
      enabled: !!(from && to),
    }
  );
};
