import React, {useContext} from 'react';
import styled from 'styled-components';
import {Book, Clock, Reload, School, Wand} from 'tabler-icons-react';

// Style
import {Color} from 'enums/common';

// Components
import {Card, Image, Progress, Text} from '@mantine/core';
import {AssignedCourseSummary} from 'types/users/users';
import {capitalizeFirstLetter} from 'utils/common';
import {Button} from 'components/button';
import {UserContext} from 'contexes';
import {Role} from 'enums/auth';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

// Styled components
const StyledTitle = styled.span<{
  fontSize?: string;
  fontWeight?: number;
  marginTop?: string;
}>`
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-size: ${({fontSize = '14px'}) => fontSize};
  font-weight: ${({fontWeight = 700}) => fontWeight};
  color: ${Color.DARK};
  margin: 0;
  padding: 0;
  margin-top: ${({marginTop = '0px'}) => marginTop};
  width: 100%;
  text-transform: capitalize;
`;

const ContentWrapper = styled.div``;

const ProgresBarWrapper = styled.div<{
  padding?: string;
}>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: ${({padding = `0 10px`}) => padding};
  & .progress-bar {
    flex: 1;
  }
`;

const CourseDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 14px;
  width: 100%;
`;

const CourseDetail = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  color: #5c4d8a;
`;

const FooterWrapper = styled.div`
  padding-top: 10px;
`;

const ProgresBarLabel = styled(Text)`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

const StyledText = styled(Text)`
  font-size: 12px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
  flex: 1;

  &::first-letter {
    text-transform: capitalize;
  }
`;

type EntityCardProps = AssignedCourseSummary & {
  buttonLabel?: string;
  hasButton?: boolean;
};

export const SummaryEntityCard = ({
  image,
  courseName,
  timeSpent,
  completionCount,
  chaptersLeft,
  courseLevel,
  completionPercentage,
  buttonLabel,
  hasButton = false,
  courseId,
  userCourseId,
}: EntityCardProps) => {
  // Hooks
  const navigate = useNavigate();

  // Context
  const [userContext] = useContext(UserContext);
  const {t} = useTranslation('Dashboard');

  const isManager = userContext.authority.includes(Role.MANAGER);

  return (
    <Card
      shadow="sm"
      radius={4}
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '32px',
        paddingBottom: '45px',
      }}
    >
      <Card.Section>
        <Image
          src={image ?? '/images/default.jpeg'}
          height={130}
          alt="no image"
        />
        <ContentWrapper>
          <StyledTitle fontSize="18px" marginTop="16px" fontWeight={500}>
            {courseName}
          </StyledTitle>
        </ContentWrapper>
      </Card.Section>
      <Card.Section>
        <ContentWrapper>
          <CourseDetailsWrapper>
            {isManager && (
              <>
                <CourseDetail>
                  <Clock size={16} />
                  <StyledText>
                    {t('courseCards.timeSpent', {
                      timeSpent,
                    })}
                  </StyledText>
                </CourseDetail>

                <CourseDetail>
                  <Reload size={16} />
                  <StyledText>
                    {t('courseCards.completionCount', {
                      completionCount,
                    })}
                  </StyledText>
                </CourseDetail>
              </>
            )}
            <CourseDetail>
              <Book size={16} />
              <StyledText>
                {t('courseCards.chaptersLeft', {
                  chapters: chaptersLeft,
                })}
              </StyledText>
            </CourseDetail>

            <CourseDetail>
              <School size={16} />
              <StyledText>{capitalizeFirstLetter(courseLevel)}</StyledText>
            </CourseDetail>

            <CourseDetail>
              <Wand size={16} />
              <StyledText>{t('courseCards.assigned')}</StyledText>
            </CourseDetail>
          </CourseDetailsWrapper>

          <FooterWrapper>
            <ProgresBarWrapper padding={hasButton ? '0px' : '0 10px'}>
              {hasButton && (
                <Button
                  onClick={() =>
                    navigate(
                      `/courseDetails=${courseId}&userCourseId=${userCourseId}`
                    )
                  }
                >
                  {buttonLabel}
                </Button>
              )}
              <ProgresBarLabel>{completionPercentage}%</ProgresBarLabel>
              <Progress
                className="progress-bar"
                value={completionPercentage}
                color={Color.GREEN_050}
              />
            </ProgresBarWrapper>
          </FooterWrapper>
        </ContentWrapper>
      </Card.Section>
    </Card>
  );
};
