import {useQuery} from '@tanstack/react-query';
import {getUserTeamOverview} from 'api/department/department';
import {ApiDataResponse, ApiResponse} from 'types/api/ApiResponse';
import {TeamEmployee, UserTeamOverviewParams} from 'types/users/users';
import {filterFalsyValues} from 'utils/common';

export const useUserTeamOverview = (params: UserTeamOverviewParams) => {
  const {page, size} = filterFalsyValues(params);
  return useQuery<ApiDataResponse<ApiResponse<TeamEmployee[]>>, Error>(
    ['teamOverview', params],
    () => getUserTeamOverview(filterFalsyValues(params)),
    {
      enabled: !!(page && size),
    }
  );
};
