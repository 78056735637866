import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {Anchor, Breadcrumbs, Container, Text} from '@mantine/core';

import {BasePage} from '../../components/common';
import {PasswordChangeFrom} from '../../components/my-profile';

import {Color} from '../../enums/common';

import {useGetProfileInfo} from '../../api/tanstack-hooks/users';

const BreadcrumbPanel = styled.div`
  margin: 16px 0 40px -30px;
`;

const StyledText = styled(Text)`
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  color: ${Color.DARK};
  margin-left: 5.6em;
`;

export const ChangePassword: React.FC = () => {
  const {data} = useGetProfileInfo();

  const {t} = useTranslation('MyProfile');

  const items = [
    {title: 'Home', href: '/'},
    {
      title: 'My profile',
      href: `/my-profile`,
    },
    {
      title: 'Change Password',
      href: `/change-password`,
    },
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));
  return (
    <BasePage>
      <Container>
        <BreadcrumbPanel>
          <Breadcrumbs
            separator="→"
            styles={{
              breadcrumb: {
                color: Color.DARK,
                fontSize: '12px',
                fontFamily: 'Montserrat',
              },
              separator: {color: Color.DARK, fontSize: '12px'},
            }}
          >
            {items}
          </Breadcrumbs>
        </BreadcrumbPanel>
        <StyledText>{t('changePasswordLabel')}</StyledText>
        {data && <PasswordChangeFrom userprofile={data.data} />}
      </Container>
    </BasePage>
  );
};
