import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const languages = ['en', 'no', 'sv'];

const i18nDebug = (): boolean => {
  const debug = process.env.REACT_APP_I18N_DEBUG;

  if (debug && debug === 'disabled') {
    return false;
  }

  return true;
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to dk loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: {
      order: ['localStorage'],
      caches: ['localStorage'],
    },
    fallbackLng: 'no',
    debug: i18nDebug(),
    supportedLngs: languages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    defaultNS: 'Common',
    ns: [
      'Common',
      'Dashboard',
      'Auth',
      'MyProfile',
      'Login',
      'Courses',
      'Practices',
      'UserProfile',
      'Feedback',
    ],
  });

export default i18n;
