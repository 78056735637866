import {createAxiosInstance} from 'api/config';
import {USERS} from '../../enums/users/urls';

import {ApiDataResponse} from '../../types/api/ApiResponse';
import {
  UserDashboardParams,
  UserFeedback,
  UserOverviewParams,
  UserOverviewResponse,
  UserProfileInfo,
  UserProfilePayload,
} from '../../types/users/users';

const axios = createAxiosInstance();

export const getProfileInfo = async () => {
  const {data} = await axios.get<ApiDataResponse<UserProfileInfo>>(
    `/${USERS.GET_USER_PROFILE_INFO}`
  );

  return data;
};

export const getCourseFeedback = async (
  id: number,
  from?: string,
  to?: string
) => {
  const response = await axios.get<ApiDataResponse<UserFeedback>>(
    `/${USERS.PC_USER}/${id}/course-feedback`,
    {
      params: {from, to},
    }
  );
  return response.data;
};

export const getUserOverview = async ({
  departmentId,
  userId,
  from,
  to,
}: UserOverviewParams): Promise<UserOverviewResponse> => {
  const response = await axios.get(
    `/${USERS.PC_USER}/${departmentId}/${userId}/overview`,
    {
      params: {from, to},
    }
  );
  return response.data;
};

export const getUserDashboard = async (
  params: UserDashboardParams
): Promise<UserOverviewResponse> => {
  const {from, to} = params;
  const response = await axios.get(`/${USERS.PC_USER}/dashboard`, {
    params: {
      from,
      to,
    },
  });
  return response.data;
};

export const updateUserProfile = async (
  id: number,
  payload: UserProfilePayload
): Promise<void> => {
  const response = await axios.put(`/${USERS.PC_USER}/${id}`, payload);
  return response.data;
};
